import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import FormValue from '@cz_frontend/ui/components/forms/FormValue/FormValue'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined'
import { Box, Grid, Link, Typography } from '@mui/material'
import useSWR from 'swr'

import { getPointUseDetail, getPointUseDetailPath } from '@/features/histories/api/getPointUseDetail'
import { PointUseType, type PointDetail } from '@/features/histories/types'
import { useNavigate, useParams } from '@/router'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/histories/point/use/:id')
  const pageTitle = 'ポイント利用詳細'

  usePageInfo({
    title: pageTitle,
  })

  const { data } = useSWR(getPointUseDetailPath, () =>
    getPointUseDetail({
      id: params.id,
    }),
  )

  // 遷移
  const goList = () => {
    navigate('/histories/point/use')
  }

  const goFanDetail = (fanId: number) => {
    navigate('/fans/:fanId', {
      params: {
        fanId: String(fanId),
      },
    })
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <Grid container spacing={4}>
        {data && (
          <>
            <Grid item md={5}>
              <FormValue label={'◼️ ID'}>{data.result.id}</FormValue>
              <FormValue label={'◼️ ファン'}>
                <Box>
                  #
                  <CopyClipboard
                    text={String(data.result.fanId)}
                    isIcon={false}
                  />
                  &nbsp;
                  <Link
                    onClick={() => goFanDetail(data.result.fanId)}
                    color='inherit'
                    underline='hover'
                  >
                    {data.result.fanName}
                  </Link>
                </Box>
              </FormValue>
              <FormValue label={'◼️ 利用用途'}>
                {PointUseType[data.result.type].text}
              </FormValue>
              <FormValue label={'◼️ 利用ポイント数'}>
                {data.result.usedPoint}
              </FormValue>
              <FormValue label={'◼️ カスタム情報'}>
                {data.result.customInfo}
              </FormValue>

              <FormValue label={'◼️ 利用日時'}>
                {data.result.createdAt}
              </FormValue>
              <FormValue label={'◼️ 更新日時'}>
                {data.result.updatedAt}
              </FormValue>
            </Grid>
            <Grid item md={5}>
              <Box mt={4} mb={4}>
                <Typography>◼️ 利用詳細</Typography>
                {detailList(data.result.detail, navigate)}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  )
}

function detailList(items: PointDetail[], navigate: ReturnType<typeof useNavigate>) {
  const formatData = items.map((item) => {
    return {
      id: item.id,
      purchase: {
        fanPointBalanceId: item.fanPointBalanceId,
        point: item.point,
      },
      usedPoint: item.usedPoint,
      expiredAt: item.expiredAt,
    }
  })

  const goPointPurchaseDetail = (fanPointBalanceId = '') => {
    navigate({
      pathname: '/histories/point/purchase',
      search: `?id=${fanPointBalanceId}`,
    })
  }

  return (
    <Box>
      <DataTable
        columns={[
          { field: 'id', headerName: 'ID', width: 50 },
          {
            field: 'purchase',
            headerName: '購入ポイント',
            width: 120,
            renderCell: (params) => (
              <Box display={'flex'} gap={1} alignItems={'center'}>
                <Box>
                  {params.value.point}
                </Box>
                <Link
                  onClick={() =>
                    goPointPurchaseDetail(params.value.fanPointBalanceId)
                  }
                  color='inherit'
                  underline='hover'
                >
                  <EditNoteOutlinedIcon />
                </Link>
              </Box>
            ),
          },
          {
            field: 'usedPoint',
            headerName: '消費ポイント',
            width: 120,
          },
          {
            field: 'expiredAt',
            headerName: '有効期限',
            width: 100,
          },
        ]}
        idPropertyName={'id'}
        rows={formatData ?? []}
      />
    </Box>
  )
}

export default Page
