import { useEffect, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormSectionContainer from '@cz_frontend/ui/components/forms/FormSectionContainer/FormSectionContainer'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Alert, Box, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import type { AppVersionSettingFormValues } from '@/features/app-version-setting/types'

import {
  getAppVersionSetting,
  getAppVersionSettingPath,
} from '@/features/app-version-setting/api/getAppVersionSetting'
import { postAppVersionSetting } from '@/features/app-version-setting/api/postAppVersionSetting'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'アプリバージョン設定'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  // 取得
  const { data, mutate } = useSWR(getAppVersionSettingPath, () => {
    return getAppVersionSetting()
  })

  // フォーム
  const { control, handleSubmit, getValues, reset } =
    useForm<AppVersionSettingFormValues>({
      defaultValues: {
        iosCurrentMinVersion: '',
        androidCurrentMinVersion: '',
        iosNextApplyDatetime: null,
        androidNextApplyDatetime: null,
        iosNextMinVersion: '',
        androidNextMinVersion: '',
      },
    })

  useEffect(() => {
    if (data) {
      const {
        iosCurrentMinVersion,
        androidCurrentMinVersion,
        iosNextApplyDatetime,
        androidNextApplyDatetime,
        iosNextMinVersion,
        androidNextMinVersion,
      } = data.result
      reset({
        iosCurrentMinVersion: iosCurrentMinVersion ?? '',
        androidCurrentMinVersion: androidCurrentMinVersion ?? '',
        iosNextApplyDatetime: iosNextApplyDatetime
          ? newDate(iosNextApplyDatetime)
          : null,
        androidNextApplyDatetime: androidNextApplyDatetime
          ? newDate(androidNextApplyDatetime)
          : null,
        iosNextMinVersion: iosNextMinVersion ?? '',
        androidNextMinVersion: androidNextMinVersion ?? '',
      })
    }
  }, [data, reset])

  const submit = async () => {
    try {
      setIsLoading(true)
      await postAppVersionSetting({
        iosNextApplyDatetime: getValues('iosNextApplyDatetime')
          ? formatDateTime(
              getValues('iosNextApplyDatetime'),
              Format.dateTimeRequest,
            )
          : null,
        androidNextApplyDatetime: getValues('androidNextApplyDatetime')
          ? formatDateTime(
              getValues('androidNextApplyDatetime'),
              Format.dateTimeRequest,
            )
          : null,
        iosNextMinVersion: getValues('iosNextMinVersion'),
        androidNextMinVersion: getValues('androidNextMinVersion'),
      })
      swal.toastSuccess()
      await mutate()
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setIsLoading(false)
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  return (
    <Box className='page'>
      <PageTitle>{pageTitle}</PageTitle>
      <Box display={'flex'} width='100%'>
        <Alert severity='error' sx={{ width: '100%' }}>
          配信中のアプリのバージョンよりも新しいバージョンを指定しないようにしてください。
        </Alert>
      </Box>
      <FormBox onSubmit={() => false}>
        <Grid item style={{ width: '100%' }}>
          <FormSectionContainer sectionTitle='現在の設定'>
            <Box>
              <FormText
                control={control}
                name='iosCurrentMinVersion'
                label='iOSの最小バージョン'
                disabled
              />
            </Box>
            <Box>
              <FormText
                control={control}
                name='androidCurrentMinVersion'
                label='Androidの最小バージョン'
                disabled
              />
            </Box>
          </FormSectionContainer>
          <FormSectionContainer sectionTitle='iOS'>
            <Box display='flex' alignItems={'center'}>
              <FormDate
                control={control}
                name='iosNextApplyDatetime'
                label='次回適用日時'
                views={['year', 'month', 'day', 'hours']}
                fullWidth={false}
                sx={{ marginBottom: 1 }}
                required
              />
            </Box>
            <Box>
              <FormText
                control={control}
                name='iosNextMinVersion'
                label='次回最小バージョン'
                hint='バージョン番号を「1.0.0」のように3つの数字で指定してください'
                required
              />
            </Box>
          </FormSectionContainer>
          <FormSectionContainer sectionTitle='Android'>
            <Box display='flex' alignItems={'center'}>
              <FormDate
                control={control}
                name='androidNextApplyDatetime'
                label='次回適用日時'
                views={['year', 'month', 'day', 'hours']}
                fullWidth={false}
                sx={{ marginBottom: 1 }}
                required
              />
            </Box>
            <Box>
              <FormText
                control={control}
                name='androidNextMinVersion'
                label='次回最小バージョン'
                hint='バージョン番号を「1.0.0」のように3つの数字で指定してください'
                required
              />
            </Box>
          </FormSectionContainer>
        </Grid>
      </FormBox>
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={handleSubmit(submit)}
        sx={{ marginTop: 3 }}
      >
        更新する
      </ActionButton>
    </Box>
  )
}

export default Page
