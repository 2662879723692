import type {
  BannerDestinationType,
  BannerDisplayTargetType,
  BannerTargetType,
  DisplayStatusType,
} from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export const getBannerPath = '/admin/v1/banner'

export type GetBannerParams = {
  page: number
  displayStartDate?: string
  displayEndDate?: string
  target?: string
  displayTarget?: string
  destination?: string
}

export type GetBannerResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    bannerUrl: string
    displayStartAt: string
    displayEndAt: string | null
    priority: number
    target: BannerTargetType
    displayTarget: BannerDisplayTargetType
    destination: BannerDestinationType
    url: string | null
    eventId: number | null
    eventType: string | null
    eventName: string | null
    artistId: number | null
    artistFanId: number | null
    artistName: string | null
    isDisplayed: DisplayStatusType
    createdAt: string
    updatedAt: string
  }>
>

export const getBanner = async (params: GetBannerParams) => {
  return await http<GetBannerResponse>(getBannerPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      display_start_date: params.displayStartDate,
      display_end_date: params.displayEndDate,
      target: params.target,
      display_target: params.displayTarget,
      destination: params.destination,
    },
  })
}
