import { httpRaw } from '@/lib/httpRaw'

export type GetTotalEventArtistReportDownloadCsvParams = {
  eventId?: string
  eventName?: string
  artistId?: string
  artistName?: string
  isNewcomer?: boolean
  isFirstGachi?: boolean
}

export const getTotalEventArtistReportDownloadCsvPath =
  '/admin/v1/total-event-artist-report/download-csv'

export const getTotalEventArtistReportDownloadCsv = async (
  params: GetTotalEventArtistReportDownloadCsvParams,
) => {
  return await httpRaw(getTotalEventArtistReportDownloadCsvPath, {
    method: 'GET',
    searchParams: {
      event_id: params.eventId,
      event_name: params.eventName,
      artist_id: params.artistId,
      artist_name: params.artistName,
      is_newcomer: params.isNewcomer,
      is_first_gachi: params.isFirstGachi,
    },
  })
}
