import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import DataModal from '@cz_frontend/ui/components/common/DataModal/DataModal'
import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { MoreHorizOutlined } from '@mui/icons-material'
import { Avatar, Box, Grid, Link } from '@mui/material'
import useSWR from 'swr'

import { deletePostComment } from '@/features/post/api/deletePostComment'
import {
  getPostComment,
  getPostCommentPath,
} from '@/features/post/api/getPostComment'
import { useNavigate, useParams } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'コメント一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()
  const params = useParams('/posts/:postId/comments')
  const [isLoading, setIsLoading] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const [modalData, setModalData] = useState('')

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
  })

  const page = Number(searchParams.get('page') || '1')

  const { data, isValidating, mutate } = useSWR(getPostCommentPath, () =>
    getPostComment({
      page: page,
      id: params.postId,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [{ key: 'page', value: String(page) }])
      return prev
    })
    mutate(
      getPostComment({
        page: page,
        id: params.postId,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      postId: item.postId,
      comment: item.comment,
      fanId: item.fanId,
      fanName: item.fanName,
      fanIconUrl: item.fanIconUrl,
      toFanId: item.toFan?.id,
      toFanName: item.toFan?.name,
      toFanIconUrl: item.toFan?.iconUrl,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      deleteId: item.id,
    }
  })

  const submitCommentDelete = async (commentId: number) => {
    try {
      setIsLoading(true)
      const { isConfirmed } = await swal.confirm({
        text: `コメントID：${commentId}のコメントを削除します。削除すると元に戻せません`,
      })
      if (!isConfirmed) {
        setIsLoading(false)
        return
      }

      await deletePostComment({
        postId: Number(params.postId),
        commentId: commentId,
      })

      swal.toastSuccess()
      mutate()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
    } finally {
      setIsLoading(false)
    }
  }

  //遷移
  const goPostDetail = () => {
    navigate('/posts/:postId', {
      params: {
        postId: String(params.postId),
      },
    })
  }

  const goFanDetail = (fanId: number) => {
    navigate('/fans/:fanId', {
      params: {
        fanId: String(fanId),
      },
    })
  }

  const openModal = (comment = '') => {
    setIsModal(true)
    setModalData(comment)
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '投稿詳細に戻る', onClick: goPostDetail }]}>
        {pageTitle}
      </PageTitle>

      <Box>
        <DataTable
          columns={[
            { field: 'id', headerName: 'ID', width: 100, headerAlign: 'right' },
            {
              field: 'postId',
              headerName: '投稿ID',
              width: 100,
              headerAlign: 'right',
            },
            {
              field: 'comment',
              headerName: 'コメント',
              width: 300,
            },
            {
              field: 'body',
              headerName: '',
              width: 50,
              renderCell: (params) => {
                return (
                  <MoreHorizOutlined
                    onClick={() => openModal(params.row.comment)}
                    sx={{
                      cursor: 'pointer',
                    }}
                  />
                )
              },
            },
            {
              field: 'fanId',
              headerName: 'ファンID',
              width: 100,
              headerAlign: 'right',
              renderCell: (params) =>
                params.value ? <CopyClipboard text={params.value} /> : '-',
            },
            {
              field: 'fanIconUrl',
              headerName: '',
              width: 50,
              align: 'center',
              renderCell: (params) => {
                return (
                  <Avatar
                    src={params.value}
                    alt='ファンアイコン'
                    sx={{ width: 40, height: 40 }}
                  />
                )
              },
            },
            {
              field: 'fanName',
              headerName: 'ファン名',
              width: 200,
              renderCell: (params) => (
                <Link
                  onClick={() => goFanDetail(params.row.fanId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'toFanId',
              headerName: '返信先ファンID',
              width: 150,
              headerAlign: 'right',
              renderCell: (params) =>
                params.value ? <CopyClipboard text={params.value} /> : '-',
            },
            {
              field: 'toFanIconUrl',
              headerName: '',
              width: 50,
              align: 'right',
              renderCell: (params) => {
                return params.value ? (
                  <Avatar
                    src={params.value}
                    alt='返信先ファンアイコン'
                    sx={{ width: 40, height: 40 }}
                  />
                ) : null
              },
            },
            {
              field: 'toFanName',
              headerName: '返信先ファン名',
              width: 200,
              renderCell: (params) =>
                params.value ? (
                  <Link
                    onClick={() => goFanDetail(params.row.toFanId)}
                    color='inherit'
                    underline='hover'
                  >
                    {params.value}
                  </Link>
                ) : (
                  '-'
                ),
            },
            {
              field: 'createdAt',
              headerName: 'コメント日時',
              width: 200,
            },
            {
              field: 'action',
              headerName: '操作',
              width: 200,
              renderCell: (params) => {
                return (
                  <ActionButton
                    type='button'
                    size='small'
                    color='error'
                    variant='outlined'
                    onClick={() => {
                      submitCommentDelete(params.row.id)
                    }}
                    disabled={isLoading}
                  >
                    コメント削除
                  </ActionButton>
                )
              },
            },
          ]}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
        ></DataTable>
      </Box>
      <DataModal
        isShow={isModal}
        title='コメント'
        onCallback={() => setIsModal(false)}
      >
        <Grid container spacing={4}>
          <Grid item md={12}>
            <Box minWidth={500}>{modalData}</Box>
          </Grid>
        </Grid>
      </DataModal>
    </Box>
  )
}

export default Page
