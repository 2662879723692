import type {
  APIResponse,
  APIResponseResultPagination,
  EventTypeType,
} from '@/types'

import { http } from '@/lib/http'

export type GetLiveRecordingParams = {
  page: number
  postId?: string
  artistId?: string
  artistName?: string
  eventId?: string
  eventName?: string
}

export type GetLiveRecordingResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    liveId: number
    postId: number
    artistId: number
    artistFanId: number
    artistName: string
    eventId: number | null
    eventName: string
    eventType: EventTypeType | null
    recordingStartAt: string
    recordingStopAt: string
    createdAt: string
    updatedAt: string
  }>
>

export const getLiveRecordingPath = '/admin/v1/live-recording'

export const getLiveRecording = async (params: GetLiveRecordingParams) => {
  return await http<GetLiveRecordingResponse>(getLiveRecordingPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      post_id: params.postId,
      artist_id: params.artistId,
      artist_name: params.artistName,
      event_id: params.eventId,
      event_name: params.eventName,
    },
  })
}
