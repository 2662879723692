import { httpRaw } from '@/lib/httpRaw'

export type GetMonthlyArtistReportDownloadCsvParams = {
  year?: number
  month?: number
  artistId?: string
  artistName?: string
}

export const getMonthlyArtistReportDownloadCsvPath =
  '/admin/v1/monthly-artist-report/download-csv'

export const getMonthlyArtistReportDownloadCsv = async (
  params: GetMonthlyArtistReportDownloadCsvParams,
) => {
  return await httpRaw(getMonthlyArtistReportDownloadCsvPath, {
    method: 'GET',
    searchParams: {
      year: params.year,
      month: params.month,
      artist_id: params.artistId,
      artist_name: params.artistName,
    },
  })
}
