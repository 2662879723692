import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PostStartupPopupParams = {
  type: string
  displayStartAt: string | null
  displayEndAt: string | null
  pickupArtistFanIds: string | null
  eventId: number | null
  imageFileName: string | null
  destinationUrl: string | null
  targetType: string
  targetFanIds: string | null
  targetFanGroupId: number | null
  description: string | null
  isDisplayed: string
}

export type PostStartupPopupResponse = APIResponse<{
  id: number
  imageUploadUrl: string
}>

export const postStartupPopupPath = '/admin/v1/startup-popup'

export const postStartupPopup = async (params: PostStartupPopupParams) => {
  return await http<PostStartupPopupResponse>(postStartupPopupPath, {
    method: 'POST',
    body: JSON.stringify({
      type: params.type,
      display_start_at: params.displayStartAt,
      display_end_at: params.displayEndAt,
      pickup_artist_fan_ids: params.pickupArtistFanIds?.split(','),
      event_id: params.eventId,
      image_file_name: params.imageFileName,
      destination_url: params.destinationUrl,
      target_type: params.targetType,
      target_fan_ids: params.targetFanIds?.split(','),
      target_fan_group_id: params.targetFanGroupId,
      description: params.description,
      is_displayed: params.isDisplayed,
    }),
  })
}
