import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'

import type {
  BannerFormRef,
} from '@/features/banners/components/BannerForm';

import { postBanner } from '@/features/banners/api/postBanner'
import BannerForm from '@/features/banners/components/BannerForm'
import { transformFormValues } from '@/features/banners/utils'
import { pickErrorMessages, postUploadMedia } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const pageTitle = 'バナー作成'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({ title: pageTitle })

  const BannerFormRef = useRef<BannerFormRef>(null)

  const goList = () => {
    navigate('/banners')
  }

  const handleRegister = async () => {
    try {
      setIsLoading(true)
      const formValues = BannerFormRef.current!.getFormValues()
      const { result } = await postBanner({
        ...transformFormValues(formValues),
      })

      if (formValues.bannerFile) {
        const res = await postUploadMedia({
          url: result.bannerUploadUrl,
          file: formValues.bannerFile,
        })
        if (!res) {
          await swal.messages({
            messages: ['バナーファイルのアップロードに失敗しました'],
          })
          return
        }
      }

      swal.toastSuccess()
      goList()
    } catch (err) {
      await swal.messages({ messages: pickErrorMessages(err) })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <BannerForm ref={BannerFormRef} />
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={handleRegister}
      >
        登録する
      </ActionButton>
    </Box>
  )
}

export default Page
