import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import DataModal from '@cz_frontend/ui/components/common/DataModal/DataModal'
import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import FormValue from '@cz_frontend/ui/components/forms/FormValue/FormValue'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  MoreHorizOutlined,
} from '@mui/icons-material'
import { Box, Chip, Grid, IconButton, Link, Typography } from '@mui/material'
import useSWR from 'swr'

import {
  getLiveComment,
  getLiveCommentPath,
} from '@/features/post/api/getLiveComment'
import { useNavigate, useParams } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'ライブ配信コメント一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()
  const params = useParams('/lives/:liveId/comments')
  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const [firstCommentNo, setFirstCommentNo] = useState<number | null>(null)
  const [lastCommentNo, setLastCommentNo] = useState<number | null>(null)
  const [totalCommentCount, setTotalCommentCount] = useState(0)
  const [isShowModal, setIsShowModal] = useState(false)
  const [modalData, setModalData] = useState<string | null>(null)

  const [searchParams, setSearchParams] = useSearchParams({
    commentNo: '',
  })
  const commentNo = searchParams.get('commentNo') || undefined

  const { data, isValidating, mutate } = useSWR(getLiveCommentPath, () =>
    getLiveComment({
      id: params.liveId,
      commentNo: commentNo ? Number(commentNo) : undefined,
    }),
  )

  // ページ変更
  const handlePagination = async (cursor: 'prev' | 'next') => {
    const commentNo = cursor === 'prev' ? firstCommentNo : lastCommentNo
    if (!commentNo) return
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [{ key: 'commentNo', value: String(commentNo) }])
      return prev
    })
    mutate(
      getLiveComment({
        id: params.liveId,
        commentNo: commentNo,
      }),
    )
  }

  const formatData = data?.result.comments.map((item) => {
    return {
      type: item.type,
      comment: item.comment,
      commentNo: item.commentNo,
      createdAt: item.createdAt,
      sendPersonId: item.artist?.fanId || item.fan?.id,
      senPersonName: item.artist?.name || item.fan?.name,
      sendPersonIconUrl: item.artist?.iconUrl || item.fan?.iconUrl,
      sendPersonType: {
        text: item.artist ? 'アーティスト' : 'ファン',
        color: item.artist ? 'primary' : 'default',
      },
    }
  })

  useEffect(() => {
    if (!data) return
    setHasNext(data.result.hasMore)
    setHasPrev(data.result.hasPrev)
    if (!data.result.comments.length) return
    // ライブ配信コメントはDynamoDBに保存されていて order by ができないため、ページネーションするために直前のコメントNoを基準に取得している
    // 基準となるコメントNoは次回のレスポンス内のcommentsには含まれない
    setFirstCommentNo(
      data.result.comments[0].commentNo + data.result.perCount + 1,
    )
    setLastCommentNo(
      data.result.comments[data.result.comments.length - 1].commentNo,
    )
    setTotalCommentCount(data.result.latestCommentNo)
  }, [data])

  // useStateだけだとtotalCommentCountが初期化されないため、初回レンダリング時に初期化する
  useEffect(() => {
    setTotalCommentCount(0)
  }, [])

  //遷移
  const goLiveDetail = () => {
    navigate('/lives/:liveId', {
      params: {
        liveId: String(params.liveId),
      },
    })
  }

  const goFanDetail = (fanId: number) => {
    navigate('/fans/:fanId', {
      params: {
        fanId: String(fanId),
      },
    })
  }

  const openModal = (data: '') => {
    setIsShowModal(true)
    setModalData(data)
  }

  const closeModal = () => {
    setIsShowModal(false)
  }

  return (
    <Box className='page'>
      <PageTitle
        actions={[{ text: 'ライブ配信詳細に戻る', onClick: goLiveDetail }]}
      >
        {pageTitle}
      </PageTitle>

      <Typography>
        ※ 送信者名はコメント時点の名前のため、最新情報とは異なる場合があります。
      </Typography>
      <Box>
        {pagination()}
        <DataTable
          columns={[
            { field: 'commentNo', headerName: 'コメントNo', width: 100 },
            {
              field: 'sendPersonType',
              headerName: '送信者種別',
              width: 110,
              renderCell: (params) => {
                return (
                  <Chip
                    size='small'
                    color={params.value.color}
                    variant='outlined'
                    label={params.value.text}
                  />
                )
              },
            },
            {
              field: 'sendPersonId',
              headerName: '送信者のファンID',
              width: 140,
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'senPersonName',
              headerName: '送信者名',
              width: 200,
              renderCell: (params) => (
                <Link
                  onClick={() => goFanDetail(params.row.sendPersonId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'comment',
              headerName: 'コメント',
              width: 400,
            },
            {
              field: '_more',
              headerName: 'もっと見る',
              width: 100,
              renderCell: (params) => {
                return (
                  <MoreHorizOutlined
                    onClick={() => openModal(params.row.comment)}
                    sx={{
                      cursor: 'pointer',
                    }}
                  />
                )
              },
            },
            {
              field: 'createdAt',
              headerName: 'コメント日時',
              width: 200,
            },
          ]}
          rows={formatData ?? []}
          isLoading={isValidating}
          idPropertyName='commentNo'
        />
        {pagination()}
      </Box>
      <DataModal isShow={isShowModal} onCallback={closeModal}>
        <Grid container spacing={4}>
          <Grid item md={12}>
            <Box minWidth={500}>
              <FormValue label={'◼️ コメント'}>{modalData}</FormValue>
            </Box>
          </Grid>
        </Grid>
      </DataModal>
    </Box>
  )

  function pagination() {
    return (
      <Grid container>
        <Grid item md={1}>
          <Box
            display={'flex'}
            minWidth={'100%'}
            justifyContent={'space-between'}
            my={1}
            alignItems={'center'}
          >
            <IconButton
              sx={{
                cursor: 'pointer',
              }}
              disabled={isValidating || !hasPrev}
              onClick={() => handlePagination('prev')}
            >
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton
              sx={{
                cursor: 'pointer',
              }}
              disabled={isValidating || !hasNext}
              onClick={() => handlePagination('next')}
            >
              <KeyboardArrowRight />
            </IconButton>
            <Typography sx={{ marginLeft: 2 }}>
              全{totalCommentCount}件
            </Typography>
          </Box>
        </Grid>
      </Grid>
    )
  }
}

export default Page
