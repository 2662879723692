import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { deleteMailBounce } from '@/features/mail-bounce/api/deleteMailBounce'
import { getMailBouncePath, getMailBounce } from '@/features/mail-bounce/api/getMailBounce'
import { pickErrorMessages } from '@/utils/functions'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'バウンスメール一覧'
  usePageInfo({
    title: pageTitle,
  })

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    email: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const email = searchParams.get('email') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      email: email,
    },
  })

  const { data, isValidating, mutate } = useSWR(getMailBouncePath, () =>
    getMailBounce({
      page: page,
      email: email || undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'email', value: getValues('email') },
      ])
      return prev
    })
    mutate(
      getMailBounce({
        page: page,
        email: getValues('email') || undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      bounceType: item.bounceType,
      bounceSubType: item.bounceSubType,
      bounceTypeJp: item.bounceTypeJp.length > 0 ? item.bounceTypeJp : '-',
      email: item.email,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  // 削除
  const deleteAction = async (email: string) => {
    try {
      const { isConfirmed } = await swal.confirm({
        title: '削除しますか？',
        text: '削除すると元に戻せません。',
      })

      if (!isConfirmed) return

      await deleteMailBounce({
        email: email,
      })

      swal.toastSuccess()
      mutate()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
    }
  }

  return (
    <Box className='page'>
      <PageTitle>{pageTitle}</PageTitle>
      <Box
        display={'flex'}
        gap={4}
        alignItems={'center'}
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Box display={'flex'} gap={2} alignItems={'flex-start'}>
          <FormText
            control={control}
            name='email'
            label='メールアドレス'
            fullWidth={false}
            hint={'部分一致'}
          />
        </Box>

        <ActionButton type='submit'>検索</ActionButton>
      </Box>
      <Box>
        <DataTable
          columns={[
            {
              field: '_delete',
              headerName: '',
              width: 100,
              renderCell: (params) => (
                <ActionButton
                  size='small'
                  color='error'
                  variant='outlined'
                  onClick={() => {
                    deleteAction(params.row.email)
                  }}
                >
                  削除
                </ActionButton>
              ),
            },
            { field: 'id', headerName: 'ID', width: 50 },
            {
              field: 'email',
              headerName: 'メールアドレス',
              width: 300,
            },
            {
              field: 'bounceType',
              headerName: 'バウンスタイプ',
              width: 200,
            },
            {
              field: 'bounceTypeJp',
              headerName: 'バウンス理由',
              width: 500,
            },
            {
              field: 'createdAt',
              headerName: 'バウンス日時',
              width: 200,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          editReferProp={'id'}
        ></DataTable>
      </Box>
    </Box>
  )
}

export default Page
