import { useEffect, useState } from 'react'

import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import FormSectionContainer from '@cz_frontend/ui/components/forms/FormSectionContainer/FormSectionContainer'
import FormValue from '@cz_frontend/ui/components/forms/FormValue/FormValue'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Chip, Grid } from '@mui/material'

import type {
  PaymentBankAccountTypeType,
  PaymentReportDetail,
  PaymentTargetTypeType,
} from '@/features/report/types'

import { getPaymentReportDetail } from '@/features/report/api/getPaymentReportDetail'
import {
  PaymentBankAccountType,
  PaymentReportStatus,
  PaymentTargetType,
} from '@/features/report/types'
import { useNavigate, useParams } from '@/router'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/reports/payment-report/:reportId')
  const pageTitle = '支払レポート詳細'

  usePageInfo({
    title: pageTitle,
  })

  const [reportData, setReportData] = useState<PaymentReportDetail>({
    id: 0,
    year: 0,
    month: 0,
    status: PaymentReportStatus.PROCESSING.value,
    target: {
      type: null,
      id: 0,
      name: '',
    },
    issueDate: '',
    paymentDate: '',
    netAmount: 0,
    amount: 0,
    amountTax: 0,
    withholdingTax: 0,
    transferFee: 0,
    transferFeeTax: 0,
    name: '',
    email: '',
    zipCode: '',
    province: '',
    city: '',
    address1: '',
    address2: '',
    bankName: '',
    bankCode: '',
    branchName: '',
    branchCode: '',
    bankAccountType: PaymentBankAccountType.SAVING.value,
    bankAccountNumber: '',
    bankAccountHolder: '',
    invoiceNumber: '',
    issuerName: '',
    issuerZipCode: '',
    issuerProvince: '',
    issuerCity: '',
    issuerAddress1: '',
    issuerAddress2: '',
    issuerInvoiceNumber: '',
    details: [],
    createdAt: '',
    updatedAt: '',
  })

  useEffect(() => {
    const fetchData = async () => {
      const { result } = await getPaymentReportDetail({
        id: Number(params.reportId),
      })

      setReportData({
        id: result.id,
        year: result.year,
        month: result.month,
        status: result.status,
        target: {
          type: result.target.type,
          id: result.target.id,
          name: result.target.name,
        },
        issueDate: result.issueDate,
        paymentDate: result.paymentDate,
        netAmount: result.netAmount,
        amount: result.amount,
        amountTax: result.amountTax,
        withholdingTax: result.withholdingTax,
        transferFee: result.transferFee,
        transferFeeTax: result.transferFeeTax,
        name: result.name,
        email: result.email,
        zipCode: result.zipCode,
        province: result.province,
        city: result.city,
        address1: result.address1,
        address2: result.address2,
        bankName: result.bankName,
        bankCode: result.bankCode,
        branchName: result.branchName,
        branchCode: result.branchCode,
        bankAccountType: result.bankAccountType,
        bankAccountNumber: result.bankAccountNumber,
        bankAccountHolder: result.bankAccountHolder,
        invoiceNumber: result.invoiceNumber,
        issuerName: result.issuerName,
        issuerZipCode: result.issuerZipCode,
        issuerProvince: result.issuerProvince,
        issuerCity: result.issuerCity,
        issuerAddress1: result.issuerAddress1,
        issuerAddress2: result.issuerAddress2,
        issuerInvoiceNumber: result.issuerInvoiceNumber,
        details: result.details,
        createdAt: result.createdAt,
        updatedAt: result.updatedAt,
      })
    }
    fetchData()
  }, [params])

  // 遷移
  const goList = () => {
    navigate('/reports/payment-report')
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <Grid container spacing={4}>
        <>
          <Grid item md={5}>
            <FormSectionContainer sectionTitle='基本情報'>
              <FormValue label={'◼️ ID'}>{reportData.id}</FormValue>
              <FormValue label={'◼️ 年月'}>
                {reportData.year}/{reportData.month}
              </FormValue>
              <FormValue label={'◼️ ステータス'}>
                <Chip
                  size='small'
                  color={PaymentReportStatus[reportData.status].color}
                  variant='outlined'
                  label={PaymentReportStatus[reportData.status].text}
                />
              </FormValue>
              <FormValue label={'◼️ 支払先'}>
                {targetString(reportData.target)}
              </FormValue>
              <FormValue label={'◼️ 発行日'}>{reportData.issueDate}</FormValue>
              <FormValue label={'◼️ 支払日'}>
                {reportData.paymentDate}
              </FormValue>
              <FormValue label={'◼️ 振込金額(税込)'}>
                {reportData.netAmount.toLocaleString()} 円
              </FormValue>
              <FormValue label={'◼️ 金額（税抜）'}>
                {reportData.amount.toLocaleString()} 円
              </FormValue>
              <FormValue label={'◼️ 消費税額'}>
                {reportData.amountTax.toLocaleString()} 円
              </FormValue>
              <FormValue label={'◼️ 源泉徴収税額'}>
                {reportData.withholdingTax.toLocaleString()} 円
              </FormValue>

              <FormValue label={'◼️ 振込手数料(税抜)'}>
                {reportData.transferFee.toLocaleString()} 円
              </FormValue>
              <FormValue label={'◼️ 振込手数料消費税'}>
                {reportData.transferFeeTax.toLocaleString()} 円
              </FormValue>
            </FormSectionContainer>

            <FormSectionContainer sectionTitle='支払先情報'>
              <FormValue label={'◼️ 名前'}>{reportData.name}</FormValue>
              <FormValue label={'◼️ メールアドレス'}>
                {reportData.email}
              </FormValue>
              <FormValue label={'◼️ 郵便番号'}>{reportData.zipCode}</FormValue>
              <FormValue label={'◼️ 都道府県'}>{reportData.province}</FormValue>

              <FormValue label={'◼️ 市区町村'}>{reportData.city}</FormValue>

              <FormValue label={'◼️ 住所1'}>{reportData.address1}</FormValue>

              <FormValue label={'◼️ 住所2(建物名)'}>
                {reportData.address2}
              </FormValue>

              <FormValue label={'◼️ 銀行名'}>{reportData.bankName}</FormValue>
              <FormValue label={'◼️ 銀行コード'}>
                {reportData.bankCode}
              </FormValue>
              <FormValue label={'◼️ 支店名'}>{reportData.branchName}</FormValue>
              <FormValue label={'◼️ 支店コード'}>
                {reportData.branchCode}
              </FormValue>
              <FormValue label={'◼️ 預金種別'}>
                {getBankAccountType(reportData.bankAccountType)}
              </FormValue>
              <FormValue label={'◼️ 口座番号'}>
                {reportData.bankAccountNumber}
              </FormValue>
              <FormValue label={'◼️ 口座名義'}>
                {reportData.bankAccountHolder}
              </FormValue>
              <FormValue label={'◼️ インボイス番号'}>
                {reportData.invoiceNumber}
              </FormValue>
              <FormValue label={'◼️ 発行者名'}>
                {reportData.issuerName}
              </FormValue>
              <FormValue label={'◼️ 発行者郵便番号'}>
                {reportData.issuerZipCode}
              </FormValue>
              <FormValue label={'◼️ 発行者都道府県'}>
                {reportData.issuerProvince}
              </FormValue>
              <FormValue label={'◼️ 発行者市区町村'}>
                {reportData.issuerCity}
              </FormValue>
              <FormValue label={'◼️ 発行者住所1'}>
                {reportData.issuerAddress1}
              </FormValue>
              <FormValue label={'◼️ 発行者住所2'}>
                {reportData.issuerAddress2}
              </FormValue>
            </FormSectionContainer>
          </Grid>
          <Grid item md={5}>
            <FormSectionContainer sectionTitle='支払内容'>
              <DataTable
                columns={[
                  {
                    field: 'id',
                    headerName: 'ID',
                    width: 50,
                    headerAlign: 'right',
                  },
                  { field: 'description', headerName: '支払内容', width: 250 },
                  {
                    field: 'amount',
                    headerName: '金額税抜',
                    width: 100,
                    headerAlign: 'right',
                    renderCell: (params) => (
                      <>{params.value.toLocaleString()} 円</>
                    ),
                  },
                ]}
                idPropertyName={'id'}
                rows={reportData.details ?? []}
              />
            </FormSectionContainer>
            <FormSectionContainer sectionTitle='発行者情報'>
              <FormValue label={'◼️ 発行者名'}>
                {reportData.issuerName}
              </FormValue>
              <FormValue label={'◼️ 発行者郵便番号'}>
                {reportData.issuerZipCode}
              </FormValue>
              <FormValue label={'◼️ 発行者都道府県'}>
                {reportData.issuerProvince}
              </FormValue>
              <FormValue label={'◼️ 発行者市区町村'}>
                {reportData.issuerCity}
              </FormValue>
              <FormValue label={'◼️ 発行者住所1'}>
                {reportData.issuerAddress1}
              </FormValue>
              <FormValue label={'◼️ 発行者住所2'}>
                {reportData.issuerAddress2}
              </FormValue>
              <FormValue label={'◼️ インボイス番号'}>
                {reportData.issuerInvoiceNumber}
              </FormValue>
            </FormSectionContainer>

            <FormSectionContainer sectionTitle='その他'>
              <FormValue label={'◼️ 作成日時'}>
                {reportData.createdAt}
              </FormValue>
              <FormValue label={'◼️ 更新日時'}>
                {reportData.updatedAt}
              </FormValue>
            </FormSectionContainer>
          </Grid>
        </>
      </Grid>
    </Box>
  )
}

/**
 * 振込先情報を文字列に変換
 * @param target
 * @returns
 */
function targetString(target: {
  type: PaymentTargetTypeType | null
  id: number
  name: string
}) {
  if (target.type === null) {
    return ''
  }
  return `【${PaymentTargetType[target.type].text}】（${target.id}）${target.name}`
}

/**
 * 口座タイプを取得
 * @param type
 * @returns
 */
function getBankAccountType(type: PaymentBankAccountTypeType): string {
  return PaymentBankAccountType[type].text
}

export default Page
