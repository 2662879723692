import { useState, type FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Alert, Box, Chip, Grid, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import {
  getPaymentReport,
  getPaymentReportPath,
} from '@/features/report/api/getPaymentReport'
import { getPaymentReportDownloadCsv } from '@/features/report/api/getPaymentReportDownloadCsv'
import { PaymentReportStatus, PaymentTargetType } from '@/features/report/types'
import { useNavigate } from '@/router'
import { downloadCsv, pickErrorMessages } from '@/utils/functions'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'


function Page() {
  const pageTitle = '支払レポート'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)

  const page = Number(searchParams.get('page') || '1')
  const year = searchParams.get('year') || ''
  const month = searchParams.get('month') || ''
  const name = searchParams.get('name') || ''
  const organizationId = searchParams.get('organizationId') || ''
  const agencyId = searchParams.get('agencyId') || ''
  const artistId = searchParams.get('artistId') || ''
  const targetType = searchParams.get('targetType') || ''
  const status = searchParams.get('status') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      year: year,
      month: month,
      name: name,
      organizationId: organizationId,
      agencyId: agencyId,
      artistId: artistId,
      targetType: targetType,
      status: status,
    },
  })

  const { data, isValidating, mutate } = useSWR(getPaymentReportPath, () =>
    getPaymentReport({
      page: page,
      year: Number(year),
      month: Number(month),
      name: name,
      organizationId: organizationId,
      agencyId: agencyId,
      artistId: artistId,
      targetType: targetType,
      status: status,
    }),
  )
  // 遷移
  const goDetail = (reportId = '') => {
    navigate('/reports/payment-report/:reportId', {
      params: {
        reportId: reportId,
      },
    })
  }

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'year', value: String(getValues('year')) },
        { key: 'month', value: String(getValues('month')) },
        { key: 'name', value: String(getValues('name')) },
        { key: 'organizationId', value: String(getValues('organizationId')) },
        { key: 'agencyId', value: String(getValues('agencyId')) },
        { key: 'artistId', value: String(getValues('artistId')) },
        { key: 'targetType', value: String(getValues('targetType')) },
        { key: 'status', value: String(getValues('status')) },
      ])
      return prev
    })
    mutate(
      getPaymentReport({
        page: page,
        year: Number(getValues('year')) || undefined,
        month: Number(getValues('month')) || undefined,
        name: getValues('name') || undefined,
        organizationId: getValues('organizationId') || undefined,
        agencyId: getValues('agencyId') || undefined,
        artistId: getValues('artistId') || undefined,
        targetType: getValues('targetType') || undefined,
        status: getValues('status') || undefined,
      }),
    )
  }

  const monthOptions = [
    { value: '', text: '選択して下さい' },
    ...Array.from({ length: 12 }, (_, i) => {
      const num = i + 1
      return { value: num.toString(), text: num.toString() }
    }),
  ]

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: 1,
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      paymentReportId: item.paymentReportId,
      year: item.year,
      month: item.month,
      status: {
        text: PaymentReportStatus[item.status].text,
        color: PaymentReportStatus[item.status].color,
      },
      isReportDetail: item.status !== PaymentReportStatus.ERROR.value,
      errorReason: item.errorReason ?? '-',
      issueDate: item.issueDate ?? '-',
      paymentDate: item.paymentDate ?? '-',
      targetName: PaymentTargetType[item.target.type].text,
      targetId: item.target.id,
      target: {
        type: item.target.type,
        id: item.target.id,
        fanId: item.target.fanId,
        name: item.target.name,
      },
      netAmount: item.netAmount ? `¥${item.netAmount.toLocaleString()}` : '-',
      amount: item.amount ? `¥${item.amount.toLocaleString()}` : '-',
      amountTax: item.amountTax ?? '-',
      withholdingTax: item.withholdingTax ?? '-',
      transferFee: item.transferFee ?? '-',
      transferFeeTax: item.transferFeeTax ?? '-',
      invoiceNumber: item.invoiceNumber ?? '-',
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  const statusOptions = () => {
    const empty = [{ value: '', text: '選択してください' }]
    const base = Object.entries(PaymentReportStatus).map(([key, value]) => ({
      value: key,
      text: value.text,
    }))
    return empty.concat(base)
  }

  const targetOptions = () => {
    const empty = [{ value: '', text: '選択してください' }]
    const base = Object.entries(PaymentTargetType).map(([key, value]) => ({
      value: key,
      text: value.text,
    }))
    return empty.concat(base)
  }

  const goCsvUpload = () => {
    navigate('/reports/payment-report/csv-upload', {})
  }

  // CSVダウンロード
  const handleDownloadCsv = async () => {
    try {
      const res = await getPaymentReportDownloadCsv()

      const resBlob = await res.blob()
      const contentDisposition = res.headers.get('content-disposition')
      const fileName = contentDisposition ? contentDisposition.split('filename=')[1] : ''
      downloadCsv(resBlob, fileName)
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  const goAgencyDetail = (agencyId = '') => {
    navigate('/agencies/:agencyId', {
      params: {
        agencyId: agencyId,
      },
    })
  }

  const goOrganizationDetail = (organizationId = '') => {
    navigate('/organizations/:organizationId', {
      params: {
        organizationId: organizationId,
      },
    })
  }

  const goFanDetail = (fanId = '') => {
    console.log(fanId)
    navigate('/fans/:fanId', {
      params: {
        fanId: fanId,
      },
    })
  }

  const goTargetDetail = (type = '' , id = '', fanId = '') => {
    console.log(type, id, fanId)
    switch (type) {
      case PaymentTargetType.ORGANIZATION.value:
        goOrganizationDetail(id)
        break
      case PaymentTargetType.AGENCY.value:
        goAgencyDetail(id)
        break
      case PaymentTargetType.ARTIST.value:
        goFanDetail(fanId)
        break
    }
  }

  return (
    <Box className='page'>
      <PageTitle
        actions={[
          { text: 'CSVダウンロード', onClick: handleDownloadCsv },
          { text: 'CSVアップロード', onClick: goCsvUpload },
        ]}
        isLoading={isLoading}
      >
        {pageTitle}
      </PageTitle>
      <Box mb={2}>
        <Alert color='info'>
          CSVダウンロードは、振込予定分（ステータスが「処理中」）のみダウンロードされます
        </Alert>
      </Box>
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Grid container columnSpacing={3} mb={3}>
          <Grid item>
            <FormText
              control={control}
              name='year'
              label='年'
              fullWidth={false}
            />
          </Grid>
          <Grid item>
            <FormSelect
              control={control}
              name='month'
              label='月'
              fullWidth={false}
              options={monthOptions}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='name'
              label='名前'
              fullWidth={false}
              hint='部分一致'
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='organizationId'
              label='事務所ID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='agencyId'
              label='代理店ID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='artistId'
              label='アーティストID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormSelect
              control={control}
              name='status'
              options={statusOptions()}
              label='ステータス'
              fullWidth={false}
            />
          </Grid>

          <Grid item>
            <FormSelect
              control={control}
              name='targetType'
              options={targetOptions()}
              label='支払先種別'
              fullWidth={false}
            />
          </Grid>
          <Grid item>
            <ActionButton type='submit'>検索</ActionButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataTable
          columns={[
            {
              field: 'id',
              headerName: 'ID',
              width: 50,
            },
            {
              field: 'detail',
              headerName: '詳細',
              width: 100,
              renderCell: (params) => {
                return (
                  <ActionButton
                    size='small'
                    disabled={!params.row.isReportDetail}
                    onClick={() => goDetail(params.row.paymentReportId ?? '')}
                  >
                    詳細
                  </ActionButton>
                )
              },
            },
            {
              field: 'date',
              headerName: '日付',
              width: 80,
              renderCell: (params) => {
                return `${params.row.year}/${params.row.month}`
              },
            },
            {
              field: 'targetName',
              headerName: '支払先種別',
              width: 110,
            },
            {
              field: 'targetId',
              headerName: '支払先ID',
              width: 80,
              renderCell: (params) => {
                if(typeof params.value === 'string') {
                  return <CopyClipboard text={params.value} />
                }
              },
            },
            {
              field: 'target',
              headerName: '名前',
              width: 250,
              renderCell: (params) => (
                <Link
                  onClick={() =>
                    goTargetDetail(
                      params.value.type,
                      params.value.id,
                      params.value.fanId,
                    )
                  }
                  color='inherit'
                  underline='hover'
                >
                  {params.value.name}
                </Link>
              ),
            },
            {
              field: 'status',
              headerName: 'ステータス',
              width: 100,
              renderCell: (params) => {
                return (
                  <Chip
                    size='small'
                    color={params.value.color}
                    variant='outlined'
                    label={params.value.text}
                  />
                )
              },
            },
            {
              field: 'errorReason',
              headerName: 'エラー理由',
              width: 300,
            },
            {
              field: 'issueDate',
              headerName: '発行日',
              width: 110,
            },
            {
              field: 'paymentDate',
              headerName: '支払日',
              width: 110,
            },
            {
              field: 'netAmount',
              headerName: '振込金額（税込）',
              width: 150,
              headerAlign: 'right',
              align: 'right',
            },
            {
              field: 'amount',
              headerName: '金額（税抜）',
              width: 150,
              headerAlign: 'right',
              align: 'right',
            },
            {
              field: 'invoiceNumber',
              headerName: 'インボイス番号',
              width: 150,
            },
            {
              field: 'createdAt',
              headerName: '作成日時',
              width: 150,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 150,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
        />
      </Box>
    </Box>
  )
}

export default Page
