export const PostType = {
  POST: { value: 'POST', text: '投稿' },
  LIVE: { value: 'LIVE', text: 'ライブ配信' },
} as const
export type PostTypeType = keyof typeof PostType

export const PostStatus = {
  DRAFT: { value: 'DRAFT', text: '下書き', color: 'default' },
  PUBLIC: { value: 'PUBLIC', text: '公開中', color: 'success' },
  PRIVATE: { value: 'PRIVATE', text: '非公開', color: 'error' },
  PROCESSING: { value: 'PROCESSING', text: '処理中', color: 'info' },
} as const
export type PostStatusType = keyof typeof PostStatus

export const LiveTargetAudience = {
  ALL: { value: 'ALL', text: '全体' },
  SUBSCRIPTION: { value: 'SUBSCRIPTION', text: 'サブスク限定' },
} as const
export type LiveTargetAudienceType = keyof typeof LiveTargetAudience

export const LiveStatus = {
  PREPARING: { value: 'PREPARING', text: '準備中', color: 'default' },
  STREAMING: { value: 'STREAMING', text: '配信中', color: 'success' },
  ENDED: { value: 'ENDED', text: '終了', color: 'error' },
} as const
export type LiveStatusType = keyof typeof LiveStatus

export const PostContentsType = {
  MOVIE: { value: 'MOVIE', text: '動画' },
  IMAGE: { value: 'IMAGE', text: '画像' },
} as const
export type PostContentsTypeType = keyof typeof PostContentsType

export type PostContentsItem = {
  id: number
  type: PostContentsTypeType
  imageUrl: string
  movieUrl: string
  width: number
  height: number
  status: string
  sort: number
  createdAt: string
  updatedAt: string
}

export const LiveStopReason = {
  NORMAL: { value: 'NORMAL', text: '通常終了', color: 'success' },
  AUTO: { value: 'AUTO', text: '自動終了', color: 'secondary' },
  FORCE: { value: 'FORCE', text: '強制終了', color: 'error' },
} as const
export type LiveStopReasonType = keyof typeof LiveStopReason

export type LiveRecording = {
  id: number
  recordingFileUrl: string
  recordingStartAt: string
  recordingStopAt: string | null
}
