import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box, Chip, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { deleteOrganizationUser } from '@/features/organization/api/deleteOrganizationUser'
import {
  getOrganizationUser,
  getOrganizationUserPath,
} from '@/features/organization/api/getOrganizationUser'
import { useNavigate } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = '事務所ユーザー一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    name: '',
    email: '',
    organizationId: '',
    organizationName: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const name = searchParams.get('name') || ''
  const email = searchParams.get('email') || ''
  const organizationId = searchParams.get('organizationId') || ''
  const organizationName = searchParams.get('organizationName') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      name: name,
      email: email,
      organizationId: organizationId,
      organizationName: organizationName,
    },
  })

  const { data, isValidating, mutate } = useSWR(getOrganizationUserPath, () =>
    getOrganizationUser({
      page: page,
      name: name || undefined,
      email: email || undefined,
      organizationId: organizationId ? Number(organizationId) : undefined,
      organizationName: organizationName || undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'name', value: getValues('name') },
        { key: 'email', value: getValues('email') },
        { key: 'organizationId', value: getValues('organizationId') },
        { key: 'organizationName', value: getValues('organizationName') },
      ])
      return prev
    })
    mutate(
      getOrganizationUser({
        page: page,
        name: getValues('name') || undefined,
        email: getValues('email') || undefined,
        organizationId: getValues('organizationId')
          ? Number(getValues('organizationId'))
          : undefined,
        organizationName: getValues('organizationName') || undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const goDetail = (id = '') => {
    navigate('/organizations/accounts/:companyAccountId', {
      params: { companyAccountId: id },
    })
  }

  const goCreate = () => {
    navigate('/organizations/accounts/create')
  }

  const goOrganizationDetail = (organizationId = '') => {
    navigate('/organizations/:organizationId', {
      params: { organizationId: organizationId },
    })
  }

  const submitDelete = async (id: number) => {
    try {
      const { isConfirmed } = await swal.confirm({
        text: '本当に削除しますか？',
      })
      if (!isConfirmed) return

      await deleteOrganizationUser({ companyAccountId: id })
      mutate()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
    }
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      name: item.name,
      organizationId: item.organizationId,
      organizationName: item.organizationName,
      email: item.email,
      deletedAt: item.deletedAt,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '新規作成', onClick: goCreate }]}>
        {pageTitle}
      </PageTitle>
      <Box
        display={'flex'}
        gap={4}
        alignItems={'center'}
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Box display={'flex'} gap={2} alignItems={'flex-start'}>
          <FormText
            control={control}
            name='name'
            label='名前'
            fullWidth={false}
            hint='部分一致'
          />
          <FormText
            control={control}
            name='email'
            label='メールアドレス'
            fullWidth={false}
            hint='部分一致'
          />
          <FormText
            control={control}
            name='organizationId'
            label='事務所ID'
            fullWidth={false}
            type='number'
          />
          <FormText
            control={control}
            name='organizationName'
            label='事務所ID'
            fullWidth={false}
            hint='部分一致'
          />
        </Box>

        <ActionButton type='submit'>検索</ActionButton>
      </Box>
      <Box>
        <DataTable
          columns={[
            { field: 'id', headerName: 'ID', width: 100 },
            {
              field: 'name',
              headerName: '名前',
              width: 200,
            },
            {
              field: 'email',
              headerName: 'メールアドレス',
              width: 300,
            },
            {
              field: 'organizationId',
              headerName: '事務所ID',
              width: 100,
              renderCell: (params) => {
                return <CopyClipboard text={params.value} />
              },
            },
            {
              field: 'organizationName',
              headerName: '事務所名',
              width: 200,
              renderCell: (params) => {
                return (
                  <Link
                    onClick={() =>
                      goOrganizationDetail(params.row.organizationId)
                    }
                    color='inherit'
                    underline='hover'
                  >
                    {params.value}
                  </Link>
                )
              },
            },
            {
              field: 'createdAt',
              headerName: '作成日時',
              width: 180,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 180,
            },
            {
              field: 'deletedAt',
              headerName: '削除',
              width: 100,
              renderCell: (params) => {
                return params.value ? (
                  <Chip label='削除済み' color='error' variant='outlined' />
                ) : (
                  '-'
                )
              },
            },
            {
              field: 'delete',
              headerName: '',
              width: 300,
              renderCell: (params) => {
                return (
                  <ActionButton
                    type='button'
                    size='small'
                    color='error'
                    variant='outlined'
                    onClick={() => {
                      submitDelete(params.row.id)
                    }}
                    disabled={params.row.deletedAt}
                  >
                    削除
                  </ActionButton>
                )
              },
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
          editReferProp={'id'}
        ></DataTable>
      </Box>
    </Box>
  )
}

export default Page
