import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetMonthlyServiceKpiReportParams = {
  page: number
  year?: number
  month?: number
}

export type GetMonthlyServiceKpiReportResponse = APIResponse<
  APIResponseResultPagination<{
    year: number
    month: number
    artistActiveCount: number
    artistNewCount: number
    activityUU: number
    postUU: number
    liveUU: number
    totalPostCount: number
    avgPostCount: number
    totalLiveDuration: number
    avgLiveDuration: number
    fanActiveCount: number
    fanNewCount: number
    cheerCount: number
    pointCheerCount: number
    freeCheerCount: number
    limitedCheerCount: number
    cheerUU: number
    pointCheerUU: number
    freeCheerUU: number
    limitedCheerUU: number
    totalRevenue: number
    revenueIos: number
    revenueAndroid: number
    revenueWeb: number
    payingUU: number
    arppu: number
    pointUnitPrice: number
    createdAt: string
    updatedAt: string
  }>
>

export const getMonthlyServiceKpiReportPath =
  '/admin/v1/monthly-service-kpi-report'

export const getMonthlyServiceKpiReport = async (
  params: GetMonthlyServiceKpiReportParams,
) => {
  return await http<GetMonthlyServiceKpiReportResponse>(
    getMonthlyServiceKpiReportPath,
    {
      method: 'GET',
      searchParams: {
        page: params.page,
        year: params.year,
        month: params.month,
      },
    },
  )
}
