import type {
  BannerDestinationType,
  BannerDisplayTargetType,
  BannerTargetType,
  DisplayStatusType,
} from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetBannerDetailParams = {
  id: number
}

export type GetBannerDetailResponse = APIResponse<{
  id: number
  bannerUrl: string
  displayStartAt: string
  displayEndAt: string
  priority: number
  target: BannerTargetType
  displayTarget: BannerDisplayTargetType
  destination: BannerDestinationType
  url: string | null
  eventId: number | null
  artistFanId: number | null
  isDisplayed: DisplayStatusType
  createdAt: string
  updatedAt: string
}>

export const getBannerDetailPath = '/admin/v1/banner/:id'

export const getBannerDetail = async (params: GetBannerDetailParams) => {
  return await http<GetBannerDetailResponse>(getBannerDetailPath, {
    method: 'GET',
    params: {
      id: params.id,
    },
  })
}
