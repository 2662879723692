import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import {
  getRankingArtistDaily,
  getRankingArtistDailyPath,
} from '@/features/rankings/api/getRankingArtistDaily'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'アーティスト日別ランキング一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    date: formatDateTime(newDate(), Format.dateRequest),
  })

  const page = Number(searchParams.get('page') || '1')
  const dateParam = searchParams.get('date')
  const date = dateParam ? newDate(dateParam) : null

  const { control, getValues } = useForm({
    defaultValues: {
      date: date,
    },
  })

  const { data, isValidating, mutate } = useSWR(getRankingArtistDailyPath, () =>
    getRankingArtistDaily({
      page: page,
      date: getValues('date')
        ? formatDateTime(getValues('date'), Format.dateRequest)
        : undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        {
          key: 'date',
          value: formatDateTime(getValues('date'), Format.dateRequest),
        },
      ])
      return prev
    })
    mutate(
      getRankingArtistDaily({
        page: page,
        date: getValues('date')
          ? formatDateTime(getValues('date'), Format.dateRequest)
          : undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      rank: item.rank,
      score: item.score,
      cheerCount: item.cheerCount,
      watchingDuration: item.watchingDuration,
      postCount: item.postCount,
      postCheerFanCount: item.postCheerFanCount,
      artist: {
        id: item.artist.fanId,
        name: item.artist.name,
      },
    }
  })

  const goFanDetail = (fanId: number) => {
    navigate('/fans/:fanId', {
      params: {
        fanId: String(fanId),
      },
    })
  }

  const goMonthlyRanking = () => {
    navigate('/rankings/artists/monthly')
  }

  return (
    <Box className='page'>
      <PageTitle
        actions={[{ text: '月別ランキングへ', onClick: goMonthlyRanking }]}
      >
        {pageTitle}
      </PageTitle>
      <Box
        display={'flex'}
        gap={4}
        alignItems={'center'}
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Box display={'flex'} gap={2} alignItems={'flex-start'}>
          <FormDate
            control={control}
            name='date'
            label='日付'
            views={['year', 'month', 'day']}
            fullWidth={false}
          />
        </Box>
        <ActionButton type='submit'>検索</ActionButton>
      </Box>
      <Box>
        <DataTable
          columns={[
            { field: 'rank', headerName: '順位', width: 100 },
            {
              field: 'artist',
              headerName: 'アーティスト',
              width: 300,
              renderCell: (params) => (
                <Box>
                  
                  #<CopyClipboard text={typeof params.value.id} isIcon={false} />
                  &nbsp;
                  <Link
                    onClick={() => goFanDetail(params.value.id)}
                    color='inherit'
                    underline='hover'
                  >
                    {params.value.name}
                  </Link>
                </Box>
              ),
            },
            { field: 'score', headerName: 'スコア', width: 130 },
            { field: 'cheerCount', headerName: '獲得CHEER数', width: 140 },
            {
              field: 'watchingDuration',
              headerName: '総視聴時間(分)',
              width: 140,
            },
            { field: 'postCount', headerName: '総投稿数', width: 140 },
            {
              field: 'postCheerFanCount',
              headerName: 'CHEER UU数',
              width: 140,
            },
          ]}
          idPropertyName={'rank'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          editReferProp={'rank'}
        />
      </Box>
    </Box>
  )
}

export default Page
