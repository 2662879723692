import { useEffect, useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'

import type { StartupPopupFormRef } from '@/features/startup-popups/components/StartupPopupForm'

import { deleteStartupPopup } from '@/features/startup-popups/api/deleteStartupPopup'
import { getStartupPopupDetail } from '@/features/startup-popups/api/getStartupPopupDetail'
import { patchStartupPopup } from '@/features/startup-popups/api/patchStartupPopup'
import StartupPopupForm from '@/features/startup-popups/components/StartupPopupForm'
import { transformFormValues } from '@/features/startup-popups/utils'
import { newDate } from '@/lib/dateFns'
import { useNavigate, useParams } from '@/router'
import { pickErrorMessages, postUploadMedia } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/startup-popups/:popupId')
  const pageTitle = 'アプリ起動時ポップアップ編集'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({ title: pageTitle })

  const goList = () => {
    navigate('/startup-popups')
  }

  const StartupPopupFormRef = useRef<StartupPopupFormRef>(null)

  useEffect(() => {
    const fetchData = async () => {
      const { result } = await getStartupPopupDetail({
        id: Number(params.popupId),
      })
      StartupPopupFormRef.current?.formReset({
        type: result.type,
        displayStartAt:
          result.displayStartAt !== null
            ? newDate(result.displayStartAt)
            : null,
        displayEndAt:
          result.displayEndAt !== null ? newDate(result.displayEndAt) : null,
        pickupArtistFanIds: result.pickupArtistFanIds
          ? result.pickupArtistFanIds.join(',')
          : '',
        eventId: result.eventId ? Number(result.eventId) : '',
        imageUrl: result.imageUrl,
        imageFile: null,
        destinationUrl: result.destinationUrl,
        targetType: result.targetType,
        targetFanIds: result.targetFanIds ? result.targetFanIds.join(',') : '',
        targetFanGroupId: result.targetFanGroupId
          ? Number(result.targetFanGroupId)
          : '',
        description: result.description || '',
        isDisplayed: result.isDisplayed,
      })
    }
    fetchData()
  }, [params])

  const handleUpdate = async () => {
    try {
      setIsLoading(true)

      const formValues = StartupPopupFormRef.current!.getFormValues()
      const { result } = await patchStartupPopup({
        ...transformFormValues(formValues),
        popupId: Number(params.popupId),
      })
      if (formValues.imageFile) {
        const res = await postUploadMedia({
          url: result.imageUploadUrl,
          file: formValues.imageFile,
        })
        if (!res) {
          await swal.messages({
            messages: ['サムネイル画像のアップロードに失敗しました'],
          })
          return
        }
      }
      swal.toastSuccess()
      goList()
    } catch (err) {
      await swal.messages({ messages: pickErrorMessages(err) })
    } finally {
      setIsLoading(false)
    }
  }

  const handleDelete = async () => {
    try {
      setIsLoading(true)
      const { isConfirmed } = await swal.confirm({
        title: '削除しますか？',
        text: '削除すると元に戻せません。',
      })

      if (!isConfirmed) return

      await deleteStartupPopup({ id: Number(params.popupId) })
      swal.toastSuccess()
      goList()
    } catch (err) {
      await swal.messages({ messages: pickErrorMessages(err) })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <StartupPopupForm ref={StartupPopupFormRef} />
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={handleUpdate}
      >
        更新する
      </ActionButton>
      <Box mt={6}>
        <ActionButton
          size='large'
          isLoading={isLoading}
          fullWidth
          color='error'
          variant='outlined'
          onClick={handleDelete}
        >
          削除する
        </ActionButton>
      </Box>
    </Box>
  )
}

export default Page
