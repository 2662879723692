import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormFile from '@cz_frontend/ui/components/forms/FormFile/FormFile'
import FormRadio from '@cz_frontend/ui/components/forms/FormRadio/FormRadio'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormSwitch from '@cz_frontend/ui/components/forms/FormSwitch/FormSwitch'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import FormValue from '@cz_frontend/ui/components/forms/FormValue/FormValue'
import { Alert, Box, Grid } from '@mui/material'
import { addDays, endOfDay, startOfHour } from 'date-fns'
import { useForm, useWatch } from 'react-hook-form'

import { PublishStatus, SendToType } from '../types'

import type { PublishStatusType, SendToTypeType } from '../types'

import { newDate } from '@/lib/dateFns'
import { CheerType, type CheerTypeType } from '@/types'

type MessageBoxTemplateFormValues = {
  sendToType: SendToTypeType
  fanIds?: string
  fanGroupId?: string
  fanGroupName?: string
  sendToEventId?: string
  title: string
  body: string
  fileUrl?: string
  file?: File | null
  cheer?: number
  cheerExpiredAt?: Date
  cheerType: CheerTypeType
  eventId?: number
  publishStatus: PublishStatusType
  flags: string[]
  reservedAt: Date
}

interface MessageBoxTemplateFormProps {
  isCreate?: boolean
}
export interface MessageBoxTemplateFormRef {
  getFormValues(): MessageBoxTemplateFormValues
  setFormValue(key: keyof MessageBoxTemplateFormValues, value: string): void
  formReset(values: MessageBoxTemplateFormValues): void
}

const MessageBoxTemplateForm = forwardRef<
  MessageBoxTemplateFormRef,
  MessageBoxTemplateFormProps
  // eslint-disable-next-line no-empty-pattern
>(({}, ref) => {
  const { control, getValues, setValue, reset } =
    useForm<MessageBoxTemplateFormValues>({
      defaultValues: {
        sendToType: SendToType.FANID.value,
        fanIds: '',
        fanGroupId: '',
        fanGroupName: '',
        sendToEventId: '',
        title: '',
        body: '',
        file: null,
        cheer: 0,
        cheerExpiredAt: endOfDay(addDays(newDate(), 7)),
        cheerType: CheerType.FREE.value,
        eventId: 1,
        publishStatus: PublishStatus.DRAFT.value,
        flags: [] as string[],
        reservedAt: startOfHour(newDate()),
      },
    })

  // 親から呼べる関数を公開
  useImperativeHandle(ref, () => ({
    getFormValues: getValues,
    setFormValue: (key, value) => {
      setValue(key, value)
    },
    formReset: (values) => {
      reset(values)
    },
  }))

  const [isSetCheer, setIsSetCheer] = useState(false)
  const [isCheerTypeLimited, setIsCheerTypeLimited] = useState(false)

  // 編集時にも状態変数周りを適切にセット
  // CHEER数
  const watchCheerCount = useWatch({ control, name: 'cheer' })
  const handleSetCheer = useCallback(() => {
    const cheer = getValues('cheer')
    if (!cheer) {
      setIsSetCheer(false)
      return
    }
    setIsSetCheer(cheer > 0)
  }, [getValues])

  useEffect(() => {
    handleSetCheer()
  }, [handleSetCheer, watchCheerCount])

  // CHEERタイプ
  const watchCheerType = useWatch({ control, name: 'cheerType' })
  const handleSetCheerType = useCallback(() => {
    setIsCheerTypeLimited(getValues('cheerType') === CheerType.LIMITED.value)
  }, [getValues])

  useEffect(() => {
    handleSetCheerType()
  }, [handleSetCheerType, watchCheerType])

  const sendToTypeOptions = () => {
    return Object.entries(SendToType).map(([key, value]) => ({
      value: key,
      text: value.text,
    }))
  }

  const watchSendToType = useWatch({ control, name: 'sendToType' })

  return (
    <FormBox onSubmit={() => false}>
      <Grid item md={10}>
        <Alert color='info' sx={{ marginBottom: 1 }}>
          送信先種別で「{SendToType.ACTIVE_ARTIST_ALL.text}」「
          {SendToType.ACTIVE_FAN_ALL.text}」「{SendToType.EVENT_ID.text}
          」を選択した場合、このメッセージ用のファンIDグループが自動生成され、そのファンIDグループが送信先として設定されます。
        </Alert>
      </Grid>
      <Grid item md={5}>
        <FormSelect
          control={control}
          required
          name='sendToType'
          label='送信先種別'
          fullWidth={false}
          options={sendToTypeOptions()}
        />
        {watchSendToType === SendToType.FANID.value && (
          <FormText
            control={control}
            name='fanIds'
            label='ファンID'
            hint='カンマ区切りで複数指定可能'
          />
        )}
        {watchSendToType === SendToType.FANID_GROUP.value && (
          <FormText
            control={control}
            name='fanGroupId'
            label='ファンIDグループのID'
            type='number'
          />
        )}
        {watchSendToType === SendToType.FANID_GROUP.value &&
          getValues('fanGroupName') && (
            <FormValue label='ファンIDグループ名'>
              {getValues('fanGroupName')}
            </FormValue>
          )}
        {watchSendToType === SendToType.EVENT_ID.value && (
          <FormText
            control={control}
            name='sendToEventId'
            label='イベントID'
            hint='カンマ区切りで複数指定可能。エントリーしているアーティストに送信されます。'
          />
        )}
        <FormFile
          label='添付画像'
          control={control}
          name='file'
          showImagePreview
          initialImageSrc={getValues('fileUrl')}
        ></FormFile>
        <FormText
          control={control}
          required
          name='title'
          label='メッセージタイトル'
          hint='最大255文字'
        />
        <FormText
          control={control}
          required
          name='body'
          label='メッセージ本文'
          hint='最大5000文字 / {name}でファン名、{fanId}でファンIDに置き換え可能'
          multiline
        />
        <FormDate
          control={control}
          name='reservedAt'
          label='配信予約日時'
          views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
          fullWidth
          sx={{ width: '100%' }}
        />
      </Grid>
      <Grid item md={5}>
        <FormText
          control={control}
          name='cheer'
          label='受け取るCHEER数'
          type='number'
        />
        {isSetCheer && (
          <Box pl={4}>
            <FormDate
              control={control}
              name='cheerExpiredAt'
              label='CHEERの受け取り期限'
              views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
              fullWidth
              sx={{ width: '100%' }}
              required
            />
            <FormRadio
              label='CHEER種別'
              control={control}
              name='cheerType'
              options={Object.entries(CheerType).map(([, item]) => ({
                value: item.value,
                text: item.text,
              }))}
              required
            ></FormRadio>
            {isCheerTypeLimited && (
              <Box pl={4}>
                <FormText
                  control={control}
                  name='eventId'
                  label='限定CHEERの対象イベントID'
                  type='number'
                  required
                />
              </Box>
            )}
          </Box>
        )}
        <FormRadio
          label='公開ステータス'
          control={control}
          name='publishStatus'
          required
          options={Object.entries(PublishStatus).map(([, item]) => ({
            value: item.value,
            text: item.text,
          }))}
        ></FormRadio>
        <FormSwitch
          control={control}
          name='flags'
          options={[
            {
              value: 'isReply',
              text: '返信可否',
            },
            {
              value: 'importantFlag',
              text: '重要フラグ',
            },
          ]}
        />
      </Grid>
    </FormBox>
  )
})

export default MessageBoxTemplateForm
