import type {
  DisplayStatusType,
  StartupPopupTargetTypeType,
  StartupPopupTypeType,
} from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetStartupPopupParams = {
  page: number
  id?: string
  type?: string
  displayStartDate?: string
  displayEndDate?: string
  targetType?: string
}

export type GetStartupPopupResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    type: StartupPopupTypeType
    displayStartAt: string
    displayEndAt: string
    eventId: number | null
    eventName: string | null
    destinationUrl: string
    targetType: StartupPopupTargetTypeType
    fanGroupId: number | null
    isDisplayed: DisplayStatusType
    createdAt: string
    updatedAt: string
  }>
>

export const getStartupPopupPath = '/admin/v1/startup-popup'

export const getStartupPopup = async (params: GetStartupPopupParams) => {
  return await http<GetStartupPopupResponse>(getStartupPopupPath, {
    method: 'GET',
    searchParams: {
      id: params.id,
      page: params.page,
      type: params.type,
      display_start_date: params.displayStartDate,
      display_end_date: params.displayEndDate,
      target_type: params.targetType,
    },
  })
}
