import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import {
  getFanIdGroup,
  getFanIdGroupPath,
} from '@/features/fan/api/getFanIdGroup'
import { Format, formatDateTime } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'ファンIDグループ一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const page = Number(searchParams.get('page') || '1')
  const name = searchParams.get('name') || ''
  const id = searchParams.get('id') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      name: name,
      id: id,
    },
  })

  const { data, isValidating, mutate } = useSWR(getFanIdGroupPath, () =>
    getFanIdGroup({
      page: page,
      name: name || undefined,
      id: id ? Number(id) : undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'name', value: getValues('name') },
        { key: 'id', value: getValues('id') },
      ])
      return prev
    })
    mutate(
      getFanIdGroup({
        page: page,
        name: getValues('name') || undefined,
        id: getValues('id') ? Number(getValues('id')) : undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const goDetail = (id = '') => {
    navigate('/fan-id-groups/:fanGroupId', {
      params: {
        fanGroupId: id,
      },
    })
  }

  const goCreate = () => {
    navigate('/fan-id-groups/create')
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      name: item.name,
      fanCount: item.fanCount,
      createdAt: formatDateTime(item.createdAt, Format.dateTimeDefault),
      updatedAt: formatDateTime(item.updatedAt, Format.dateTimeDefault),
    }
  })

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '新規作成', onClick: goCreate }]}>
        {pageTitle}
      </PageTitle>
      <Box
        display={'flex'}
        gap={4}
        alignItems={'center'}
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Box display={'flex'} gap={2} alignItems={'flex-start'}>
          <FormText
            control={control}
            name='id'
            label='グループID'
            fullWidth={false}
            type='number'
          />
          <FormText
            control={control}
            name='name'
            label='IDグループ名'
            fullWidth={false}
          />
        </Box>

        <ActionButton type='submit'>検索</ActionButton>
      </Box>
      <Box>
        <DataTable
          columns={[
            { field: 'id', headerName: 'ID', width: 100 },
            {
              field: 'name',
              headerName: 'IDグループ名',
              width: 300,
            },
            {
              field: 'fanCount',
              headerName: 'ファン数',
              width: 300,
            },
            {
              field: 'createdAt',
              headerName: '作成日時',
              width: 200,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 200,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
          editReferProp={'id'}
        ></DataTable>
      </Box>
    </Box>
  )
}

export default Page
