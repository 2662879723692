import type {
  LiveRecording,
  LiveStatusType,
  LiveTargetAudienceType,
  PostContentsItem,
  PostStatusType,
  PostTypeType,
} from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetPostDetailParams = {
  id: number
}

export type GetPostDetailResponse = APIResponse<{
  id: number
  type: PostTypeType
  artistId: number
  artistName: string
  eventId: number | null
  eventName: string
  body: string
  targetAudience: LiveTargetAudienceType
  publishedAt: string
  status: PostStatusType
  imageUrl: string
  movieUrl: string
  width: number
  height: number
  liveStatus: LiveStatusType | ''
  createdAt: string
  updatedAt: string
  contents: PostContentsItem[]
  liveDetail: null | {
    recordings: null | LiveRecording[]
  }
}>

export const getPostDetailPath = '/admin/v1/post/:postId'

export const getPostDetail = async (params: GetPostDetailParams) => {
  return await http<GetPostDetailResponse>(getPostDetailPath, {
    method: 'GET',
    params: {
      postId: params.id,
    },
  })
}
