import { forwardRef, useImperativeHandle } from 'react'

import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormFile from '@cz_frontend/ui/components/forms/FormFile/FormFile'
import FormRadio from '@cz_frontend/ui/components/forms/FormRadio/FormRadio'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { Box, FormLabel, Grid, Typography } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'

import { StartupPopupTargetType, StartupPopupType } from '../types'

import type { StartupPopupFormValues } from '../types'

import { INPUT_BASE_HEIGHT } from '@/theme'

interface StartupPopupFormProps {}
export interface StartupPopupFormRef {
  getFormValues(): StartupPopupFormValues
  setFormValue(key: keyof StartupPopupFormValues, value: string | number): void
  formReset(values: StartupPopupFormValues): void
}

const StartupPopupForm = forwardRef<StartupPopupFormRef, StartupPopupFormProps>(
  (_, ref) => {
    const { control, getValues, setValue, reset } =
      useForm<StartupPopupFormValues>({
        defaultValues: {
          type: StartupPopupType.ARTIST_PICKUP.value,
          displayStartAt: null,
          displayEndAt: null,
          pickupArtistFanIds: '',
          eventId: '',
          imageUrl: '',
          destinationUrl: '',
          targetType: StartupPopupTargetType.FAN_ALL.value,
          targetFanIds: '',
          targetFanGroupId: '',
          description: '',
          isDisplayed: 'ON',
        },
      })

    // 親から呼べる関数を公開
    useImperativeHandle(ref, () => ({
      getFormValues: getValues,
      setFormValue: (key, value) => {
        setValue(key, value)
      },
      formReset: (values) => {
        reset(values)
      },
    }))

    const watchType = useWatch<StartupPopupFormValues>({
      control,
      name: 'type',
    })

    const isDisplayPeriodVisible =
      watchType === StartupPopupType.ARTIST_PICKUP.value ||
      watchType === StartupPopupType.FAN_DAILY_MISSION.value ||
      watchType === StartupPopupType.WEB.value
    const isEventIdVisible =
      watchType === StartupPopupType.EVENT_RECRUIT.value ||
      watchType === StartupPopupType.EVENT_ACTIVE.value

    const watchTargetType = useWatch<StartupPopupFormValues>({
      control,
      name: 'targetType',
    })

    return (
      <FormBox onSubmit={() => false}>
        <Grid item style={{ width: '100%' }}>
          <Box>
            <FormRadio
              label='種別'
              control={control}
              name='type'
              required
              fullWidth={false}
              options={Object.entries(StartupPopupType).map(([, item]) => ({
                value: item.value,
                text: item.text,
              }))}
            />
          </Box>
          {isDisplayPeriodVisible && (
            <>
              <FormLabel>表示期間</FormLabel>
              <Box display='flex' alignItems={'center'} gap={'1em'}>
                <FormDate
                  control={control}
                  name='displayStartAt'
                  views={[
                    'year',
                    'month',
                    'day',
                    'hours',
                    'minutes',
                    'seconds',
                  ]}
                  fullWidth={false}
                />
                <Typography height={INPUT_BASE_HEIGHT}>〜</Typography>
                <FormDate
                  control={control}
                  name='displayEndAt'
                  views={[
                    'year',
                    'month',
                    'day',
                    'hours',
                    'minutes',
                    'seconds',
                  ]}
                  fullWidth={false}
                />
              </Box>
            </>
          )}
          {watchType === StartupPopupType.ARTIST_PICKUP.value && (
            <Box>
              <FormText
                control={control}
                name='pickupArtistFanIds'
                label='ピックアップアーティストのファンID'
                hint='カンマ区切りで複数指定可能'
              />
            </Box>
          )}
          {isEventIdVisible && (
            <Box>
              <FormText
                control={control}
                name='eventId'
                label='イベントID'
                type='number'
              />
            </Box>
          )}
          {watchType === StartupPopupType.WEB.value && (
            <>
              <Box style={{ width: '50%' }}>
                <FormFile
                  control={control}
                  name='imageFile'
                  label='サムネイル画像'
                  showImagePreview
                  initialImageSrc={getValues('imageUrl')}
                  required
                />
              </Box>
              <Box>
                <FormText
                  control={control}
                  name='destinationUrl'
                  label='遷移先URL'
                />
              </Box>
            </>
          )}
          <Box>
            <FormRadio
              label='表示対象'
              control={control}
              name='targetType'
              required
              fullWidth={false}
              options={Object.entries(StartupPopupTargetType).map(
                ([, item]) => ({
                  value: item.value,
                  text: item.text,
                }),
              )}
            />
          </Box>
          {watchTargetType === StartupPopupTargetType.FAN_ID.value && (
            <Box>
              <FormText
                control={control}
                name='targetFanIds'
                label='ファンID'
                hint='カンマ区切りで複数指定可能'
              />
            </Box>
          )}
          {watchTargetType === StartupPopupTargetType.FAN_GROUP.value && (
            <Box>
              <FormText
                control={control}
                name='targetFanGroupId'
                label='ファングループID'
                type='number'
              />
            </Box>
          )}
          <Box>
            <FormText
              control={control}
              name='description'
              label='概要'
              hint='最大250文字'
              multiline
            />
          </Box>
          <Box>
            <FormRadio
              label='表示'
              control={control}
              name='isDisplayed'
              options={[
                { value: 'ON', text: '表示' },
                { value: 'OFF', text: '非表示' },
              ]}
            />
          </Box>
        </Grid>
      </FormBox>
    )
  },
)

export default StartupPopupForm
