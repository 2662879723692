import { useRef, useState, type FormEvent } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormFile from '@cz_frontend/ui/components/forms/FormFile/FormFile'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Alert, Box } from '@mui/material'
import { useForm } from 'react-hook-form'

import {
  postPaymentReportUploadCsv,
  type PostPaymentReportUploadCsvFormData,
} from '@/features/report/api/postPaymentReportUploadCsv'
import { useNavigate } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const [isLoading, setIsLoading] = useState(false)
  //csvFIleの値を初期化
  const csvFileRef = useRef<HTMLInputElement | null>(null)
  const pageTitle = '支払レポートCSVアップロード'

  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const { control, getValues } = useForm({
    defaultValues: {
      csvFile: null,
    },
  })

  // アップロード
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    try {
      setIsLoading(true)
      if (!getValues('csvFile')) {
        swal.alert({ text: 'CSVファイルを選択してください' })
        return
      }
      const formData = new FormData() as PostPaymentReportUploadCsvFormData
      formData.append('file', getValues('csvFile'))

      await postPaymentReportUploadCsv(formData)
      swal.toastSuccess()
      setIsLoading(false)
      goList()
    } catch (error) {
      csvFileRef.current ? (csvFileRef.current.value = '') : null
      await swal.messages({ messages: pickErrorMessages(error) })
    } finally {
      setIsLoading(false)
    }
  }

  //一覧へ戻る
  const goList = () => {
    navigate('/reports/payment-report', {})
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '支払一覧へ戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <Box mb={2}>
        <Alert color='info'>
          「振込結果」:
          振込が正常に完了した場合は「SUCCESS」、何らかのエラーで失敗した場合は「FAILURE」を入力してください
        </Alert>
        <Alert color='info'>「振込完了日」: 振込結果が「SUCCESS」の場合は、実際に振込を行った日付を「YYYY-MM-DD」のフォーマットで入力してください</Alert>
      </Box>
      <Box
        onSubmit={(e) => {
          handleSubmit(e)
        }}
        component='form'
      >
        <FormFile
          label='CSVファイル'
          control={control}
          name='csvFile'
          required
          inputCsvFile={csvFileRef}
          accept='.csv'
        />
        <ActionButton type='submit' disabled={isLoading}>
          アップロード
        </ActionButton>
      </Box>
    </Box>
  )
}

export default Page
