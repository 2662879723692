import React from 'react';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import { CircularProgress } from '@mui/material';
import { Box, Pagination, Typography, Paper, Table, TableContainer, TableHead, TableBody, TableRow, TableCell } from '@mui/material';


type Column = {
  field: string;
  headerName: string;
  width?: number;
  align?: 'left' | 'right' | 'center';
  headerAlign?: 'left' | 'right' | 'center';
  renderCell?: (params: {
    row: any;
    field: string;
    value: any ;
  }) => React.ReactNode;
};

function DataTable({
  columns,
  rows,
  idPropertyName = 'id',
  total,
  page = 1,
  lastPage = 0,
  onPageChange,
  isLoading = false,
  editReferProp = 'id',
  onEditClick,
  editHeaderName = '編集',
  rowHeight = 52,
}: {
  columns: Column[];
  rows: {[key: string]: any }[];
  idPropertyName?: string;
  /** 現在のページ */
  page?: number;
  /** 全件 */
  total?: number;
  /** ページ総数 */
  lastPage?: number;
  /** ページ変更 */
  onPageChange?: (value: number) => void;
  /** 読み込み中の表示をする場合はtrue */
  isLoading?: boolean;
  /** 編集画面に遷移時に参照するプロパティ（デフォルトはid）  */
  editReferProp?: string;
  /** 編集画面に遷移するボタンを押した（editReferPropを指定しない場合は必ずid列が必要）  */
  onEditClick?: (id: string) => void;
  editHeaderName?: string;
  /** 行の高さ */
  rowHeight?: number;
}) {
  
 //選択した行をハイライトさせる状態管理
 const [selectedRow, setSelectedRow] = React.useState<string | null>(null);

  const getRowId = (row: any) => {
    return row[idPropertyName];
  };

  //クリックした行のidを取得する
  const handleRowClick = (id: string) => {
    setSelectedRow(prevSelected => prevSelected === id ? id : null);
  }


  if (onEditClick && !columns.find((c) => c.field === '_edit')) {
    columns.unshift({
      field: '_edit',
      headerName: editHeaderName,
      width: 50,
      align: 'center',
      renderCell: (params) => {
        return (
          <EditNoteOutlinedIcon
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => onEditClick(params.row[editReferProp].toString())}
          />
        );
      },
    });
  }

  columns.forEach((column) => {
    if (!column.align) {
      column.align =
        rows.length > 0 && typeof rows[0][column.field] === 'number'
          ? 'right'
          : 'left';
    }

    const originalRenderCell = column.renderCell;
    column.renderCell = (params) => {
      let formattedValue = params.value;
      if (
        typeof params.value === 'number' &&
        !column.field.toLowerCase().includes('id')
      ) {
        formattedValue = params.value.toLocaleString();
      }
      if (originalRenderCell) {
        return originalRenderCell({ ...params, value: formattedValue });
      }
      return formattedValue;
    };
  });

  const handleChange = (_e: React.ChangeEvent<unknown>, value: number) => {
    if (!onPageChange) return;
    onPageChange(value);
  };

  return (
    <Box>
      {page && lastPage && total && onPageChange ? (
        <Box pb={1} display='flex' alignItems='center'>
          <Pagination count={lastPage} page={page} onChange={handleChange} />
          <Typography fontSize={12} ml={1}>
            {rows.length}件を表示 / 全{total}件
          </Typography>
        </Box>
      ) : (
        <></>
      )}

      {isLoading ? (
        <Box display='flex' justifyContent='center'>
          <CircularProgress />
        </Box>
      ) : (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: '96vw',  }}>
          <Table stickyHeader sx={{ tableLayout: 'fixed', width: '100%' }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.field}
                    align={column.align}
                    style={{ 
                      width: column.width, 
                      minWidth: column.width, 
                      maxWidth: column.width ,
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow 
                key={getRowId(row)} 
                sx={{ 
                  height: rowHeight,
                  backgroundColor: selectedRow === getRowId(row) ? 'action.selected' : 'inherit',
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  }, 
                 }} 
                onClick={() => handleRowClick(getRowId(row))}
                >
                  {columns.map((column) => (
                    <TableCell
                      key={`${getRowId(row)}-${column.field}`}
                      align={column.align}
                      sx={{
                        width: '100%',
                        paddingRight: '6px',
                        paddingLeft: '4px',
                        whiteSpace: 'normal',
                      }}
                    >
                    <Typography sx={{ display: '-webkit-box', WebkitLineClamp: 2,  WebkitBoxOrient: 'vertical' , lineHeight: '1.4em', maxHeight: '8.4em', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%'}} >
                      {column.renderCell 
                        ? column.renderCell({ 
                            row, 
                            field: column.field, 
                            value: row[column.field],
                          }) 
                        : row[column.field]}
                     </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      )}
    </Box>
  )
}

export default DataTable;