import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetTemplateMessageListParams = {
  messageBoxTemplateId: number
  paging: string
  pagingFanId?: number
}

export type TemplateMessage = {
  id: number
  fanId: number
  fanName: string
  fanIconUrl: string
  readFlag: number
  lastMessagedAt: string
  createdAt: string
  updatedAt: string
}

export type GetTemplateMessageListResponse = APIResponse<Array<TemplateMessage>>

export const getTemplateMessageListPath =
  '/admin/v1/message-box/template-message-list'

export const getTemplateMessageList = async (
  params: GetTemplateMessageListParams,
) => {
  return await http<GetTemplateMessageListResponse>(
    getTemplateMessageListPath,
    {
      method: 'GET',
      searchParams: {
        message_box_template_id: params.messageBoxTemplateId,
        paging: params.paging,
        paging_fan_id: params.pagingFanId,
      },
    },
  )
}
