import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetPostCommentParams = {
  page: number
  id: string
}

export type GetPostCommentResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    postId: number
    comment: string
    fanId: number
    fanName: string
    fanIconUrl: string
    toFan: {
      id: number
      name: string
      iconUrl: string
    } | null
    createdAt: string
    updatedAt: string
  }>
>

export const getPostCommentPath = '/admin/v1/post/:postId/comment'

export const getPostComment = async (params: GetPostCommentParams) => {
  return await http<GetPostCommentResponse>(getPostCommentPath, {
    method: 'GET',
    params: {
      postId: params.id,
    },
    searchParams: {
      page: params.page,
    },
  })
}
