export const StartupPopupType = {
  ARTIST_PICKUP: { value: 'ARTIST_PICKUP', text: 'アーティストピックアップ' },
  EVENT_RECRUIT: { value: 'EVENT_RECRUIT', text: 'イベント告知(募集中)' },
  EVENT_ACTIVE: { value: 'EVENT_ACTIVE', text: 'イベント告知(開催中)' },
  FAN_DAILY_MISSION: {
    value: 'FAN_DAILY_MISSION',
    text: 'ファンデイリーミッション',
  },
  WEB: { value: 'WEB', text: 'WEBで表示' },
} as const
export type StartupPopupTypeType = keyof typeof StartupPopupType

export const StartupPopupTargetType = {
  FAN_ALL: { value: 'FAN_ALL', text: 'ファン(全て)' },
  FAN_EXCLUDE_ARTIST: {
    value: 'FAN_EXCLUDE_ARTIST',
    text: 'ファン(アーティスト除く)',
  },
  FAN_NEWCOMER: { value: 'FAN_NEWCOMER', text: 'ファン(登録45日以内)' },
  FAN_ID: { value: 'FAN_ID', text: 'ファン(ID指定)' },
  FAN_GROUP: { value: 'FAN_GROUP', text: 'ファングループ(ID指定)' },
  ARTIST: { value: 'ARTIST', text: 'アーティスト' },
  ARTIST_NEWCOMER: { value: 'ARTIST_NEWCOMER', text: 'アーティスト(新人)' },
} as const
export type StartupPopupTargetTypeType = keyof typeof StartupPopupTargetType

export const DisplayStatus = {
  ON: { value: 'ON', text: '表示', color: 'success' },
  OFF: { value: 'OFF', text: '非表示', color: 'error' },
} as const
export type DisplayStatusType = keyof typeof DisplayStatus

export type StartupPopupFormValues = {
  type: string
  displayStartAt: Date | null
  displayEndAt: Date | null
  pickupArtistFanIds: string
  eventId: number | ''
  imageUrl: string
  imageFile: File | null
  destinationUrl: string
  targetType: string
  targetFanIds: string
  targetFanGroupId: number | ''
  description: string
  isDisplayed: string
}
