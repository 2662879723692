import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { MessageOutlined } from '@mui/icons-material'
import { Box, Chip, Grid, Link, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import {
  getMessageBoxTemplate,
  getMessageBoxTemplatePath,
} from '@/features/message-boxes/api/getMessageBoxTemplate'
import {
  PublishStatus,
  SendStatus,
  TemplateStatus,
} from '@/features/message-boxes/types'
import { useNavigate } from '@/router'
import { isEmptyObj } from '@/utils/functions'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'メッセージボックス（運営）'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const page = Number(searchParams.get('page') || '1')
  const title = searchParams.get('title') || ''
  const fanGroupId = Number(searchParams.get('fanGroupId'))

  const { data, isValidating, mutate } = useSWR(
    `${getMessageBoxTemplatePath}-OFFICIAL`,
    () =>
      getMessageBoxTemplate({
        page: page,
        title: title,
        fanGroupId: fanGroupId > 0 ? fanGroupId : undefined,
        sendFrom: 'OFFICIAL',
      }),
  )
  const { control, getValues } = useForm({
    defaultValues: {
      title: title,
      fanGroupId: fanGroupId > 0 ? String(fanGroupId) : '',
    },
  })

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'title', value: getValues('title') },
        { key: 'fanGroupId', value: getValues('fanGroupId') },
      ])
      return prev
    })
    mutate(
      getMessageBoxTemplate({
        page: page,
        fanGroupId: getValues('fanGroupId')
          ? Number(getValues('fanGroupId'))
          : undefined,
        sendFrom: 'ARTIST',
      }),
    )
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    })
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    setSearchParams({ page: value.toString() })
    search({ page: value })
  }

  const goDetail = (id = '') => {
    navigate('/message-boxes/admin/:messageId', {
      params: {
        messageId: id,
      },
    })
  }

  const goMessageList = (id = '') => {
    navigate('/message-boxes/admin/:messageId/recipients', {
      params: {
        messageId: id,
      },
    })
  }

  const formatData = data?.result.data.map((item) => {
    const sentCount = item.sentCount
    const readCount = item.readCount
    const readRate = sentCount > 0 ? (readCount / sentCount) * 100 : 0

    return {
      id: item.id,
      fanGroup: isEmptyObj(item.fanGroup)
        ? null
        : {
            id: item.fanGroup.id,
            name: item.fanGroup.name,
          },
      title: item.title,
      publishStatus: {
        text: PublishStatus[item.publishStatus].text,
        color: PublishStatus[item.publishStatus].color,
      },
      templateStatus: {
        text: TemplateStatus[item.templateStatus].text,
        color: TemplateStatus[item.templateStatus].color,
      },
      sendStatus: {
        text: SendStatus[item.sendStatus].text,
        color: SendStatus[item.sendStatus].color,
      },
      sentAt: item.sentAt.length > 0 ? item.sentAt : '未送信',
      sentCount: item.sentCount,
      readRate: readRate,
      reservedAt: item.reservedAt.length > 0 ? item.reservedAt : '予約設定なし',
    }
  })

  const goCreate = () => {
    navigate('/message-boxes/admin/create')
  }

  const goFanIdGroupDetail = (fanGroupId = '') => {
    navigate('/fan-id-groups/:fanGroupId', {
      params: {
        fanGroupId: fanGroupId,
      },
    })
  }

  return (
    <Box className='page'>
      <Box>
        <PageTitle actions={[{ text: '新規作成', onClick: goCreate }]}>
          {pageTitle}
        </PageTitle>
        <Box
          onSubmit={(e) => {
            handleSearchSubmit(e)
          }}
          component='form'
        >
          <Grid container columnSpacing={3} mb={3}>
            <Grid item>
              <FormText
                control={control}
                name='title'
                label='タイトル'
                fullWidth={false}
                hint='部分一致'
              />
            </Grid>
            <Grid item>
              <FormText
                control={control}
                name='fanGroupId'
                label='ファンIDグループのID'
                type='number'
                fullWidth={false}
              />
            </Grid>
            <Grid item>
              <ActionButton type='submit'>検索</ActionButton>
            </Grid>
          </Grid>
        </Box>

        <DataTable
          columns={[
            { field: 'id', headerName: 'ID', width: 100 },
            { field: 'title', headerName: 'タイトル', width: 300 },
            {
              field: 'fanGroup',
              headerName: 'ファンIDグループ',
              width: 300,
              renderCell: (params) =>
                params.value ? (
                  <Box display={'flex'} gap={2} alignItems={'center'}>
                    <Box>
                      # <CopyClipboard text={params.value.id} />
                    </Box>
                    <Link
                      onClick={() => goFanIdGroupDetail(params.value.id)}
                      color='inherit'
                      underline='hover'
                    >
                      {params.value.name}
                    </Link>
                  </Box>
                ) : (
                  '-'
                ),
            },
            {
              field: 'publishStatus',
              headerName: '公開ステータス',
              width: 150,
              renderCell: (params) => {
                return (
                  <Chip
                    size='small'
                    color={params.value.color}
                    variant='outlined'
                    label={params.value.text}
                  />
                )
              },
            },
            {
              field: 'templateStatus',
              headerName: '処理ステータス',
              width: 150,
              renderCell: (params) => {
                return (
                  <Chip
                    size='small'
                    color={params.value.color}
                    variant='outlined'
                    label={params.value.text}
                  />
                )
              },
            },
            {
              field: 'sendStatus',
              headerName: '送信ステータス',
              width: 150,
              renderCell: (params) => {
                return (
                  <Chip
                    size='small'
                    color={params.value.color}
                    variant='outlined'
                    label={params.value.text}
                  />
                )
              },
            },
            { field: 'sentAt', headerName: '配信日時', width: 200 },
            {
              field: 'sentCount',
              headerName: '配信数',
              width: 100,
              align: 'center',
              renderCell: (params) => (
                <Box display={'flex'}>
                  <MessageOutlined
                    sx={{
                      cursor: params.row.sentCount > 0 ? 'pointer' : 'default',
                    }}
                    onClick={() => {
                      if (params.row.sentCount > 0) {
                        goMessageList(params.row.id)
                      }
                    }}
                  />
                  <Typography>（{params.row.sentCount}）</Typography>
                </Box>
              ),
            },
            {
              field: 'readRate',
              headerName: '既読率(%)',
              align: 'right',
              width: 100,
            },
            { field: 'reservedAt', headerName: '配信予約日時', width: 200 },
          ]}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
        ></DataTable>
      </Box>
    </Box>
  )
}

export default Page
