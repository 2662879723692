import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type AppVersionSetting = {
  iosCurrentMinVersion: string | null
  androidCurrentMinVersion: string | null
  iosNextApplyDatetime: string | null
  androidNextApplyDatetime: string | null
  iosNextMinVersion: string | null
  androidNextMinVersion: string | null
  createdAt: string | null
  updatedAt: string | null
}

export type GetAppVersionSettingResponse = APIResponse<AppVersionSetting>

export const getAppVersionSettingPath = '/admin/v1/app-version-setting'

export const getAppVersionSetting = async () => {
  return await http<GetAppVersionSettingResponse>(getAppVersionSettingPath, {
    method: 'GET',
  })
}
