import type { APIResponse, APIResponseResultPagination, EventTypeType } from '@/types'

import { http } from '@/lib/http'

export type GetTotalEventArtistReportParams = {
  page: number
  eventId?: string
  eventName?: string
  artistId?: string
  artistName?: string
  isNewcomer?: boolean
  isFirstGachi?: boolean
}

export type GetTotalEventArtistReportResponse = APIResponse<
  APIResponseResultPagination<{
    eventId: number
    eventName: string
    eventType: EventTypeType
    artistId: number
    artistName: string
    fanId: number
    artistIconUrl: string
    isNewcomer: boolean
    isFirstGachi: boolean
    postCount: number
    liveCount: number
    liveDuration: string
    cheerCount: number
    pointCheerCount: number
    freeCheerCount: number
    limitedCheerCount: number
    cheerUU: number
    pointCheerUU: number
    freeCheerUU: number
    limitedCheerUU: number
    createdAt: string
    updatedAt: string
  }>
>

export const getTotalEventArtistReportPath = '/admin/v1/total-event-artist-report'

export const getTotalEventArtistReport = async (params: GetTotalEventArtistReportParams) => {
  return await http<GetTotalEventArtistReportResponse>(
    getTotalEventArtistReportPath,
    {
      method: 'GET',
      searchParams: {
        page: params.page,
        event_id: params.eventId,
        event_name: params.eventName,
        artist_id: params.artistId,
        artist_name: params.artistName,
        is_newcomer: params.isNewcomer,
        is_first_gachi: params.isFirstGachi,
      },
    },
  )
}
