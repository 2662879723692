import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import ImageView from '@cz_frontend/ui/components/common/ImageView/ImageView'
import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { OpenInNew } from '@mui/icons-material'
import { Box, Chip, Grid, Link, Tooltip } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import type { EventTypeType } from '@/types'

import { getBanner, getBannerPath } from '@/features/banners/api/getBanner'
import { BannerDisplayTarget, DisplayStatus } from '@/features/banners/types'
import { BannerDestination, BannerTarget } from '@/features/banners/types'
import { formatDateTime, Format, newDate } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { EventType } from '@/types'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'バナー一覧'
  usePageInfo({ title: pageTitle })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    displayStartDate: '',
    displayEndDate: '',
    target: '',
    displayTarget: '',
    destination: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const displayStartDate = searchParams.get('displayStartDate') || null
  const displayEndDate = searchParams.get('displayEndDate') || null
  const target = searchParams.get('target') || ''
  const displayTarget = searchParams.get('displayTarget') || ''
  const destination = searchParams.get('destination') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      displayStartDate,
      displayEndDate,
      target,
      displayTarget,
      destination,
    },
  })

  const { data, isValidating, mutate } = useSWR(getBannerPath, () =>
    getBanner({
      page: page,
      displayStartDate: displayStartDate
        ? formatDateTime(displayStartDate, Format.dateRequest)
        : undefined,
      displayEndDate: displayEndDate
        ? formatDateTime(displayEndDate, Format.dateRequest)
        : undefined,
      target: target || undefined,
      displayTarget: displayTarget || undefined,
      destination: destination || undefined,
    }),
  )

  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        {
          key: 'displayStartDate',
          value: formatDateTime(
            getValues('displayStartDate'),
            Format.dateRequest,
          ),
        },
        {
          key: 'displayEndDate',
          value: formatDateTime(
            getValues('displayEndDate'),
            Format.dateRequest,
          ),
        },
        { key: 'target', value: getValues('target') },
        { key: 'displayTarget', value: getValues('displayTarget') },
        { key: 'destination', value: getValues('destination') },
      ])
      return prev
    })
    mutate(
      getBanner({
        page: page,
        displayStartDate: getValues('displayStartDate')
          ? formatDateTime(getValues('displayStartDate'), Format.dateRequest)
          : undefined,
        displayEndDate: getValues('displayEndDate')
          ? formatDateTime(getValues('displayEndDate'), Format.dateRequest)
          : undefined,
        target: getValues('target') || undefined,
        displayTarget: getValues('displayTarget') || undefined,
        destination: getValues('destination') || undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (page: number) => {
    search({ page })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({ page: Number(page) })
  }

  const goDetail = (id = '') => {
    navigate('/banners/:bannerId', {
      params: {
        bannerId: id,
      },
    })
  }

  const goCreate = () => {
    navigate('/banners/create')
  }

  const goFanDetail = (fanId: number) => {
    navigate('/fans/:fanId', {
      params: {
        fanId: String(fanId),
      },
    })
  }

  const goEventDetail = (eventId: number, type: EventTypeType) => {
    navigate(`/events/${EventType[type].page}/:eventId`, {
      params: {
        eventId: String(eventId),
      },
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      bannerUrl: item.bannerUrl,
      displayStartAt: item.displayStartAt,
      displayEndAt: item.displayEndAt,
      displayPeriodColor:
        newDate(item.displayStartAt) < newDate() &&
        (item.displayEndAt === null || newDate() <= newDate(item.displayEndAt))
          ? 'success.main'
          : 'default',
      priority: item.priority,
      target: BannerTarget[item.target].text,
      displayTarget: BannerDisplayTarget[item.displayTarget].text,
      destination: BannerDestination[item.destination].text,
      url: item.url,
      eventId: item.eventId,
      eventType: item.eventType,
      eventName: item.eventName,
      artistId: item.artistId,
      artistFanId: item.artistFanId,
      artistName: item.artistName,
      displayStatus: {
        text: DisplayStatus[item.isDisplayed].text,
        color: DisplayStatus[item.isDisplayed].color,
      },
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '新規作成', onClick: goCreate }]}>
        {pageTitle}
      </PageTitle>
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Grid container columnSpacing={3} mb={3}>
          <Grid item>
            <FormDate
              control={control}
              name='displayStartDate'
              label='表示開始日'
              views={['year', 'month', 'day']}
              fullWidth={false}
            />
          </Grid>
          <Grid item>
            <FormDate
              control={control}
              name='displayEndDate'
              label='表示終了日'
              views={['year', 'month', 'day']}
              fullWidth={false}
            />
          </Grid>
          <Grid item>
            <FormSelect
              control={control}
              name='target'
              label='表示デバイス'
              fullWidth={false}
              options={[{ value: '', text: '選択してください' }].concat(
                Object.entries(BannerTarget).map(([, item]) => ({
                  value: item.value,
                  text: item.text,
                })),
              )}
            />
          </Grid>
          <Grid item>
            <FormSelect
              control={control}
              name='displayTarget'
              label='表示対象'
              fullWidth={false}
              options={[{ value: '', text: '選択してください' }].concat(
                Object.entries(BannerDisplayTarget).map(([, item]) => ({
                  value: item.value,
                  text: item.text,
                })),
              )}
            />
          </Grid>
          <Grid item>
            <FormSelect
              control={control}
              name='destination'
              label='遷移先'
              fullWidth={false}
              options={[{ value: '', text: '選択してください' }].concat(
                Object.entries(BannerDestination).map(([, item]) => ({
                  value: item.value,
                  text: item.text,
                })),
              )}
            />
          </Grid>
          <Grid item>
            <ActionButton type='submit'>検索</ActionButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataGrid
          columns={[
            {
              field: 'id',
              headerName: 'ID',
              width: 70,
              headerAlign: 'right',
            },
            {
              field: 'bannerUrl',
              headerName: 'バナー',
              width: 150,
              align: 'center',
              renderCell: (params) => (
                <Box my={3}>
                  <ImageView
                    imageSrc={params.value}
                    imageAlt='バナー画像'
                    style={{ height: '80px' }}
                  />
                </Box>
              ),
            },
            {
              field: 'displayStartAt',
              headerName: '表示開始日時',
              width: 200,
              renderCell: (params) => (
                <Box color={params.row.displayPeriodColor}>{params.value}</Box>
              ),
            },
            {
              field: 'displayEndAt',
              headerName: '表示終了日時',
              width: 200,
              renderCell: (params) => (
                <Box color={params.row.displayPeriodColor}>{params.value}</Box>
              ),
            },
            {
              field: 'priority',
              headerName: '優先度',
              width: 70,
              headerAlign: 'right',
            },
            {
              field: 'target',
              headerName: '表示デバイス',
              width: 150,
            },
            {
              field: 'displayTarget',
              headerName: '表示対象',
              width: 200,
            },
            {
              field: 'destination',
              headerName: '遷移先',
              width: 200,
            },
            {
              field: 'url',
              headerName: '遷移先URL',
              width: 200,
              renderCell: (params) =>
                params.value && (
                  <Tooltip title={params.value}>
                    <Link
                      href={params.value}
                      target='_blank'
                      rel='noopener'
                      style={{ display: 'inline-flex', alignItems: 'center' }}
                    >
                      <OpenInNew fontSize='small' style={{ marginRight: 4 }} />
                      遷移先を開く
                    </Link>
                  </Tooltip>
                ),
            },
            {
              field: 'eventId',
              headerName: 'イベントID',
              width: 100,
              headerAlign: 'right',
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'eventName',
              headerName: 'イベント名',
              width: 250,
              renderCell: (params) =>
                params.row.eventId && (
                  <Link
                    onClick={() =>
                      goEventDetail(params.row.eventId, params.row.eventType)
                    }
                    color='inherit'
                    underline='hover'
                  >
                    {params.value}
                  </Link>
                ),
            },
            {
              field: 'artistId',
              headerName: 'アーティストID',
              width: 150,
              headerAlign: 'right',
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'artistName',
              headerName: 'アーティスト名',
              width: 200,
              renderCell: (params) =>
                params.row.artistId && (
                  <Link
                    onClick={() => goFanDetail(params.row.artistFanId)}
                    color='inherit'
                    underline='hover'
                  >
                    {params.value}
                  </Link>
                ),
            },
            {
              field: 'displayStatus',
              headerName: '表示/非表示',
              width: 150,
              renderCell: (params) => {
                return (
                  <Chip
                    size='small'
                    color={params.value.color}
                    variant='outlined'
                    label={params.value.text}
                  />
                )
              },
            },
            {
              field: 'createdAt',
              headerName: '作成日時',
              width: 200,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 200,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
          rowHeight={90}
        />
      </Box>
    </Box>
  )
}

export default Page
