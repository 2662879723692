import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { CalendarMonthOutlined, TodayOutlined } from '@mui/icons-material'
import { Avatar, Box, Chip } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { getFan, getFanPath } from '@/features/fan/api/getFan'
import { useNavigate } from '@/router'
import { FanStatus } from '@/types'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'ファン一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const page = Number(searchParams.get('page') || '1')
  const fanIds = searchParams.get('fanIds') || ''
  const name = searchParams.get('name') || ''
  const artistIds = searchParams.get('artistIds') || ''
  const status = searchParams.get('status') || ''
  const isArtist = searchParams.get('isArtist') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      fanIds: fanIds,
      name: name,
      artistIds: artistIds,
      status: status,
      isArtist: isArtist,
    },
  })

  // カンマ区切りのID文字列をnumber[]にする
  const parseIds = (ids: string) => {
    return ids.split(',').map((id) => Number(id))
  }

  const { data, isValidating, mutate } = useSWR(getFanPath, () =>
    getFan({
      page: page,
      fanIds: fanIds ? parseIds(fanIds) : undefined,
      name: name ? name : undefined,
      artistIds: artistIds ? parseIds(artistIds) : undefined,
      status: status,
      isArtist: isArtist ? Boolean(isArtist === 'true') : undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'fanIds', value: getValues('fanIds') },
        { key: 'name', value: getValues('name') },
        { key: 'artistIds', value: getValues('artistIds') },
        { key: 'status', value: getValues('status')},
        { key: 'isArtist', value: getValues('isArtist')}
      ])
      return prev
    })
    mutate(
      getFan({
        page: page,
        fanIds: getValues('fanIds') ? parseIds(getValues('fanIds')) : undefined,
        name: getValues('name') ? getValues('name') : undefined,
        artistIds: getValues('artistIds')
          ? parseIds(getValues('artistIds'))
          : undefined,
        status: getValues('status'),
        isArtist: getValues('isArtist') ? Boolean(getValues('isArtist')) : undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    setSearchParams({ page: value.toString() })
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const goDetail = (id = '') => {
    navigate('/fans/:fanId', {
      params: {
        fanId: id,
      },
    })
  }

  const goFanDailyRanking = (artistId = '') => {
    navigate('/artists/:artistId/ranking/fans/daily', {
      params: {
        artistId: artistId,
      },
    })
  }

  const goFanMonthlyRanking = (artistId = '') => {
    navigate('/artists/:artistId/ranking/fans/monthly', {
      params: {
        artistId: artistId,
      },
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      fanId: item.fanId,
      iconUrl: item.iconUrl,
      name: item.name,
      status: {
        text: FanStatus[item.status].text,
        color: FanStatus[item.status].color,
      },
      birthday: item.birthday,
      artistId: item.artistId > 0 ? item.artistId : '-',
      deletedAt: item.deletedAt ?? '-',
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  const statusOptions = () => {
    const empty = [{ value: '', text: '選択してください' }]
    const base = Object.entries(FanStatus).map(([key, value]) => ({
      value: key,
      text: value.text,
    }))
    return empty.concat(base)
  }

  return (
    <Box className='page'>
      <PageTitle>{pageTitle}</PageTitle>
      <Box
        display={'flex'}
        gap={4}
        alignItems={'center'}
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Box display={'flex'} gap={2} alignItems={'flex-start'}>
          <FormText
            control={control}
            name='fanIds'
            label='ファンID'
            fullWidth={false}
            hint={'カンマ区切りで複数指定可能'}
          />
          <FormText
            control={control}
            name='name'
            label='ファン名'
            fullWidth={false}
          />
          <FormSelect
            control={control}
            name='status'
            label='ステータス'
            fullWidth={false}
            options={statusOptions()}
          />
          <FormText
            control={control}
            name='artistIds'
            label='アーティストID'
            fullWidth={false}
            hint={'カンマ区切りで複数指定可能'}
          />
          <FormSelect
            control={control}
            name='isArtist'
            label='アーティスト登録'
            fullWidth={false}
            options={[
              { value: '', text: '選択してください' },
              { value: 'true', text: '登録済み' },
              { value: 'false', text: '未登録' },
            ]}
          />
        </Box>
        <ActionButton type='submit'>検索</ActionButton>
      </Box>
      <Box>
        <DataTable
          columns={[
            { field: 'fanId', headerName: 'ファンID', width: 80 },
            {
              field: 'iconUrl',
              headerName: '',
              width: 50,
              align: 'center',
              renderCell: (params) => {
                return (
                  <Avatar
                    src={params.value}
                    alt='アイコン'
                    sx={{ width: 40, height: 40 }}
                  />
                )
              },
            },
            { field: 'name', headerName: 'ファン名', width: 200 },
            {
              field: 'status',
              headerName: 'ステータス',
              width: 90,
              renderCell: (params) => {
                return (
                  <Chip
                    size='small'
                    color={params.value.color}
                    variant='outlined'
                    label={params.value.text}
                  />
                )
              },
            },
            { field: 'birthday', headerName: '誕生日', width: 110 },
            {
              field: 'artistId',
              headerName: 'アーティストID',
              width: 120,
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: '_fanDailyRanking',
              headerName: 'ファンランキング 日別',
              width: 170,
              align: 'center',
              renderCell: (params) =>
                params.row.artistId > 0 ? (
                  <TodayOutlined
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() => goFanDailyRanking(params.row.artistId)}
                  />
                ) : (
                  '-'
                ),
            },
            {
              field: '_fanMonthlyRank',
              headerName: '月別',
              width: 70,
              renderCell: (params) =>
                params.row.artistId > 0 ? (
                  <CalendarMonthOutlined
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() => goFanMonthlyRanking(params.row.artistId)}
                  />
                ) : (
                  '-'
                ),
            },
            { field: 'createdAt', headerName: '作成日時', width: 180 },
            { field: 'updatedAt', headerName: '更新日時', width: 180 },
            { field: 'deletedAt', headerName: '退会・削除', width: 180 },
          ]}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
          idPropertyName='fanId'
          editReferProp='fanId'
        ></DataTable>
      </Box>
    </Box>
  )
}

export default Page
