import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { GetDevicePath, getDevice } from '@/features/fan/api/getDevice'
import { DeviceType } from '@/features/fan/types'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'デバイス一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    fanId: '',
    fanName: '',
    deviceCode: '',
    deviceId: '',
    deviceType: '',
    osVersion: '',
    appVersion: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const fanId = searchParams.get('fanId') || ''
  const fanName = searchParams.get('fanName') || ''
  const deviceCode = searchParams.get('deviceCode') || ''
  const deviceId = searchParams.get('deviceId') || ''
  const deviceType = searchParams.get('deviceType') || ''
  const osVersion = searchParams.get('osVersion') || ''
  const appVersion = searchParams.get('appVersion') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      fanId: fanId,
      fanName: fanName,
      deviceCode: deviceCode,
      deviceId: deviceId,
      deviceType: deviceType,
      osVersion: osVersion,
      appVersion: appVersion,
    },
  })

  const { data, isValidating, mutate } = useSWR(GetDevicePath, () =>
    getDevice({
      page: page,
      fanId: fanId || undefined,
      fanName: fanName || undefined,
      deviceCode: deviceCode || undefined,
      deviceId: deviceId || undefined,
      deviceType: deviceType || undefined,
      osVersion: osVersion || undefined,
      appVersion: appVersion || undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'fanId', value: getValues('fanId') },
        { key: 'fanName', value: getValues('fanName') },
        { key: 'deviceCode', value: getValues('deviceCode') },
        { key: 'deviceId', value: getValues('deviceId') },
        { key: 'deviceType', value: getValues('deviceType') },
        { key: 'osVersion', value: getValues('osVersion') },
        { key: 'appVersion', value: getValues('appVersion') },
      ])
      return prev
    })
    mutate(
      getDevice({
        page: page,
        fanId: getValues('fanId') || undefined,
        fanName: getValues('fanName') || undefined,
        deviceCode: getValues('deviceCode') || undefined,
        deviceId: getValues('deviceId') || undefined,
        deviceType: getValues('deviceType') || undefined,
        osVersion: getValues('osVersion') || undefined,
        appVersion: getValues('appVersion') || undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      fanId: item.fanId,
      fanName: item.fanName,
      deviceId: item.deviceId,
      deviceCode: item.deviceCode,
      deviceType: item.deviceType,
      osVersion: item.osVersion,
      appVersion: item.appVersion,
      pushToken: item.pushToken,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  const goFanDetail = (fanId: number) => {
    navigate('/fans/:fanId', {
      params: {
        fanId: String(fanId),
      },
    })
  }

  const deviceTypeOptions = () => {
    const empty = [{ value: '', text: '選択してください' }]
    const base = Object.entries(DeviceType).map(([key]) => ({
      value: key,
      text: key,
    }))
    return empty.concat(base)
  }

  return (
    <Box className='page'>
      <PageTitle>{pageTitle}</PageTitle>
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Box display={'flex'} gap={2} alignItems={'flex-start'}>
          <FormText
            control={control}
            name='fanId'
            label='ファンID'
            fullWidth={false}
            hint={'カンマ区切りで複数指定可能'}
          />
          <FormText
            control={control}
            name='fanName'
            label='ファン名'
            fullWidth={false}
            hint={'部分一致'}
          />
          <FormText
            control={control}
            name='deviceCode'
            label='機種'
            fullWidth={false}
            hint={'部分一致'}
          />
          <FormSelect
            control={control}
            name='deviceType'
            label='種別'
            fullWidth={false}
            options={deviceTypeOptions()}
          />
        </Box>
        <Box display={'flex'} gap={2} alignItems={'center'}>
          <Box display={'flex'} gap={2} alignItems={'flex-start'}>
            <FormText
              control={control}
              name='deviceId'
              label='デバイスID'
              fullWidth={false}
              hint={'部分一致'}
            />
            <FormText
              control={control}
              name='osVersion'
              label='OSver'
              fullWidth={false}
              hint={'部分一致'}
            />
            <FormText
              control={control}
              name='appVersion'
              label='アプリver'
              fullWidth={false}
              hint={'部分一致'}
            />
          </Box>
          <ActionButton type='submit'>検索</ActionButton>
        </Box>
      </Box>
      <Box>
        <DataTable
          columns={[
            { field: 'id', headerName: 'ID', width: 50 },
            {
              field: 'fanId',
              headerName: 'ファンID',
              width: 80,
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'fanName',
              headerName: 'ファン名',
              width: 250,
              renderCell: (params) => (
                <Link
                  onClick={() => goFanDetail(params.row.fanId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'deviceId',
              headerName: 'デバイスID',
              width: 350,
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'deviceCode',
              headerName: '機種',
              width: 100,
            },
            {
              field: 'deviceType',
              headerName: '種別',
              width: 80,
            },
            {
              field: 'osVersion',
              headerName: 'OSver',
              width: 80,
            },
            {
              field: 'appVersion',
              headerName: 'アプリver',
              width: 90,
            },
            {
              field: 'pushToken',
              headerName: 'プッシュトークン',
              width: 200,
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'createdAt',
              headerName: '作成日時',
              width: 180,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 180,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          editReferProp={'id'}
        ></DataTable>
      </Box>
    </Box>
  )
}

export default Page
