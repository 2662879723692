import { useState, type FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box, Chip, Grid, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { getArtistCoinHistory, getArtistCoinHistoryPath } from '@/features/histories/api/getArtistCoinHistory'
import { getArtistCoinHistoryDownloadCsv } from '@/features/histories/api/getArtistCoinHistoryDownloadCsv'
import { ArtistCoinHistoryType } from '@/features/histories/types'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { downloadCsv, pickErrorMessages } from '@/utils/functions'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'



function Page() {
  const pageTitle = 'コイン履歴一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    artistId: '',
    artistName: '',
    type: '',
    dateFrom: '',
    dateTo: '',
  })
  const [isLoading, setIsLoading] = useState(false)

  const page = Number(searchParams.get('page') || '1')
  const artistId = searchParams.get('artistId') || ''
  const artistName = searchParams.get('artistName') || ''
  const type = searchParams.get('type') || ''
  const dateFromParam = searchParams.get('dateFrom')
  const dateFrom = dateFromParam ? newDate(dateFromParam) : null
  const dateToParam = searchParams.get('dateTo')
  const dateTo = dateToParam ? newDate(dateToParam) : null

  const { control, getValues } = useForm({
    defaultValues: {
      artistId: artistId,
      artistName: artistName,
      type: type,
      dateFrom: dateFrom,
      dateTo: dateTo,
    },
  })

  const { data, isValidating, mutate } = useSWR(getArtistCoinHistoryPath, () =>
    getArtistCoinHistory({
      page: page,
      artistId: artistId || undefined,
      artistName: artistName || undefined,
      type: type || undefined,
      dateFrom: dateFrom
        ? formatDateTime(dateFrom, Format.dateRequest)
        : undefined,
      dateTo: dateTo ? formatDateTime(dateTo, Format.dateRequest) : undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'artistId', value: getValues('artistId') },
        { key: 'artistName', value: getValues('artistName') },
        { key: 'type', value: getValues('type') },
        {
          key: 'dateFrom',
          value: formatDateTime(getValues('dateFrom'), Format.dateRequest),
        },
        {
          key: 'dateTo',
          value: formatDateTime(getValues('dateTo'), Format.dateRequest),
        },
      ])
      return prev
    })
    mutate(
      getArtistCoinHistory({
        page: page,
        artistId: getValues('artistId') || undefined,
        artistName: getValues('artistName') || undefined,
        type: getValues('type') || undefined,
        dateFrom: getValues('dateFrom')
          ? formatDateTime(getValues('dateFrom'), Format.dateRequest)
          : undefined,
        dateTo: getValues('dateTo')
          ? formatDateTime(getValues('dateTo'), Format.dateRequest)
          : undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: 1,
    })
  }

  // CSVダウンロード
  const handleDownloadCsv = async () => {
    try {
      const res = await getArtistCoinHistoryDownloadCsv({
        artistId: getValues('artistId') || undefined,
        artistName: getValues('artistName') || undefined,
        type: getValues('type') || undefined,
        dateFrom: getValues('dateFrom')
          ? formatDateTime(getValues('dateFrom'), Format.dateRequest)
          : undefined,
        dateTo: getValues('dateTo')
          ? formatDateTime(getValues('dateTo'), Format.dateRequest)
          : undefined,
      })

      const resBlob = await res.blob()
      const contentDisposition = res.headers.get('content-disposition')
      const fileName = contentDisposition
        ? contentDisposition.split('filename=')[1]
        : ''
      downloadCsv(resBlob, fileName)
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      artistId: item.artistId,
      artistName: item.artistName,
      fanId: item.fanId,
      coin: item.coin,
      type: {
        text: ArtistCoinHistoryType[item.type].text,
        color: ArtistCoinHistoryType[item.type].color,
      },
      description: item.description,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  const goFanDetail = (fanId = '') => {
    navigate('/fans/:fanId', {
      params: {
        fanId: fanId,
      },
    })
  }

  const typeOptions = () => {
    const empty = [{ value: '', text: '選択してください' }]
    const base = Object.entries(ArtistCoinHistoryType).map(([, value]) => ({
      value: value.value,
      text: value.text,
    }))
    return empty.concat(base)
  }

  return (
    <Box className='page'>
      <PageTitle
        actions={[{ text: 'CSVダウンロード', onClick: handleDownloadCsv }]}
        isLoading={isLoading}
      >
        {pageTitle}
      </PageTitle>
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Grid container columnSpacing={3} mb={3}>
          <Grid item>
            <FormText
              control={control}
              name='artistId'
              label='アーティストID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='artistName'
              label='アーティスト名'
              fullWidth={false}
              hint='部分一致'
            />
          </Grid>
          <Grid item>
            <FormSelect
              control={control}
              name='type'
              label='種別'
              fullWidth={false}
              options={typeOptions()}
            />
          </Grid>
          <Grid item>
            <FormDate
              control={control}
              name='dateFrom'
              views={['year', 'month', 'day']}
              fullWidth={false}
              label='開始日'
            />
          </Grid>
          <Grid item>
            <FormDate
              control={control}
              name='dateTo'
              views={['year', 'month', 'day']}
              fullWidth={false}
              label='終了日'
            />
          </Grid>
          <Grid item>
            <ActionButton type='submit'>検索</ActionButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataTable
          columns={[
            {
              field: 'id',
              headerName: 'ID',
              headerAlign: 'right',
              width: 80,
            },
            {
              field: 'artistId',
              headerName: 'アーティストID',
              width: 120,
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'artistName',
              headerName: 'アーティスト名',
              width: 250,
              renderCell: (params) => (
                <Link
                  onClick={() => goFanDetail(params.row.fanId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'type',
              headerName: '種別',
              width: 200,
              renderCell: (params) => {
                return (
                  <Chip
                    size='small'
                    color={params.value.color}
                    variant='outlined'
                    label={params.value.text}
                  />
                )
              },
            },
            {
              field: 'coin',
              headerName: 'コイン数',
              headerAlign: 'right',
              width: 150,
            },
            {
              field: 'description',
              headerName: '概要',
              width: 250,
            },
            {
              field: 'createdAt',
              headerName: '処理日時',
              width: 200,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
        ></DataTable>
      </Box>
    </Box>
  )
}

export default Page
