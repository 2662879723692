import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PatchStartupPopupParams = {
  popupId: number
  type: string
  displayStartAt: string | null
  displayEndAt: string | null
  pickupArtistFanIds: string | null
  eventId: number | null
  imageFileName: string | null
  destinationUrl: string | null
  targetType: string
  targetFanIds: string | null
  targetFanGroupId: number | null
  description: string | null
  isDisplayed: string
}

export type PatchStartupPopupResponse = APIResponse<{
  id: number
  imageUploadUrl: string
}>

export const patchStartupPopupPath = '/admin/v1/startup-popup/:popupId'

export const patchStartupPopup = async (params: PatchStartupPopupParams) => {
  return await http<PatchStartupPopupResponse>(patchStartupPopupPath, {
    method: 'PATCH',
    params: {
      popupId: params.popupId,
    },
    body: JSON.stringify({
      type: params.type,
      display_start_at: params.displayStartAt,
      display_end_at: params.displayEndAt,
      pickup_artist_fan_ids: params.pickupArtistFanIds?.split(','),
      event_id: params.eventId,
      image_file_name: params.imageFileName,
      destination_url: params.destinationUrl,
      target_type: params.targetType,
      target_fan_ids: params.targetFanIds?.split(','),
      target_fan_group_id: params.targetFanGroupId,
      description: params.description,
      is_displayed: params.isDisplayed,
    }),
  })
}
