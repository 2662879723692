import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Grid, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import type { EventTypeType } from '@/types';

import {
  getCheerCollect,
  getCheerCollectPath,
} from '@/features/histories/api/getCheerCollect'
import { useNavigate } from '@/router'
import { CheerType, EventType } from '@/types'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'CHEER受取一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    id: '',
    fanId: '',
    name: '',
    eventId: '',
    eventName: '',
    type: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const id = searchParams.get('id') || ''
  const fanId = searchParams.get('fanId') || ''
  const name = searchParams.get('name') || ''
  const eventId = searchParams.get('eventId') || ''
  const eventName = searchParams.get('eventName') || ''
  const type = searchParams.get('type') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      id: id,
      fanId: fanId,
      name: name,
      eventId: eventId,
      eventName: eventName,
      type: type,
    },
  })

  const { data, isValidating, mutate } = useSWR(getCheerCollectPath, () =>
    getCheerCollect({
      page: page,
      id: id || undefined,
      fanId: fanId || undefined,
      name: name || undefined,
      eventId: eventId || undefined,
      eventName: eventName || undefined,
      type: type || undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'id', value: getValues('id') },
        { key: 'fanId', value: getValues('fanId') },
        { key: 'name', value: getValues('name') },
        { key: 'eventId', value: getValues('eventId') },
        { key: 'eventName', value: getValues('eventName') },
        { key: 'type', value: getValues('type') },
      ])
      return prev
    })
    mutate(
      getCheerCollect({
        page: page,
        id: getValues('id') || undefined,
        fanId: getValues('fanId') || undefined,
        name: getValues('name') || undefined,
        eventId: getValues('eventId') || undefined,
        eventName: getValues('eventName') || undefined,
        type: getValues('type') || undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      fanId: item.fanId,
      fanName: item.fanName,
      eventId: item.eventId,
      eventName: item.eventName,
      eventType: item.eventType,
      cheerCount: item.cheerCount,
      usedCheerCount: item.usedCheerCount,
      cheerType: CheerType[item.cheerType].text,
      expiredAt: item.expiredAt ?? '-',
      createdAt: item.createdAt,
    }
  })

  const cheerTypes = () => {
    const empty = [{ value: '', text: '選択してください' }]
    const base = Object.entries(CheerType).map(([key, value]) => ({
      value: key,
      text: value.text,
    }))
    return empty.concat(base)
  }

  const goFanDetail = (fanId: number) => {
    navigate('/fans/:fanId', {
      params: {
        fanId: String(fanId),
      },
    })
  }

  const goEventDetail = (eventId: number, type: EventTypeType) => {
    navigate(`/events/${EventType[type].page}/:eventId`, {
      params: {
        eventId: String(eventId),
      },
    })
  }

  return (
    <Box className='page'>
      <PageTitle>{pageTitle}</PageTitle>
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Grid container columnSpacing={2}>
          <Grid item>
            <FormText
              control={control}
              name='id'
              label='ID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='fanId'
              label='ファンID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='name'
              label='ファン名'
              fullWidth={false}
              hint={'部分一致'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='eventId'
              label='イベントID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='eventName'
              label='イベント名'
              fullWidth={false}
              hint={'部分一致'}
            />
          </Grid>
          <Grid item>
            <FormSelect
              control={control}
              name='type'
              label='CHEER種別'
              fullWidth={false}
              options={cheerTypes()}
            />
          </Grid>
          <Grid item>
            <ActionButton type='submit'>検索</ActionButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataTable
          columns={[
            { field: 'id', headerName: 'ID', width: 100 },
            {
              field: 'fanId',
              headerName: 'ファンID',
              width: 100,
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'fanName',
              headerName: 'ファン',
              width: 250,
              renderCell: (params) => (
                <Link
                  onClick={() => goFanDetail(params.row.fanId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'cheerType',
              headerName: '種別',
              width: 150,
            },
            {
              field: 'cheerCount',
              headerName: '受取CHEER数',
              width: 120,
            },
            {
              field: 'usedCheerCount',
              headerName: '消費CHEER数',
              width: 120,
            },
            {
              field: 'eventId',
              headerName: 'イベントId',
              width: 90,
              renderCell: (params) =>
                params.value ? (
                  <CopyClipboard text={params.value} isIcon={false} />
                ) : (
                  '-'
                ),
            },
            {
              field: 'eventName',
              headerName: '使える対象イベント',
              width: 300,
              renderCell: (params) =>
                params.value ? (
                  <Link
                    onClick={() =>
                      goEventDetail(params.row.eventId, params.row.eventType)
                    }
                    color='inherit'
                    underline='hover'
                  >
                    {params.value}
                  </Link>
                ) : (
                  '-'
                ),
            },
            {
              field: 'expiredAt',
              headerName: '有効期限',
              width: 180,
            },
            {
              field: 'createdAt',
              headerName: '受取日時',
              width: 180,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          editReferProp={'id'}
        ></DataTable>
      </Box>
    </Box>
  )
}

export default Page
