import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PostAppVersionSettingParams = {
  iosNextApplyDatetime: string | null
  androidNextApplyDatetime: string | null
  iosNextMinVersion: string | null
  androidNextMinVersion: string | null
}

export type PostAppVersionSettingResponse = APIResponse<null>

export const postAppVersionSettingPath = '/admin/v1/app-version-setting'

export const postAppVersionSetting = async (
  params: PostAppVersionSettingParams,
) => {
  return await http<PostAppVersionSettingResponse>(postAppVersionSettingPath, {
    method: 'POST',
    body: JSON.stringify({
      ios_next_apply_datetime: params.iosNextApplyDatetime,
      android_next_apply_datetime: params.androidNextApplyDatetime,
      ios_next_min_version: params.iosNextMinVersion,
      android_next_min_version: params.androidNextMinVersion,
    }),
  })
}
