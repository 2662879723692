import { useState, type FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Avatar, Box, Grid, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import {
  getDailyArtistReport,
  getDailyArtistReportPath,
} from '@/features/report/api/getDailyArtistReport'
import { getDailyArtistReportDownloadCsv } from '@/features/report/api/getDailyArtistReportDownloadCsv'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { downloadCsv, pickErrorMessages } from '@/utils/functions'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = '日次アーティストレポート'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)

  const page = Number(searchParams.get('page') || '1')
  const artistId = searchParams.get('artistId') || ''
  const artistName = searchParams.get('artistName') || ''
  const dateFromParam = searchParams.get('dateFrom')
  const dateFrom = dateFromParam ? newDate(dateFromParam) : null
  const dateToParam = searchParams.get('dateTo')
  const dateTo = dateToParam ? newDate(dateToParam) : null

  const { control, getValues } = useForm({
    defaultValues: {
      dateFrom: dateFrom,
      dateTo: dateTo,
      artistId: artistId,
      artistName: artistName,
    },
  })

  const { data, isValidating, mutate } = useSWR(getDailyArtistReportPath, () =>
    getDailyArtistReport({
      page: page,
      artistId: artistId || undefined,
      artistName: artistName || undefined,
      dateFrom: dateFrom
        ? formatDateTime(dateFrom, Format.dateRequest)
        : undefined,
      dateTo: dateTo ? formatDateTime(dateTo, Format.dateRequest) : undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'artistId', value: getValues('artistId') },
        { key: 'artistName', value: getValues('artistName') },
        {
          key: 'dateFrom',
          value: formatDateTime(getValues('dateFrom'), Format.dateRequest),
        },
        {
          key: 'dateTo',
          value: formatDateTime(getValues('dateTo'), Format.dateRequest),
        },
      ])
      return prev
    })
    mutate(
      getDailyArtistReport({
        page: page,
        artistId: getValues('artistId') || undefined,
        artistName: getValues('artistName') || undefined,
        dateFrom: dateFrom
          ? formatDateTime(getValues('dateFrom'), Format.dateRequest)
          : undefined,
        dateTo: dateTo
          ? formatDateTime(getValues('dateTo'), Format.dateRequest)
          : undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: 1,
    })
  }

  // CSVダウンロード
  const handleDownloadCsv = async () => {
    try {
      const res = await getDailyArtistReportDownloadCsv({
        artistId: artistId || undefined,
        artistName: artistName || undefined,
        dateFrom: dateFrom
          ? formatDateTime(dateFrom, Format.dateRequest)
          : undefined,
        dateTo: dateTo ? formatDateTime(dateTo, Format.dateRequest) : undefined,
      })

      const resBlob = await res.blob()
      const contentDisposition = res.headers.get('content-disposition')
      const fileName = contentDisposition
        ? contentDisposition.split('filename=')[1]
        : ''
      downloadCsv(resBlob, fileName)
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: `${item.date}-${item.artistId}`,
      date: item.date,
      artistId: item.artistId,
      artistName: item.artistName,
      fanId: item.fanId,
      artistIconUrl: item.artistIconUrl,
      postCount: item.postCount,
      liveCount: item.liveCount,
      liveDuration: item.liveDuration,
      cheerCount: item.cheerCount,
      pointCheerCount: item.pointCheerCount,
      freeCheerCount: item.freeCheerCount,
      limitedCheerCount: item.limitedCheerCount,
      cheerUu: item.cheerUu,
      pointCheerUu: item.pointCheerUu,
      freeCheerUu: item.freeCheerUu,
      limitedCheerUu: item.limitedCheerUu,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  const goFanDetail = (fanId = '') => {
    navigate('/fans/:fanId', {
      params: {
        fanId: fanId,
      },
    })
  }

  return (
    <Box className='page'>
      <PageTitle
        actions={[{ text: 'CSVダウンロード', onClick: handleDownloadCsv }]}
        isLoading={isLoading}
      >
        {pageTitle}
      </PageTitle>
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Grid container columnSpacing={3} mb={3}>
          <Grid item>
            <FormDate
              control={control}
              name='dateFrom'
              views={['year', 'month', 'day']}
              fullWidth={false}
              label='開始日'
            />
          </Grid>
          <Grid item>
            <FormDate
              control={control}
              name='dateTo'
              views={['year', 'month', 'day']}
              fullWidth={false}
              label='終了日'
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='artistId'
              label='アーティストID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='artistName'
              label='アーティスト名'
              fullWidth={false}
              hint='部分一致'
            />
          </Grid>
          <Grid item>
            <ActionButton type='submit'>検索</ActionButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataTable
          columns={[
            {
              field: 'date',
              headerName: '日付',
              width: 100,
            },
            {
              field: 'artistId',
              headerName: 'アーティストID',
              width: 120,
            },
            {
              field: 'fanId',
              headerName: 'ファンID',
              width: 100,
            },
            {
              field: 'artistIconUrl',
              headerName: '',
              width: 50,
              align: 'center',
              renderCell: (params) => {
                return (
                  <Avatar
                    src={params.value}
                    alt='アーティストアイコン'
                    sx={{ width: 40, height: 40 }}
                  />
                )
              },
            },
            {
              field: 'artistName',
              headerName: 'アーティスト名',
              width: 230,
              renderCell: (params) => (
                <Link
                  onClick={() => goFanDetail(params.row.fanId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'postCount',
              headerName: '投稿数',
              width: 70,
            },
            {
              field: 'liveCount',
              headerName: 'ライブ配信数',
              width: 110,
            },
            {
              field: 'liveDuration',
              headerName: '配信時間',
              width: 80,
            },
            {
              field: 'freeCheerUu',
              headerName: 'ボーナスCHEER UU数',
              width: 230,
              headerAlign: 'right',
            },
            {
              field: 'pointCheerUu',
              headerName: 'ポイントCHEER UU数',
              width: 160,
            },
            {
              field: 'limitedCheerUu',
              headerName: 'イベント限定CHEER UU数',
              width: 190,
            },
            {
              field: 'cheerUu',
              headerName: 'CHEER UU総数',
              width: 120,
              renderCell: (params) => {
                return (
                  <Box
                    px={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right',
                      color: 'secondary.main',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {params.value}
                  </Box>
                )
              },
            },
            {
              field: 'freeCheerCount',
              headerName: 'ボーナスCHEER数',
              width: 200,
              headerAlign: 'right',
            },
            {
              field: 'pointCheerCount',
              headerName: 'ポイントCHEER数',
              width: 140,
            },
            {
              field: 'limitedCheerCount',
              headerName: 'イベント限定CHEER数',
              width: 170,
            },
            {
              field: 'cheerCount',
              headerName: '獲得CHEER総数',
              width: 130,
              renderCell: (params) => {
                return (
                  <Box
                    px={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right',
                      color: 'secondary.main',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {params.value}
                  </Box>
                )
              },
            },
            {
              field: 'createdAt',
              headerName: '作成日時',
              width: 200,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
        ></DataTable>
      </Box>
    </Box>
  )
}

export default Page
