import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import {
  getMonthlyServiceKpiReport,
  getMonthlyServiceKpiReportPath,
} from '@/features/report/api/getMonthlyServiceKpiReport'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = '月次KPIレポート'
  usePageInfo({
    title: pageTitle,
  })

  const [searchParams, setSearchParams] = useSearchParams()

  const page = Number(searchParams.get('page') || '1')
  const dateParam = searchParams.get('date')
  const date = dateParam ? newDate(dateParam) : null

  const { control, getValues } = useForm({
    defaultValues: {
      date: date,
    },
  })

  const { data, isValidating, mutate } = useSWR(
    getMonthlyServiceKpiReportPath,
    () =>
      getMonthlyServiceKpiReport({
        page: page,
        year: date ? date.getFullYear() : undefined,
        month: date ? date.getMonth() + 1 : undefined,
      }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        {
          key: 'date',
          value: formatDateTime(getValues('date'), Format.dateRequest),
        },
      ])
      return prev
    })
    mutate(
      getMonthlyServiceKpiReport({
        page: page,
        year: date ? date.getFullYear() : undefined,
        month: date ? date.getMonth() + 1 : undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: 1,
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: `${item.year}-${item.month}`,
      year: String(item.year),
      month: item.month,
      artistActiveCount: item.artistActiveCount,
      artistNewCount: item.artistNewCount,
      activityUU: item.activityUU,
      postUU: item.postUU,
      liveUU: item.liveUU,
      totalPostCount: item.totalPostCount,
      avgPostCount: item.avgPostCount,
      totalLiveDuration: item.totalLiveDuration,
      avgLiveDuration: item.avgLiveDuration,
      fanActiveCount: item.fanActiveCount,
      fanNewCount: item.fanNewCount,
      cheerCount: item.cheerCount,
      pointCheerCount: item.pointCheerCount,
      freeCheerCount: item.freeCheerCount,
      limitedCheerCount: item.limitedCheerCount,
      cheerUU: item.cheerUU,
      pointCheerUU: item.pointCheerUU,
      freeCheerUU: item.freeCheerUU,
      limitedCheerUU: item.limitedCheerUU,
      totalRevenue: item.totalRevenue,
      revenueIos: item.revenueIos,
      revenueAndroid: item.revenueAndroid,
      revenueWeb: item.revenueWeb,
      payingUU: item.payingUU,
      arppu: item.arppu,
      pointUnitPrice: item.pointUnitPrice,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  return (
    <Box className='page'>
      <PageTitle>{pageTitle}</PageTitle>
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Grid container columnSpacing={3} mb={3} alignItems={'center'}>
          <Grid item>
            <FormDate
              control={control}
              name='date'
              views={['year', 'month']}
              fullWidth={false}
              label='対象月'
            />
          </Grid>
          <Grid item>
            <ActionButton type='submit'>検索</ActionButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataTable
          columns={[
            {
              field: 'year',
              headerName: '年',
              width: 50,
            },
            {
              field: 'month',
              headerName: '月',
              width: 50,
              headerAlign: 'center',
              align: 'center',
            },
            {
              field: 'artistActiveCount',
              headerName: 'アーティストアクティブ数',
              width: 190,
              headerAlign: 'right',
            },
            {
              field: 'artistNewCount',
              headerName: 'アーティスト新規UU',
              width: 170,
              headerAlign: 'right',
            },
            {
              field: 'postUU',
              headerName: '投稿UU',
              width: 150,
              headerAlign: 'right',
            },
            {
              field: 'liveUU',
              headerName: 'ライブ配信UU',
              width: 120,
              headerAlign: 'right',
            },
            {
              field: 'activityUU',
              headerName: 'アーティスト活動UU',
              width: 150,
              headerAlign: 'right',
              renderCell: (params) => {
                return (
                  <Box
                    px={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right',
                      color: 'secondary.main',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {params.value}
                  </Box>
                )
              },
            },
            {
              field: 'totalPostCount',
              headerName: '投稿総数',
              width: 170,
              headerAlign: 'right',
            },
            {
              field: 'avgPostCount',
              headerName: '平均投稿数',
              width: 150,
              headerAlign: 'right',
            },
            {
              field: 'totalLiveDuration',
              headerName: '総配信時間',
              width: 150,
              headerAlign: 'right',
              align: 'right',
            },
            {
              field: 'avgLiveDuration',
              headerName: '平均配信時間',
              width: 150,
              headerAlign: 'right',
              align: 'right',
            },
            {
              field: 'fanActiveCount',
              headerName: 'ファンアクティブ数',
              width: 200,
              headerAlign: 'right',
            },
            {
              field: 'fanNewCount',
              headerName: '新規ファンUU',
              width: 120,
              headerAlign: 'right',
            },
            {
              field: 'pointCheerCount',
              headerName: 'ポイントCHEER数',
              width: 210,
              headerAlign: 'right',
            },
            {
              field: 'freeCheerCount',
              headerName: 'ボーナスCHEER数',
              width: 150,
              headerAlign: 'right',
            },
            {
              field: 'limitedCheerCount',
              headerName: 'イベント限定CHEER数',
              width: 170,
              headerAlign: 'right',
            },
            {
              field: 'cheerCount',
              headerName: 'CHEER総数',
              width: 150,
              headerAlign: 'right',
              renderCell: (params) => {
                return (
                  <Box
                    px={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right',
                      color: 'secondary.main',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {params.value}
                  </Box>
                )
              },
            },
            {
              field: 'pointCheerUU',
              headerName: 'ポイントCHEER UU',
              width: 200,
              headerAlign: 'right',
            },
            {
              field: 'freeCheerUU',
              headerName: 'ボーナスCHEER UU',
              width: 150,
              headerAlign: 'right',
            },
            {
              field: 'limitedCheerUU',
              headerName: 'イベント限定CHEER UU',
              width: 180,
              headerAlign: 'right',
            },
            {
              field: 'cheerUU',
              headerName: 'CHEER UU',
              width: 150,
              headerAlign: 'right',
              renderCell: (params) => {
                return (
                  <Box
                    px={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right',
                      color: 'secondary.main',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {params.value}
                  </Box>
                )
              },
            },
            {
              field: 'revenueIos',
              headerName: 'iOS課金額',
              width: 200,
              headerAlign: 'right',
            },
            {
              field: 'revenueAndroid',
              headerName: 'Android課金額',
              width: 150,
              headerAlign: 'right',
            },
            {
              field: 'revenueWeb',
              headerName: 'WEB課金額',
              width: 150,
              headerAlign: 'right',
            },
            {
              field: 'totalRevenue',
              headerName: '課金総額',
              width: 150,
              headerAlign: 'right',
              renderCell: (params) => {
                return (
                  <Box
                    px={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right',
                      color: 'secondary.main',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {params.value}
                  </Box>
                )
              },
            },
            {
              field: 'payingUU',
              headerName: '課金UU',
              width: 150,
              headerAlign: 'right',
            },
            {
              field: 'arppu',
              headerName: 'ARPPU',
              width: 100,
              headerAlign: 'right',
            },
            {
              field: 'pointUnitPrice',
              headerName: 'ポイント単価',
              width: 120,
              headerAlign: 'right',
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
        ></DataTable>
      </Box>
    </Box>
  )
}

export default Page
