import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { OpenInNew } from '@mui/icons-material'
import { Box, Chip, Grid, Link, Tooltip } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import {
  getStartupPopup,
  getStartupPopupPath,
} from '@/features/startup-popups/api/getStartupPopup'
import {
  DisplayStatus,
  StartupPopupTargetType,
  StartupPopupType,
} from '@/features/startup-popups/types'
import { formatDateTime, newDate, Format } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'アプリ起動時ポップアップ一覧'
  usePageInfo({ title: pageTitle })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    id: '',
    type: '',
    displayStartDate: '',
    displayEndDate: '',
    targetType: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const id = searchParams.get('id') || ''
  const type = searchParams.get('type') || ''
  const displayStartDate = searchParams.get('displayStartDate') || null
  const displayEndDate = searchParams.get('displayEndDate') || null
  const targetType = searchParams.get('targetType') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      id,
      type,
      displayStartDate,
      displayEndDate,
      targetType,
    },
  })

  const { data, isValidating, mutate } = useSWR(getStartupPopupPath, () =>
    getStartupPopup({
      page: page,
      id: id,
      type: type,
      displayStartDate: displayStartDate
        ? formatDateTime(displayStartDate, Format.dateRequest)
        : undefined,
      displayEndDate: displayEndDate
        ? formatDateTime(displayEndDate, Format.dateRequest)
        : undefined,
      targetType: targetType,
    }),
  )

  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'id', value: getValues('id') },
        { key: 'type', value: getValues('type') },
        {
          key: 'displayStartDate',
          value: formatDateTime(
            getValues('displayStartDate'),
            Format.dateRequest,
          ),
        },
        {
          key: 'displayEndDate',
          value: formatDateTime(
            getValues('displayEndDate'),
            Format.dateRequest,
          ),
        },
        { key: 'targetType', value: getValues('targetType') },
      ])
      return prev
    })
    mutate(
      getStartupPopup({
        page: page,
        id: getValues('id') || undefined,
        type: getValues('type') || undefined,
        displayStartDate: getValues('displayStartDate')
          ? formatDateTime(getValues('displayStartDate'), Format.dateRequest)
          : undefined,
        displayEndDate: getValues('displayEndDate')
          ? formatDateTime(getValues('displayEndDate'), Format.dateRequest)
          : undefined,
        targetType: getValues('targetType') || undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (page: number) => {
    search({ page })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({ page: Number(page) })
  }

  const goDetail = (id = '') => {
    navigate('/startup-popups/:popupId', {
      params: {
        popupId: id,
      },
    })
  }

  const goCreate = () => {
    navigate('/startup-popups/create')
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      type: StartupPopupType[item.type].text,
      displayStartAt: item.displayStartAt,
      displayEndAt: item.displayEndAt,
      displayPeriodColor:
        newDate(item.displayStartAt) < newDate() &&
        newDate() <= newDate(item.displayEndAt)
          ? 'success.main'
          : 'default',
      eventId: item.eventId,
      eventName: item.eventName,
      destinationUrl: item.destinationUrl,
      targetType: StartupPopupTargetType[item.targetType].text,
      displayStatus: {
        text: DisplayStatus[item.isDisplayed].text,
        color: DisplayStatus[item.isDisplayed].color,
      },
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '新規作成', onClick: goCreate }]}>
        {pageTitle}
      </PageTitle>
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Grid container columnSpacing={3} mb={3}>
          <Grid item>
            <FormSelect
              control={control}
              name='type'
              label='種別'
              fullWidth={false}
              options={[{ value: '', text: '選択してください' }].concat(
                Object.entries(StartupPopupType).map(([, item]) => ({
                  value: item.value,
                  text: item.text,
                })),
              )}
            />
          </Grid>
          <Grid item>
            <FormDate
              control={control}
              name='displayStartDate'
              label='表示開始日'
              views={['year', 'month', 'day']}
              fullWidth={false}
            />
          </Grid>
          <Grid item>
            <FormDate
              control={control}
              name='displayEndDate'
              label='表示終了日'
              views={['year', 'month', 'day']}
              fullWidth={false}
            />
          </Grid>
          <Grid item>
            <FormSelect
              control={control}
              name='targetType'
              label='表示対象'
              fullWidth={false}
              options={[{ value: '', text: '選択してください' }].concat(
                Object.entries(StartupPopupTargetType).map(([, item]) => ({
                  value: item.value,
                  text: item.text,
                })),
              )}
            />
          </Grid>
          <Grid item>
            <ActionButton type='submit'>検索</ActionButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataGrid
          columns={[
            {
              field: 'id',
              headerName: 'ID',
              width: 70,
              headerAlign: 'right',
            },
            {
              field: 'type',
              headerName: '種別',
              width: 200,
            },
            {
              field: 'displayStartAt',
              headerName: '表示開始日時',
              width: 200,
              renderCell: (params) => (
                <Box color={params.row.displayPeriodColor}>{params.value}</Box>
              ),
            },
            {
              field: 'displayEndAt',
              headerName: '表示終了日時',
              width: 200,
              renderCell: (params) => (
                <Box color={params.row.displayPeriodColor}>{params.value}</Box>
              ),
            },
            {
              field: 'targetType',
              headerName: '表示対象',
              width: 200,
            },
            {
              field: 'eventId',
              headerName: 'イベントID',
              width: 100,
              headerAlign: 'right',
            },
            {
              field: 'eventName',
              headerName: 'イベント名',
              width: 300,
            },
            {
              field: 'destinationUrl',
              headerName: '遷移先URL',
              width: 200,
              renderCell: (params) =>
                params.value && (
                  <Tooltip title={params.value}>
                    <Link
                      href={params.value}
                      target='_blank'
                      rel='noopener'
                      style={{ display: 'inline-flex', alignItems: 'center' }}
                    >
                      <OpenInNew fontSize='small' style={{ marginRight: 4 }} />
                      遷移先を開く
                    </Link>
                  </Tooltip>
                ),
            },
            {
              field: 'displayStatus',
              headerName: '表示/非表示',
              width: 150,
              renderCell: (params) => {
                return (
                  <Chip
                    size='small'
                    color={params.value.color}
                    variant='outlined'
                    label={params.value.text}
                  />
                )
              },
            },
            {
              field: 'createdAt',
              headerName: '作成日時',
              width: 200,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 200,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
          rowHeight={90}
        />
      </Box>
    </Box>
  )
}

export default Page
