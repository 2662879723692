import { forwardRef, useImperativeHandle } from 'react'

import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormFile from '@cz_frontend/ui/components/forms/FormFile/FormFile'
import FormRadio from '@cz_frontend/ui/components/forms/FormRadio/FormRadio'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { Box, Collapse, FormLabel, Grid, Typography } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'

import {
  BannerDestination,
  BannerDisplayTarget,
  BannerTarget,
  DisplayStatus,
} from '../types'

import type { BannerFormValues } from '../types'

import { INPUT_BASE_HEIGHT } from '@/theme'

interface BannerFormProps {}
export interface BannerFormRef {
  getFormValues(): BannerFormValues
  setFormValue(key: keyof BannerFormValues, value: string | number): void
  formReset(values: BannerFormValues): void
}

const BannerForm = forwardRef<BannerFormRef, BannerFormProps>((_, ref) => {
  const { control, getValues, setValue, reset } = useForm<BannerFormValues>({
    defaultValues: {
      bannerUrl: '',
      displayStartAt: null,
      displayEndAt: null,
      priority: 1,
      target: BannerTarget.ALL.value,
      displayTarget: BannerDisplayTarget.FAN_ALL.value,
      destination: BannerDestination.WEB.value,
      url: '',
      eventId: '',
      artistFanId: '',
      isDisplayed: DisplayStatus.ON.value,
    },
  })

  // 親から呼べる関数を公開
  useImperativeHandle(ref, () => ({
    getFormValues: getValues,
    setFormValue: (key, value) => {
      setValue(key, value)
    },
    formReset: (values) => {
      reset(values)
    },
  }))

  const watchDestination = useWatch<BannerFormValues>({
    control,
    name: 'destination',
  })

  return (
    <FormBox onSubmit={() => false}>
      <Grid item style={{ width: '100%' }}>
        <Box style={{ width: '50%' }}>
          <FormFile
            control={control}
            name='bannerFile'
            label='バナー画像(800x270)'
            showImagePreview
            initialImageSrc={getValues('bannerUrl')}
            required
          />
        </Box>
        <FormLabel>表示期間</FormLabel>
        <Box display='flex' alignItems={'center'} gap={'1em'}>
          <FormDate
            control={control}
            name='displayStartAt'
            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
            fullWidth={false}
          />
          <Typography height={INPUT_BASE_HEIGHT}>〜</Typography>
          <FormDate
            control={control}
            name='displayEndAt'
            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
            fullWidth={false}
          />
        </Box>
        <Box>
          <FormText
            control={control}
            name='priority'
            label='優先度'
            type='number'
            required
            hint='数値が小さいほど優先度が高くなります'
          />
        </Box>
        <Box>
          <FormRadio
            label='表示デバイス'
            control={control}
            name='target'
            required
            fullWidth={false}
            options={Object.entries(BannerTarget).map(([, item]) => ({
              value: item.value,
              text: item.text,
            }))}
          />
        </Box>
        <Box>
          <FormRadio
            label='表示対象'
            control={control}
            name='displayTarget'
            required
            fullWidth={false}
            options={Object.entries(BannerDisplayTarget).map(([, item]) => ({
              value: item.value,
              text: item.text,
            }))}
          />
        </Box>
        <Box>
          <FormRadio
            label='遷移先'
            control={control}
            name='destination'
            required
            fullWidth={false}
            options={Object.entries(BannerDestination).map(([, item]) => ({
              value: item.value,
              text: item.text,
            }))}
          />
        </Box>
        <Collapse in={watchDestination === BannerDestination.WEB.value}>
          <Box>
            <FormText control={control} name='url' label='URL' />
          </Box>
        </Collapse>
        <Collapse
          in={watchDestination === BannerDestination.EVENT_DETAIL.value}
        >
          <Box>
            <FormText
              control={control}
              name='eventId'
              label='イベントID'
              type='number'
            />
          </Box>
        </Collapse>
        <Collapse
          in={watchDestination === BannerDestination.ARTIST_PROFILE.value}
        >
          <Box>
            <FormText
              control={control}
              name='artistFanId'
              label='アーティストのファンID'
              type='number'
            />
          </Box>
        </Collapse>
        <Box>
          <FormRadio
            label='表示'
            control={control}
            name='isDisplayed'
            options={[
              { value: 'ON', text: '表示' },
              { value: 'OFF', text: '非表示' },
            ]}
          />
        </Box>
      </Grid>
    </FormBox>
  )
})

export default BannerForm
