import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'

import type { StartupPopupFormRef } from '@/features/startup-popups/components/StartupPopupForm'

import { postStartupPopup } from '@/features/startup-popups/api/postStartupPopup'
import StartupPopupForm from '@/features/startup-popups/components/StartupPopupForm'
import { transformFormValues } from '@/features/startup-popups/utils'
import { pickErrorMessages, postUploadMedia } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const pageTitle = 'アプリ起動時ポップアップ作成'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({ title: pageTitle })

  const StartupPopupFormRef = useRef<StartupPopupFormRef>(null)

  const goList = () => {
    navigate('/startup-popups')
  }

  const handleRegister = async () => {
    try {
      setIsLoading(true)
      const formValues = StartupPopupFormRef.current!.getFormValues()
      const { result } = await postStartupPopup({
        ...transformFormValues(formValues),
      })

      if (formValues.imageFile) {
        const res = await postUploadMedia({
          url: result.imageUploadUrl,
          file: formValues.imageFile,
        })
        if (!res) {
          await swal.messages({
            messages: ['サムネイル画像のアップロードに失敗しました'],
          })
          return
        }
      }

      swal.toastSuccess()
      goList()
    } catch (err) {
      await swal.messages({ messages: pickErrorMessages(err) })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <StartupPopupForm ref={StartupPopupFormRef} />
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={handleRegister}
      >
        登録する
      </ActionButton>
    </Box>
  )
}

export default Page
