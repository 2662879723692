import {
  StartupPopupTargetType,
  StartupPopupType,
  type StartupPopupFormValues,
} from '../types'

import { Format, formatDateTime } from '@/lib/dateFns'

export const transformFormValues = (formValues: StartupPopupFormValues) => {
  const type = formValues.type

  let displayStartAt = null
  let displayEndAt = null
  let eventId = null
  let imageFileName = null
  let destinationUrl = null
  let pickupArtistFanIds = null
  if (type === StartupPopupType.ARTIST_PICKUP.value) {
    displayStartAt =
      formValues.displayStartAt !== null
        ? formatDateTime(formValues.displayStartAt, Format.dateTimeRequest)
        : null
    displayEndAt =
      formValues.displayEndAt !== null
        ? formatDateTime(formValues.displayEndAt, Format.dateTimeRequest)
        : null
    pickupArtistFanIds =
      formValues.pickupArtistFanIds !== ''
        ? formValues.pickupArtistFanIds
        : null
  } else if (type === StartupPopupType.FAN_DAILY_MISSION.value) {
    displayStartAt =
      formValues.displayStartAt !== null
        ? formatDateTime(formValues.displayStartAt, Format.dateTimeRequest)
        : null
    displayEndAt =
      formValues.displayEndAt !== null
        ? formatDateTime(formValues.displayEndAt, Format.dateTimeRequest)
        : null
  } else if (
    type === StartupPopupType.EVENT_RECRUIT.value ||
    type === StartupPopupType.EVENT_ACTIVE.value
  ) {
    eventId = formValues.eventId !== '' ? Number(formValues.eventId) : null
  } else if (type === StartupPopupType.WEB.value) {
    displayStartAt =
      formValues.displayStartAt !== null
        ? formatDateTime(formValues.displayStartAt, Format.dateTimeRequest)
        : null
    displayEndAt =
      formValues.displayEndAt !== null
        ? formatDateTime(formValues.displayEndAt, Format.dateTimeRequest)
        : null
    imageFileName = formValues.imageFile ? formValues.imageFile.name : null
    destinationUrl = formValues.destinationUrl
  }

  const targetType = formValues.targetType
  let targetFanIds = null
  let targetFanGroupId = null
  if (targetType === StartupPopupTargetType.FAN_ID.value) {
    targetFanIds =
      formValues.targetFanIds !== '' ? formValues.targetFanIds : null
  } else if (targetType === StartupPopupTargetType.FAN_GROUP.value) {
    targetFanGroupId =
      formValues.targetFanGroupId !== ''
        ? Number(formValues.targetFanGroupId)
        : null
  }

  const description =
    formValues.description !== '' ? formValues.description : null
  const isDisplayed = formValues.isDisplayed

  return {
    type,
    displayStartAt,
    displayEndAt,
    pickupArtistFanIds,
    eventId,
    imageFileName,
    destinationUrl,
    targetType,
    targetFanIds,
    targetFanGroupId,
    description,
    isDisplayed,
  }
}
