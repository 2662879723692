import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PostBannerParams = {
  bannerFileName: string | null
  displayStartAt: string | null
  displayEndAt: string | null
  priority: number
  target: string
  displayTarget: string
  destination: string
  url: string | null
  eventId: number | null
  artistFanId: number | null
  isDisplayed: string
}

export type PostBannerResponse = APIResponse<{
  id: number
  bannerUploadUrl: string
}>

export const postBannerPath = '/admin/v1/banner'

export const postBanner = async (params: PostBannerParams) => {
  return await http<PostBannerResponse>(postBannerPath, {
    method: 'POST',
    body: JSON.stringify({
      banner_file_name: params.bannerFileName,
      display_start_at: params.displayStartAt,
      display_end_at: params.displayEndAt,
      priority: params.priority,
      target: params.target,
      display_target: params.displayTarget,
      destination: params.destination,
      url: params.url,
      event_id: params.eventId,
      artist_fan_id: params.artistFanId,
      is_displayed: params.isDisplayed,
    }),
  })
}
