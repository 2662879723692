import { useState, type FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { getMonthlyPointReport, getMonthlyPointReportPath } from '@/features/report/api/getMonthlyPointReport'
import { getMonthlyPointReportDownloadCsv } from '@/features/report/api/getMonthlyPointReportDownloadCsv'
import { newDate } from '@/lib/dateFns'
import { downloadCsv, pickErrorMessages } from '@/utils/functions'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = '月別未消化ポイント'
  usePageInfo({
    title: pageTitle,
  })

  const [searchParams, setSearchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)

  const page = Number(searchParams.get('page') || '1')
  const yearParam = searchParams.get('year')
  const year = yearParam ? newDate(yearParam) : null
  const month = searchParams.get('month') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      year: year,
      month: month,
    },
  })

  const { data, isValidating, mutate } = useSWR(getMonthlyPointReportPath, () =>
    getMonthlyPointReport({
      page: page,
      year: year ? year.getFullYear() : undefined,
      month: month ? Number(month) : undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    const yearValue = getValues('year')
    const year = yearValue ? yearValue.getFullYear().toString() : ''
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'year', value: year },
        { key: 'month', value: String(getValues('month')) },
      ])
      return prev
    })
    mutate(
      getMonthlyPointReport({
        page: page,
        year: year ? Number(year) : undefined,
        month: getValues('month') ? Number(getValues('month')) : undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: 1,
    })
  }

  // CSVダウンロード
  const handleDownloadCsv = async () => {
    try {
      const res = await getMonthlyPointReportDownloadCsv({
        year: year ? year.getFullYear() : undefined,
        month: getValues('month') ? Number(getValues('month')) : undefined,
      })

      const resBlob = await res.blob()
      const contentDisposition = res.headers.get('content-disposition')
      const fileName = contentDisposition ? contentDisposition.split('filename=')[1] : ''
      downloadCsv(resBlob, fileName)
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      yearMonth: `${item.year}/${item.month}月末`,
      issuedCreditcard: item.issued.creditcard,
      issuedIos: item.issued.ios,
      issuedAndroid: item.issued.android,
      issuedCoin: item.issued.coin,
      issuedTotal: item.issued.total,
      usedCreditcard: item.used.creditcard,
      usedIos: item.used.ios,
      usedAndroid: item.used.android,
      usedCoin: item.used.coin,
      usedTotal: item.used.total,
      depositCreditcard: item.deposit.creditcard,
      depositIos: item.deposit.ios,
      depositAndroid: item.deposit.android,
      depositCoin: item.deposit.coin,
      depositTotal: item.deposit.total,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  const monthOptions = [
    { value: '', text: '選択して下さい' },
    ...Array.from({ length: 12 }, (_, i) => {
      const num = i + 1
      return { value: num.toString(), text: num.toString() }
    }),
  ]

  return (
    <Box className='page'>
      <PageTitle
        actions={[{ text: 'CSVダウンロード', onClick: handleDownloadCsv }]}
        isLoading={isLoading}
      >
        {pageTitle}
      </PageTitle>
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Grid container columnSpacing={3}>
          <Grid item>
            <FormDate
              control={control}
              name='year'
              label='年'
              views={['year']}
              fullWidth={false}
            />
          </Grid>
          <Grid item>
            <FormSelect
              control={control}
              name='month'
              label='月'
              fullWidth={false}
              options={monthOptions}
            />
          </Grid>
          <Grid item>
            <ActionButton type='submit'>検索</ActionButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataTable
          columns={[
            {
              field: 'yearMonth',
              headerName: '日付',
              width: 100,
              headerAlign: 'center',
              align: 'center',
            },
            {
              field: 'issuedCreditcard',
              headerName: 'クレジットカード(発行)',
              width: 200,
              headerAlign: 'right',
            },
            {
              field: 'issuedIos',
              headerName: 'iOS(発行)',
              width: 120,
              headerAlign: 'right',
            },
            {
              field: 'issuedAndroid',
              headerName: 'Android(発行)',
              width: 120,
              headerAlign: 'right',
            },
            {
              field: 'issuedCoin',
              headerName: 'コイン交換',
              width: 120,
              headerAlign: 'right',
            },
            {
              field: 'issuedTotal',
              headerName: '合計(発行)',
              width: 120,
              headerAlign: 'right',
              renderCell: (params) => {
                return (
                  <Box
                    px={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right',
                      color: 'secondary.main',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {params.value}
                  </Box>
                )
              },
            },
            {
              field: 'usedCreditcard',
              headerName: 'クレジットカード(利用)',
              width: 200,
              headerAlign: 'right',
            },
            {
              field: 'usedIos',
              headerName: 'iOS(利用)',
              width: 120,
              headerAlign: 'right',
            },
            {
              field: 'usedAndroid',
              headerName: 'Android(利用)',
              width: 120,
              headerAlign: 'right',
            },
            {
              field: 'usedCoin',
              headerName: 'コイン(利用)',
              width: 120,
              headerAlign: 'right',
            },
            {
              field: 'usedTotal',
              headerName: '合計(利用)',
              width: 120,
              headerAlign: 'right',
              renderCell: (params) => {
                return (
                  <Box
                    px={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right',
                      color: 'secondary.main',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {params.value}
                  </Box>
                )
              },
            },
            {
              field: 'depositCreditcard',
              headerName: 'クレジットカード(残)',
              width: 200,
              headerAlign: 'right',
            },
            {
              field: 'depositIos',
              headerName: 'iOS(残)',
              width: 120,
              headerAlign: 'right',
            },
            {
              field: 'depositAndroid',
              headerName: 'Android(残)',
              width: 120,
              headerAlign: 'right',
            },
            {
              field: 'depositCoin',
              headerName: 'コイン(残)',
              width: 120,
              headerAlign: 'right',
            },
            {
              field: 'depositTotal',
              headerName: '合計(残)',
              width: 120,
              headerAlign: 'right',
              renderCell: (params) => {
                return (
                  <Box
                    px={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right',
                      color: 'secondary.main',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {params.value}
                  </Box>
                )
              },
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
        ></DataTable>
      </Box>
    </Box>
  )
}

export default Page
