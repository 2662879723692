import { useEffect, useState } from 'react'

import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import FormSectionContainer from '@cz_frontend/ui/components/forms/FormSectionContainer/FormSectionContainer'
import FormValue from '@cz_frontend/ui/components/forms/FormValue/FormValue'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Alert, Box } from '@mui/material'

import { getSalesReportDetail } from '@/features/report/api/getSalesReportDetail'
import { useNavigate, useParams } from '@/router'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/reports/sales-report/:reportId')
  const pageTitle = '売上詳細'
  const type = 'CHEER売上'

  usePageInfo({
    title: pageTitle,
  })

  const [reportData, setReportData] = useState<{
    id: number
    year: number
    month: number
    organizationId: number | null
    organizationName: string | null
    addRate: number | null
    agencyId: number | null
    agencyName: string | null
    totalSales: number
    paymentStatus: string
    errorReason: string | null
    details: {
      id: number
      fanId: number
      iconUrl: string
      artistId: number
      artistName: string
      salesAmount: number
    }[]
    createdAt: string
    updatedAt: string
    list: { id: number; artistName: string; salesAmount: number }[]
  }>({
    id: 0,
    year: 0,
    month: 0,
    organizationId: null,
    organizationName: '',
    addRate: null,
    agencyId: null,
    agencyName: '',
    totalSales: 0,
    paymentStatus: '',
    errorReason: '',
    details: [],
    createdAt: '',
    updatedAt: '',
    list: [],
  })

  useEffect(() => {
    const fetchData = async () => {
      const { result } = await getSalesReportDetail({
        id: Number(params.reportId),
      })

      const list = result.details.map((item) => {
        return {
          id: item.id,
          artistName: item.artistName,
          salesAmount: item.salesAmount,
        }
      })

      setReportData({
        id: result.id,
        year: result.year,
        month: result.month,
        organizationId: result.organizationId,
        organizationName: result.organizationName,
        addRate: result.addRate,
        agencyId: result.agencyId,
        agencyName: result.agencyName,
        totalSales: result.totalSales,
        paymentStatus: result.paymentStatus,
        errorReason: result.errorReason,
        details: result.details,
        createdAt: result.createdAt,
        updatedAt: result.updatedAt,
        list: list,
      })
    }

    fetchData()
  }, [params])

  // 遷移
  const goList = () => {
    navigate('/reports/sales-report')
  }

  return (
    <Box className='page' mb={5}>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <Box pb={2}>
        {reportData.errorReason && (
          <Alert severity='error'>{reportData.errorReason}</Alert>
        )}
      </Box>
      <Box pb={2}>
        <FormValue
          label={`◼️ ${reportData.year}年${reportData.month}月売上合計`}
        >
          {reportData.totalSales.toLocaleString()}円
        </FormValue>
      </Box>

      <Box pb={2}>
        {reportData.organizationId && (
          <FormValue label={'◼️ 事務所'}>
            {reportData.organizationName}(事務所ID:{reportData.organizationId})
          </FormValue>
        )}
      </Box>

      <Box pb={2}>
        {reportData.organizationId && (
          <FormValue label={'◼️ 事務所の上乗せ率'}>
            {reportData.addRate}%
          </FormValue>
        )}
      </Box>

      <Box pb={2}>
        {reportData.agencyId && (
          <FormValue label={'◼️ 代理店'}>
            {reportData.agencyName}(代理店ID:{reportData.agencyId})
          </FormValue>
        )}
      </Box>

      <FormSectionContainer sectionTitle='◼️ アーティスト別'>
        <DataTable
          columns={[
            { field: 'id', headerName: 'id', width: 50 },
            { field: 'artistName', headerName: 'アーティスト名', width: 200 },
            {
              field: 'type',
              headerName: 'CHEER',
              width: 200,
              renderCell: () => type,
            },
            {
              field: 'salesAmount',
              headerName: '売上',
              width: 200,
              renderCell: (params) => `¥${params.value}`,
            },
          ]}
          rows={reportData.list}
        />
      </FormSectionContainer>
    </Box>
  )
}

export default Page
