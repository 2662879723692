import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type DeleteStartupPopupParams = {
  id: number
}

export type DeleteStartupPopupResponse = APIResponse<null>

export const deleteStartupPopupPath = '/admin/v1/startup-popup/:id'

export const deleteStartupPopup = async (params: DeleteStartupPopupParams) => {
  return await http<DeleteStartupPopupResponse>(deleteStartupPopupPath, {
    method: 'DELETE',
    params: {
      id: params.id,
    },
  })
}
