import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type DeleteBannerParams = {
  id: number
}

export type DeleteBannerResponse = APIResponse<null>

export const deleteBannerPath = '/admin/v1/banner/:id'

export const deleteBanner = async (params: DeleteBannerParams) => {
  return await http<DeleteBannerResponse>(deleteBannerPath, {
    method: 'DELETE',
    params: {
      id: params.id,
    },
  })
}
