import { BannerDestination } from '../types'

import type { BannerFormValues } from '../types'

import { Format, formatDateTime } from '@/lib/dateFns'

export const transformFormValues = (formValues: BannerFormValues) => {
  const displayStartAt =
    formValues.displayStartAt !== null
      ? formatDateTime(formValues.displayStartAt, Format.dateTimeRequest)
      : null

  const displayEndAt =
    formValues.displayEndAt !== null
      ? formatDateTime(formValues.displayEndAt, Format.dateTimeRequest)
      : null

  const priority = Number(formValues.priority)

  const destination = formValues.destination
  let url = null
  let eventId = null
  let artistFanId = null
  if (destination === BannerDestination.WEB.value) {
    url = formValues.url !== '' ? formValues.url : null
  } else if (destination === BannerDestination.EVENT_DETAIL.value) {
    eventId = formValues.eventId !== '' ? Number(formValues.eventId) : null
  } else if (destination === BannerDestination.ARTIST_PROFILE.value) {
    artistFanId =
      formValues.artistFanId !== '' ? Number(formValues.artistFanId) : null
  }

  return {
    bannerFileName: formValues.bannerFile ? formValues.bannerFile.name : null,
    displayStartAt,
    displayEndAt,
    priority,
    target: formValues.target,
    displayTarget: formValues.displayTarget,
    destination,
    url,
    eventId,
    artistFanId,
    isDisplayed: formValues.isDisplayed,
  }
}
