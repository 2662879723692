import type {
  PublishStatusType,
  SendStatusType,
  TemplateStatusType,
} from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetMessageBoxTemplateParams = {
  page: number
  title?: string
  fanGroupId?: number
  artistId?: number
  sendFrom?: 'OFFICIAL' | 'ARTIST'
}

export type GetMessageBoxTemplateResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    fanIds: string
    fanGroup: {
      id: number
      name: string
    }
    artist: {
      id: number
      name: string
    }
    title: string
    publishStatus: PublishStatusType
    templateStatus: TemplateStatusType
    sendStatus: SendStatusType
    sentAt: string
    sentCount: number
    readCount: number
    reservedAt: string
    createdAt: string
    updatedAt: string
  }>
>

export const getMessageBoxTemplatePath = '/admin/v1/message-box-template'

export const getMessageBoxTemplate = async (
  params: GetMessageBoxTemplateParams,
) => {
  return await http<GetMessageBoxTemplateResponse>(getMessageBoxTemplatePath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      title: params.title,
      fan_group_id: params.fanGroupId,
      artist_id: params.artistId,
      send_from: params.sendFrom,
    },
  })
}
