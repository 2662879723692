import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetStartupPopupDetailParams = {
  id: number
}

export type GetStartupPopupDetailResponse = APIResponse<{
  id: number
  type: string
  displayStartAt: string
  displayEndAt: string
  pickupArtistFanIds: Array<number> | null
  eventId: number | null
  imageUrl: string
  destinationUrl: string
  targetType: string
  targetFanIds: Array<number> | null
  targetFanGroupId: number | null
  description: string
  isDisplayed: string
  createdAt: string
  updatedAt: string
}>

export const getStartupPopupDetailPath = '/admin/v1/startup-popup/:id'

export const getStartupPopupDetail = async (
  params: GetStartupPopupDetailParams,
) => {
  return await http<GetStartupPopupDetailResponse>(getStartupPopupDetailPath, {
    method: 'GET',
    params: {
      id: params.id,
    },
  })
}
