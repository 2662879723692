export const MessageStatus = {
  IN_PROGRESS: { value: 'IN_PROGRESS', text: '対応中', color: 'info' },
  COMPLETED: { value: 'COMPLETED', text: '完了', color: 'success' },
} as const
export type MessageStatusType = keyof typeof MessageStatus

export const MessageType = {
  ENABLE_REPLY_MESSAGE: { value: 'ENABLE_REPLY_MESSAGE', text: '返信可' },
  USER_INQUIRY_MESSAGE: {
    value: 'USER_INQUIRY_MESSAGE',
    text: 'ユーザー問い合わせ',
  },
  FINISH_INQUIRY_MESSAGE: {
    value: 'FINISH_INQUIRY_MESSAGE',
    text: '問い合わせ終了',
  },
} as const
export type MessageTypeType = keyof typeof MessageType

export const MediaType = {
  MOVIE: { value: 'MOVIE', text: '動画' },
  AUDIO: { value: 'AUDIO', text: '音声' },
  IMAGE: { value: 'IMAGE', text: '画像' },
} as const
export type MediaTypeType = keyof typeof MediaType

export const TemplateStatus = {
  PROCESSING: { value: 'PROCESSING', text: '処理中', color: 'info' },
  COMPLETE: { value: 'COMPLETE', text: '完了', color: 'success' },
  FAILED: { value: 'FAILED', text: '失敗', color: 'error' },
} as const
export type TemplateStatusType = keyof typeof TemplateStatus

export const SendStatus = {
  PENDING: { value: 'PENDING', text: '処理待ち', color: 'default' },
  PROCESSING: { value: 'PROCESSING', text: '処理中', color: 'info' },
  COMPLETE: { value: 'COMPLETE', text: '完了', color: 'success' },
  FAILED: { value: 'FAILED', text: '失敗', color: 'error' },
} as const
export type SendStatusType = keyof typeof SendStatus

export const PublishStatus = {
  DRAFT: { value: 'DRAFT', text: '下書き', color: 'default' },
  PUBLISH: { value: 'PUBLISH', text: '公開', color: 'success' },
} as const
export type PublishStatusType = keyof typeof PublishStatus

export const SendToType = {
  ALL: { value: 'ALL', text: '全員' },
  FANID: { value: 'FANID', text: 'ファンIDで指定' },
  FANID_GROUP: { value: 'FANID_GROUP', text: 'ファンIDグループで指定' },
  ACTIVE_ARTIST_ALL: { value: 'ACTIVE_ARTIST_ALL', text: 'アーティスト全員' },
  ACTIVE_FAN_ALL: {
    value: 'ACTIVE_FAN_ALL',
    text: 'ファン全員(アーティストを除く)',
  },
  EVENT_ID: { value: 'EVENT_ID', text: 'イベントIDで指定' },
} as const
export type SendToTypeType = keyof typeof SendToType

export type MessageEvent = {
  id: number
  name: string
}

export type InquiryFrom = {
  name: string
  iconUrl: string
}

export type InquiryFile = {
  type: MediaTypeType
  thumbnailUrl: string
  url: string
}

export const MessageReadStatus = {
  UNREAD: { text: '未読', color: 'error' },
  READ: { text: '既読', color: 'success' },
}
