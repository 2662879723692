import { httpRaw } from '@/lib/httpRaw'

export type GetDailyArtistReportDownloadCsvParams = {
  dateFrom?: string
  dateTo?: string
  artistId?: string
  artistName?: string
}

export const getDailyArtistReportDownloadCsvPath =
  '/admin/v1/daily-artist-report/download-csv'

export const getDailyArtistReportDownloadCsv = async (
  params: GetDailyArtistReportDownloadCsvParams,
) => {
  return await httpRaw(getDailyArtistReportDownloadCsvPath, {
    method: 'GET',
    searchParams: {
      date_from: params.dateFrom,
      date_to: params.dateTo,
      artist_id: params.artistId,
      artist_name: params.artistName,
    },
  })
}
