import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { getFaq, getFaqPath } from '@/features/faq/api/getFaq'
import {
  getFaqCategoryAllPath,
  getFaqCategoryAll,
} from '@/features/faq/api/getFaqCategoryAll'
import { FaqCategoryStatus, FaqCategoryTargetType } from '@/features/faq/types'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'FAQ一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    name: '',
    status: '',
    categoryTarget: '',
    faqCategoryId: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const title = searchParams.get('title') || ''
  const status = searchParams.get('status') || ''
  const categoryTarget = searchParams.get('categoryTarget') || ''
  const faqCategoryId = searchParams.get('faqCategoryId') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      title: title,
      status: status,
      categoryTarget: categoryTarget,
      faqCategoryId: faqCategoryId,
    },
  })

  const { data, isValidating, mutate } = useSWR(getFaqPath, () =>
    getFaq({
      page: page,
      title: title || undefined,
      status: status || undefined,
      categoryTarget: categoryTarget || undefined,
      faqCategoryId: faqCategoryId || undefined,
    }),
  )

  //FAQカテゴリー一覧
  const { data: faqCategoryList } = useSWR(getFaqCategoryAllPath, () =>
    getFaqCategoryAll({}),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'title', value: getValues('title') },
        { key: 'status', value: getValues('status') },
        { key: 'categoryTarget', value: getValues('categoryTarget') },
        { key: 'faqCategoryId', value: getValues('faqCategoryId') },
      ])
      return prev
    })
    mutate(
      getFaq({
        page: page,
        title: getValues('title') || undefined,
        status: getValues('status') || undefined,
        categoryTarget: getValues('categoryTarget') || undefined,
        faqCategoryId: getValues('faqCategoryId') || undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const goDetail = (id = '') => {
    navigate('/faqs/:faqId', {
      params: {
        faqId: id,
      },
    })
  }

  const goCreate = () => {
    navigate('/faqs/create')
  }

  const statusOptions = () => {
    const empty = [{ value: '', text: '選択してください' }]
    const base = Object.entries(FaqCategoryStatus).map(([key, value]) => ({
      value: key,
      text: value.text,
    }))
    return empty.concat(base)
  }

  const targetOptions = () => {
    const empty = [{ value: '', text: '選択してください' }]
    const base = Object.entries(FaqCategoryTargetType).map(([key, value]) => ({
      value: key,
      text: value.text,
    }))
    return empty.concat(base)
  }

  const categoryOptions = () => {
    const empty = [{ value: '', text: '選択してください' }]
    if (!faqCategoryList) return empty
    const base = faqCategoryList.result.map((item) => ({
      value: item.id,
      text: `[${FaqCategoryTargetType[item.target.key].text}] ${item.name}`,
    }))
    return empty.concat(base)
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      title: item.title,
      sort: item.sort,
      status: item.status.value,
      categoryName: item.categoryName,
      categoryTarget: item.categoryTarget.value,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '新規作成', onClick: goCreate }]}>
        {pageTitle}
      </PageTitle>
      <Box
        display={'flex'}
        gap={4}
        alignItems={'center'}
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Box display={'flex'} gap={2} alignItems={'flex-start'}>
          <FormText
            control={control}
            name='title'
            label='タイトル'
            fullWidth={false}
          />
          <FormSelect
            control={control}
            fullWidth={false}
            name='status'
            label='ステータス'
            options={statusOptions()}
          />
          <FormSelect
            control={control}
            fullWidth={false}
            name='faqCategoryId'
            label='カテゴリー'
            options={categoryOptions()}
          />
          <FormSelect
            control={control}
            fullWidth={false}
            name='categoryTarget'
            label='表示場所'
            options={targetOptions()}
          />
        </Box>

        <ActionButton type='submit'>検索</ActionButton>
      </Box>
      <Box>
        <DataTable
          columns={[
            { field: 'id', headerName: 'ID', width: 50 },
            {
              field: 'title',
              headerName: 'タイトル',
              width: 200,
            },
            {
              field: 'status',
              headerName: 'ステータス',
              width: 120,
            },
            {
              field: 'categoryName',
              headerName: 'カテゴリー',
              width: 250,
            },
            {
              field: 'categoryTarget',
              headerName: '表示場所',
              width: 200,
            },
            {
              field: 'sort',
              headerName: '並び順',
              width: 100,
            },
            {
              field: 'createdAt',
              headerName: '作成日時',
              width: 200,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 200,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
          editReferProp={'id'}
        ></DataTable>
      </Box>
    </Box>
  )
}

export default Page
