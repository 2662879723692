import { useState, type FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box, Grid, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import {
  getSalesReport,
  getSalesReportPath,
} from '@/features/report/api/getSalesReport'
import { getSalesReportDownloadCsv } from '@/features/report/api/getSalesReportDownloadCsv'
import { newDate } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { downloadCsv, pickErrorMessages } from '@/utils/functions'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = '売上レポート'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)

  const page = Number(searchParams.get('page') || '1')
  const yearParam = searchParams.get('year')
  const year = yearParam ? newDate(yearParam) : null
  const month = searchParams.get('month') || ''
  const organizationId = searchParams.get('organizationId') || ''
  const organizationName = searchParams.get('organizationName') || ''
  const agencyId = searchParams.get('agencyId') || ''
  const agencyName = searchParams.get('agencyName') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      year: year,
      month: month,
      organizationId: organizationId,
      organizationName: organizationName,
      agencyId: agencyId,
      agencyName: agencyName,
    },
  })

  const { data, isValidating, mutate } = useSWR(getSalesReportPath, () =>
    getSalesReport({
      page: page,
      year: year ? year.getFullYear() : undefined,
      month: month ? Number(month) : undefined,
      organizationId: organizationId,
      organizationName: organizationName,
      agencyId: agencyId,
      agencyName: agencyName,
    }),
  )
  // 遷移
  const goDetail = (reportId = '') => {
    navigate('/reports/sales-report/:reportId', {
      params: {
        reportId: reportId,
      },
    })
  }

  // 検索
  const search = ({ page = 1 }) => {
    const yearValue = getValues('year')
    const year = yearValue ? yearValue.getFullYear().toString() : ''
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'year', value: year },
        { key: 'month', value: String(getValues('month')) },
        { key: 'organizationId', value: getValues('organizationId') },
        {
          key: 'organizationName',
          value: String(getValues('organizationName')),
        },
        { key: 'agencyId', value: getValues('agencyId') },
        { key: 'agencyName', value: String(getValues('agencyName')) },
      ])
      return prev
    })
    mutate(
      getSalesReport({
        page: page,
        year: year ? Number(year) : undefined,
        month: getValues('month') ? Number(getValues('month')) : undefined,
        organizationId: getValues('organizationId') || undefined,
        organizationName: getValues('organizationName') || undefined,
        agencyId: getValues('agencyId') || undefined,
        agencyName: getValues('agencyName') || undefined,
      }),
    )
  }

  const monthOptions = [
    { value: '', text: '選択して下さい' },
    ...Array.from({ length: 12 }, (_, i) => {
      const num = i + 1
      return { value: num.toString(), text: num.toString() }
    }),
  ]

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: 1,
    })
  }

  // CSVダウンロード
  const handleDownloadCsv = async () => {
    try {
      const res = await getSalesReportDownloadCsv({
        year: year ? year.getFullYear() : undefined,
        month: getValues('month') ? Number(getValues('month')) : undefined,
        organizationId: getValues('organizationId') || undefined,
        organizationName: getValues('organizationName') || undefined,
        agencyId: getValues('agencyId') || undefined,
        agencyName: getValues('agencyName') || undefined,
      })

      const resBlob = await res.blob()
      const contentDisposition = res.headers.get('content-disposition')
      const fileName = contentDisposition ? contentDisposition.split('filename=')[1] : ''
      downloadCsv(resBlob, fileName)
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      year: item.year,
      month: item.month,
      organizationId: item.organizationId ?? '-',
      organizationName: item.organizationName,
      agencyId: item.agencyId ?? '-',
      agencyName: item.agencyName,
      totalSales: item.totalSales,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  const goAgencyDetail = (agencyId = '') => {
    navigate('/agencies/:agencyId', {
      params: {
        agencyId: agencyId,
      },
    })
  }

  const goOrganizationDetail = (organizationId = '') => {
    navigate('/organizations/:organizationId', {
      params: {
        organizationId: organizationId,
      },
    })
  }

  return (
    <Box className='page'>
      <PageTitle
        actions={[{ text: 'CSVダウンロード', onClick: handleDownloadCsv }]}
        isLoading={isLoading}
      >
        {pageTitle}
      </PageTitle>
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Grid container columnSpacing={3} mb={3}>
          <Grid item>
            <FormDate
              control={control}
              name='year'
              label='年'
              views={['year']}
              fullWidth={false}
            />
          </Grid>
          <Grid item>
            <FormSelect
              control={control}
              name='month'
              label='月'
              fullWidth={false}
              options={monthOptions}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='organizationId'
              label='事務所ID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='organizationName'
              label='事務所名'
              fullWidth={false}
              hint='部分一致'
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='agencyId'
              label='代理店ID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='agencyName'
              label='代理店名'
              fullWidth={false}
              hint='部分一致'
            />
          </Grid>
          <Grid item>
            <ActionButton type='submit'>検索</ActionButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataTable
          columns={[
            {
              field: 'id',
              headerName: 'ID',
              width: 50,
            },
            {
              field: 'date',
              headerName: '日付',
              width: 100,
              renderCell: (params) => {
                return `${params.row.year}/${params.row.month}`
              },
            },
            {
              field: 'organizationId',
              headerName: '事務所ID',
              width: 100,
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'organizationName',
              headerName: '事務所名',
              width: 150,
              renderCell: (params) =>
                params.value ? (
                  <Link
                    onClick={() => goOrganizationDetail(params.row.agencyId)}
                    color='inherit'
                    underline='hover'
                  >
                    {params.value}
                  </Link>
                ) : (
                  '-'
                ),
            },
            {
              field: 'agencyId',
              headerName: '代理店ID',
              width: 150,
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'agencyName',
              headerName: '代理店名',
              width: 200,
              renderCell: (params) =>
                params.value ? (
                  <Link
                    onClick={() => goAgencyDetail(params.row.agencyId)}
                    color='inherit'
                    underline='hover'
                  >
                    {params.value}
                  </Link>
                ) : (
                  '-'
                ),
            },
            {
              field: 'totalSales',
              headerName: '売上合計',
              width: 150,
              renderCell: (params) => {
                return `¥${params.value.toLocaleString()}`
              },
            },
            {
              field: 'createdAt',
              headerName: '作成日時',
              width: 150,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 150,
            },
            {
              field: 'detail',
              headerName: '詳細',
              width: 150,
              renderCell: (params) => {
                return (
                  <ActionButton onClick={() => goDetail(params.row.id)}>
                    詳細
                  </ActionButton>
                )
              },
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
        />
      </Box>
    </Box>
  )
}

export default Page
