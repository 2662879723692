import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Link, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import type { SortType } from '@/features/artists/types'

import {
  getFanRankHistoryPath,
  getFanRankHistory,
} from '@/features/artists/api/getFanRankHistory'
import { Sort } from '@/features/artists/types'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'


function Page() {
  const pageTitle = 'ファンランク履歴一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    fanId: '',
    artistId: '',
    startAt: '',
    endAt: '',
    rankSort: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const fanId = searchParams.get('fanId') || ''
  const artistId = searchParams.get('artistId') || ''
  const startAtParam = searchParams.get('startAt')
  const startAt = startAtParam ? newDate(startAtParam) : null
  const endAtParam = searchParams.get('endAt')
  const endAt = endAtParam ? newDate(endAtParam) : null
  const rankSort = searchParams.get('rankSort') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      fanId: fanId,
      artistId: artistId,
      startAt: startAt,
      endAt: endAt,
      rankSort: rankSort,
    },
  })

  const { data, isValidating, mutate } = useSWR(getFanRankHistoryPath, () =>
    getFanRankHistory({
      page: page,
      fanId: fanId || undefined,
      artistId: artistId || undefined,
      startAt: startAt
        ? formatDateTime(startAt, Format.dateRequest)
        : undefined,
      endAt: endAt ? formatDateTime(endAt, Format.dateRequest) : undefined,
      rankSort: rankSort as SortType | undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'fanId', value: getValues('fanId') },
        { key: 'artistId', value: getValues('artistId') },
        {
          key: 'startAt',
          value: formatDateTime(getValues('startAt'), Format.dateRequest),
        },
        {
          key: 'endAt',
          value: formatDateTime(getValues('endAt'), Format.dateRequest),
        },
        { key: 'rankSort', value: getValues('rankSort') },
      ])
      return prev
    })
    mutate(
      getFanRankHistory({
        page: page,
        fanId: getValues('fanId') || undefined,
        artistId: getValues('artistId') || undefined,
        startAt: getValues('startAt')
          ? formatDateTime(getValues('startAt'), Format.dateRequest)
          : undefined,
        endAt: getValues('endAt')
          ? formatDateTime(getValues('endAt'), Format.dateRequest)
          : undefined,
        rankSort: getValues('rankSort') as SortType | undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  // 遷移
  const goFanDetail = (fanId = '') => {
    navigate('/fans/:fanId', {
      params: {
        fanId: fanId,
      },
    })
  }
  const goList = () => {
    navigate('/artists/fan-ranks')
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      artist: {
        id: item.artistId,
        name: item.artistName,
      },
      fan: {
        id: item.fanId,
        name: item.fanName,
      },
      rank: item.rank,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  const sortOptions = () => {
    const empty = [{ value: '', text: '選択してください' }]
    const base = Object.entries(Sort).map(([key, value]) => ({
      value: key,
      text: value.text,
    }))
    return empty.concat(base)
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧へ戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <Box
        gap={4}
        mb={3}
        alignItems={'center'}
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Box display={'flex'} gap={2} alignItems={'flex-start'}>
          <FormDate
            control={control}
            name='startAt'
            label='集計開始日'
            views={['year', 'month', 'day']}
            fullWidth={false}
          />
          <FormDate
            control={control}
            name='endAt'
            label='集計終了日'
            views={['year', 'month', 'day']}
            fullWidth={false}
          />
          <FormSelect
            control={control}
            name='rankSort'
            label='ランク並び順'
            fullWidth={false}
            options={sortOptions()}
          />
        </Box>
        <ActionButton type='submit'>検索</ActionButton>
      </Box>
      <Box>
        <DataTable
          columns={[
            { field: 'id', headerName: 'ID', width: 50 },
            {
              field: 'artist',
              headerName: 'アーティスト',
              width: 280,
              renderCell: (params) => (
                <Box>
                  <Typography component='span'>
                    #{params.value.id} &nbsp;
                  </Typography>
                  <Link
                    onClick={() => goFanDetail(params.value.id)}
                    color='inherit'
                    underline='hover'
                  >
                    {params.value.name}
                  </Link>
                </Box>
              ),
            },
            {
              field: 'fan',
              headerName: 'ファン',
              width: 280,
              renderCell: (params) => (
                <Box>
                  <Typography component='span'>
                    #{params.value.id} &nbsp;
                  </Typography>
                  <Link
                    onClick={() => goFanDetail(params.value.id)}
                    color='inherit'
                    underline='hover'
                  >
                    {params.value.name}
                  </Link>
                </Box>
              ),
            },
            { field: 'rank', headerName: 'ランク', width: 100 },
            {
              field: 'createdAt',
              headerName: '集計日時',
              width: 160,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
        />
      </Box>
    </Box>
  )
}

export default Page
