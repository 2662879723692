import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Chip, Grid, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import {
  getDailyMissionPath,
  getDailyMission,
} from '@/features/mission/api/getDailyMission'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'ミッション履歴一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    fanId: '',
    fanName: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const fanId = searchParams.get('fanId') || ''
  const fanName = searchParams.get('fanName') || ''

  const { data, isValidating, mutate } = useSWR(getDailyMissionPath, () =>
    getDailyMission({
      page: page,
      fanId: fanId,
      fanName: fanName,
    }),
  )
  const { control, getValues } = useForm({
    defaultValues: {
      fanId: fanId,
      fanName,
    },
  })

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'fanId', value: getValues('fanId') },
        { key: 'fanName', value: getValues('fanName') },
      ])
      return prev
    })
    mutate(
      getDailyMission({
        page: page,
        fanId: getValues('fanId'),
        fanName: getValues('fanName'),
      }),
    )
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      fanId: item.fanId,
      fanName: item.fanName,
      type: item.type,
      artistId: item.artistId,
      fanCheerId: item.fanCheerId,
      cheer: item.cheer,
      expiresDate: item.expiresDate,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  const goFanDetail = (fanId = '') => {
    navigate('/fans/:fanId', {
      params: {
        fanId: fanId,
      },
    })
  }

  const goCheerCollect = (fanCheerId = '') => {
    navigate({
      pathname: '/histories/cheer/collect',
      search: `?id=${fanCheerId}`,
    })
  }

  return (
    <Box className='page'>
      <Box>
        <PageTitle>{pageTitle}</PageTitle>
        <Box
          onSubmit={(e) => {
            handleSearchSubmit(e)
          }}
          component='form'
        >
          <Grid container columnSpacing={2} alignItems={'center'}>
            <Grid item>
              <FormText
                control={control}
                name='fanId'
                label='ファンID'
                hint='カンマ区切りで複数指定可能'
              />
            </Grid>
            <Grid item>
              <FormText
                control={control}
                name='fanName'
                label='ファン名'
                hint='部分一致'
              />
            </Grid>
            <Grid item>
              <ActionButton type='submit'>検索</ActionButton>
            </Grid>
          </Grid>
        </Box>

        <DataTable
          columns={[
            { field: 'id', headerName: 'ID', width: 100 },
            {
              field: 'fanId',
              headerName: 'ファンID',
              width: 80,
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'fanName',
              headerName: 'ファン名',
              width: 250,
              renderCell: (params) => (
                <Link
                  onClick={() => goFanDetail(params.row.fanId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            { field: 'type', headerName: 'タイプ', width: 250 },
            {
              field: 'artistId',
              headerName: 'アーティストID',
              width: 150,
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'fanCheerId',
              headerName: 'CHEER受取ID',
              width: 120,
              renderCell: (params) =>
                params.value === 0 ? (
                  <Chip
                    size='small'
                    color='error'
                    variant='outlined'
                    label='未受取'
                  />
                ) : (
                  <Link
                    onClick={() => goCheerCollect(String(params.value))}
                    color='inherit'
                    underline='hover'
                  >
                    {params.value}
                  </Link>
                ),
            },
            {
              field: 'cheer',
              headerName: '獲得CHEER数',
              width: 150,
            },
            {
              field: 'expiresDate',
              headerName: '有効期限',
              width: 200,
            },
            { field: 'createdAt', headerName: '作成日時', width: 200 },
            { field: 'updatedAt', headerName: '更新日時', width: 200 },
          ]}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
        />
      </Box>
    </Box>
  )
}

export default Page
