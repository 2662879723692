import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import type { EventTypeType } from '@/types'

import {
  getArtistEventRevenueShare,
  getArtistEventRevenueSharePath,
} from '@/features/artists/api/getArtistEventsRevenueShare'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { EventType, RevenueShareType } from '@/types'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'アーティストイベントRS履歴一覧'

  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    eventId: '',
    eventName: '',
    artistId: '',
    artistName: '',
    agencyId: '',
    agencyName: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const dateParam = searchParams.get('date')
  const date = dateParam ? newDate(dateParam) : null
  const eventId = searchParams.get('eventId') || ''
  const eventName = searchParams.get('eventName') || ''
  const artistId = searchParams.get('artistId') || ''
  const artistName = searchParams.get('artistName') || ''
  const agencyId = searchParams.get('agencyId') || ''
  const agencyName = searchParams.get('agencyName') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      date: date,
      eventId: eventId,
      eventName: eventName,
      artistId: artistId,
      artistName: artistName,
      agencyId: agencyId,
      agencyName: agencyName,
    },
  })

  const { data, isValidating, mutate } = useSWR(
    getArtistEventRevenueSharePath,
    () =>
      getArtistEventRevenueShare({
        page: page,
        year: date ? date.getFullYear() : undefined,
        month: date ? date.getMonth() + 1 : undefined,
        eventId: eventId || undefined,
        eventName: eventName || undefined,
        artistId: artistId || undefined,
        artistName: artistName || undefined,
        agencyId: agencyId || undefined,
        agencyName: agencyName || undefined,
      }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        {
          key: 'date',
          value: formatDateTime(getValues('date'), Format.dateRequest),
        },
        { key: 'eventId', value: getValues('eventId') },
        { key: 'eventName', value: getValues('eventName') },
        { key: 'artistId', value: getValues('artistId') },
        { key: 'artistName', value: getValues('artistName') },
        { key: 'agencyId', value: getValues('agencyId') },
        { key: 'agencyName', value: getValues('agencyName') },
      ])
      return prev
    })
    mutate(
      getArtistEventRevenueShare({
        page: page,
        year: date ? date.getFullYear() : undefined,
        month: date ? date.getMonth() + 1 : undefined,
        eventId: getValues('eventId') || undefined,
        eventName: getValues('eventName') || undefined,
        artistId: getValues('artistId') || undefined,
        artistName: getValues('artistName') || undefined,
        agencyId: getValues('agencyId') || undefined,
        agencyName: getValues('agencyName') || undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  // 遷移
  const goFanDetail = (fanId = '') => {
    navigate('/fans/:fanId', {
      params: {
        fanId: fanId,
      },
    })
  }

  const goAgencyDetail = (agencyId = '') => {
    navigate('/agencies/:agencyId', {
      params: {
        agencyId: agencyId,
      },
    })
  }

  const goEventDetail = (eventId: number, type: EventTypeType) => {
    navigate(`/events/${EventType[type].page}/:eventId`, {
      params: {
        eventId: String(eventId),
      },
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      year: String(item.year), //右寄せ、カンマ区切りさせたくない
      month: String(item.month), //右寄せ、カンマ区切りさせたくない
      eventId: item.eventId,
      eventName: item.eventName,
      eventType: item.eventType,
      artistId: item.artistId,
      artistName: item.artistName,
      agencyId: item.agencyId,
      agencyName: item.agencyName,
      eventRevenueShareRate: item.eventRevenueShareRate,
      agencyRevenueShareType: item.agencyRevenueShareType.key
        ? RevenueShareType[item.agencyRevenueShareType.key].text
        : '-',
      agencyRevenueShareRate: item.agencyRevenueShareRate ?? '-',
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  return (
    <Box className='page'>
      <PageTitle>{pageTitle}</PageTitle>
      <Box
        gap={4}
        alignItems={'center'}
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Box display={'flex'} gap={2} alignItems={'center'}>
          <Box display={'flex'} gap={2} alignItems={'flex-start'}>
            <FormDate
              control={control}
              name='date'
              label='日付'
              views={['year', 'month']}
              fullWidth={false}
            />
            <FormText
              control={control}
              name='artistId'
              label='アーティストID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
            <FormText
              control={control}
              name='artistName'
              label='アーティスト名'
              fullWidth={false}
              hint='部分一致'
            />
            <FormText
              control={control}
              name='eventId'
              label='イベントID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
            <FormText
              control={control}
              name='eventName'
              label='イベント名'
              fullWidth={false}
              hint='部分一致'
            />
          </Box>
        </Box>
        <Box display={'flex'} gap={2} alignItems={'center'}>
          <Box display={'flex'} gap={2} alignItems={'flex-start'}>
            <FormText
              control={control}
              name='agencyId'
              label='代理店ID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
            <FormText
              control={control}
              name='agencyName'
              label='代理店名'
              fullWidth={false}
              hint='部分一致'
            />
          </Box>
          <ActionButton type='submit'>検索</ActionButton>
        </Box>
      </Box>
      <Box>
        <DataTable
          columns={[
            { field: 'id', headerName: 'ID', width: 100 },
            { field: 'year', headerName: '年', width: 60 },
            { field: 'month', headerName: '月', width: 40 },
            {
              field: 'artistId',
              headerName: 'アーティストID',
              width: 120,
              align: 'center',
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'artistName',
              headerName: 'アーティスト名',
              width: 200,
              renderCell: (params) => (
                <Link
                  onClick={() => goFanDetail(params.row.fanId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'eventRevenueShareRate',
              headerName: 'イベントRS率(%)',
              width: 140,
              align: 'center',
            },
            {
              field: 'eventId',
              headerName: 'イベントID',
              width: 120,
              align: 'center',
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'eventName',
              headerName: 'イベント名',
              width: 200,
              renderCell: (params) => (
                <Link
                  onClick={() =>
                    goEventDetail(params.row.eventId, params.row.eventType)
                  }
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'agencyId',
              headerName: '代理店ID',
              width: 80,
              align: 'center',
              renderCell: (params) =>
                params.value ? <CopyClipboard text={params.value} /> : '-',
            },
            {
              field: 'agencyName',
              headerName: '代理店名',
              width: 200,
              renderCell: (params) =>
                params.value ? (
                  <Link
                    onClick={() => goAgencyDetail(params.row.agencyId)}
                    color='inherit'
                    underline='hover'
                  >
                    {params.value}
                  </Link>
                ) : (
                  '-'
                ),
            },
            {
              field: 'agencyRevenueShareRate',
              headerName: '代理店RS率(%)',
              width: 120,
              align: 'center',
            },
            {
              field: 'agencyRevenueShareType',
              headerName: 'RSタイプ',
              width: 120,
            },
            {
              field: 'createdAt',
              headerName: '登録日時',
              width: 150,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          editReferProp={'id'}
        />
      </Box>
    </Box>
  )
}

export default Page
