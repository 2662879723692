import { useState } from 'react'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Avatar, Box, Button, Chip, Link } from '@mui/material'
import useSWRInfinite from 'swr/infinite'

import type {
  GetTemplateMessageListParams,
  TemplateMessage,
} from '@/features/message-boxes/api/getTemplateMessageList'

import {
  getTemplateMessageList,
  getTemplateMessageListPath,
} from '@/features/message-boxes/api/getTemplateMessageList'
import { MessageReadStatus } from '@/features/message-boxes/types'
import { useNavigate, useParams } from '@/router'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'メッセージ（運営）送信先一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()
  const params = useParams('/message-boxes/admin/:messageId')
  const messageId = Number(params.messageId)
  const limit = 100
  const [isReachingEnd, setReachingEnd] = useState(true)

  const getKey = (_: number, previousPageData: Array<TemplateMessage>) => {
    let key = `${getTemplateMessageListPath}?message_box_template_id=${messageId}&paging=NEXT`
    if (previousPageData) {
      const len = previousPageData.length
      if (len < limit) {
        return null
      }
      key = `${key}&paging_fan_id=${previousPageData[len - 1].fanId}`
    }
    return key
  }

  const formatMessage = (message: TemplateMessage) => {
    return {
      id: message.id,
      fanId: message.fanId,
      fanName: message.fanName,
      fanIconUrl: message.fanIconUrl,
      readStatus: MessageReadStatus[message.readFlag ? 'READ' : 'UNREAD'],
      lastMessagedAt: message.lastMessagedAt,
      createdAt: message.createdAt,
      updatedAt: message.updatedAt,
    }
  }

  const fetcher = async (key: string) => {
    const params: GetTemplateMessageListParams = {
      messageBoxTemplateId: messageId,
      paging: 'NEXT',
    }
    const pagingFanId = key.match(/paging_fan_id=(\d+)/)?.[1]
    if (pagingFanId) {
      params.pagingFanId = Number(pagingFanId)
    }
    const res = await getTemplateMessageList(params)
    if (res.result.length < limit) {
      setReachingEnd(true)
    } else {
      setReachingEnd(false)
    }
    return res.result.map(formatMessage)
  }

  const { data, isValidating, setSize } = useSWRInfinite(getKey, fetcher, {
    revalidateFirstPage: false,
  })

  const formatData = data ? data.flat() : []

  const loadMore = () => {
    setSize((prev) => prev + 1)
  }

  const goList = () => {
    navigate('/message-boxes/admin')
  }

  const goFanDetail = (fanId = '') => {
    navigate('/fans/:fanId', {
      params: {
        fanId: fanId,
      },
    })
  }

  return (
    <Box className='page'>
      <Box>
        <PageTitle actions={[{ text: '戻る', onClick: goList }]}>
          {pageTitle}
        </PageTitle>

        <DataTable
          idPropertyName='fanId'
          columns={[
            {
              field: 'fanId',
              headerName: 'ファンID',
              width: 100,
              renderCell: (params) => {
                const text = String(params.value)
                return <CopyClipboard text={text} />
              },
            },
            {
              field: 'fanIconUrl',
              headerName: '',
              width: 50,
              align: 'center',
              renderCell: (params) => {
                return (
                  <Avatar
                    src={params.value}
                    alt='ファンアイコン'
                    sx={{ width: 40, height: 40 }}
                  />
                )
              },
            },
            {
              field: 'fanName',
              headerName: 'ファン名',
              width: 250,
              renderCell: (params) => (
                <Link
                  onClick={() => goFanDetail(params.row.fanId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'readStatus',
              headerName: '未読・既読',
              width: 100,
              renderCell: (params) => {
                return (
                  <Chip
                    size='small'
                    color={params.value.color}
                    variant='outlined'
                    label={params.value.text}
                  />
                )
              },
            },
            {
              field: 'lastMessagedAt',
              headerName: '最終メッセージ日時',
              width: 200,
            },
            { field: 'createdAt', headerName: '作成日時', width: 200 },
            { field: 'updatedAt', headerName: '更新日時', width: 200 },
          ]}
          rows={formatData}
          isLoading={isValidating}
        ></DataTable>
      </Box>
      {!isReachingEnd && !isValidating && (
        <Button sx={{ mt: 1 }} onClick={loadMore}>
          もっと読み込む
        </Button>
      )}
    </Box>
  )
}

export default Page
