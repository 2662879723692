import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormValue from '@cz_frontend/ui/components/forms/FormValue/FormValue'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box, FormLabel, Grid, Typography } from '@mui/material'
import useSWR from 'swr'

import type { ReplyItem } from '@/features/message-boxes/api/getMessageBoxDetail'
import type { ReplyFormRef } from '@/features/message-boxes/components/ReplyForm'

import {
  getMessageBoxDetail,
  getMessageBoxDetailPath,
} from '@/features/message-boxes/api/getMessageBoxDetail'
import { postFinishInquiry } from '@/features/message-boxes/api/postFinishInquiry'
import { postMessageBoxFileUploadUrl } from '@/features/message-boxes/api/postMessageBoxFileUploadUrl'
import { postMessageBoxReply } from '@/features/message-boxes/api/postMessageBoxReply'
import MessageReplyItem from '@/features/message-boxes/components/MessageReplyItem'
import ReplyForm from '@/features/message-boxes/components/ReplyForm'
import { MessageType } from '@/features/message-boxes/types'
import { useNavigate, useParams } from '@/router'
import { CheerType } from '@/types'
import {
  isEmptyObj,
  pathReplace,
  pickErrorMessages,
  postUploadMedia,
} from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/message-boxes/inquiry/:messageBoxId')
  const pageTitle = 'お問い合わせ詳細'
  const [isLoading, setIsLoading] = useState(false)
  const [replyItems, setReplyItems] = useState<ReplyItem[]>([])
  const [isFinishedMessage, setIsFinishedMessage] = useState(false)

  const [searchParams] = useSearchParams()
  const fanId = searchParams.get('fanId') || ''

  usePageInfo({
    title: pageTitle,
  })

  // 取得
  const { data, mutate } = useSWR(
    pathReplace(getMessageBoxDetailPath, {
      messageBoxId: params.messageBoxId,
    }),
    () =>
      getMessageBoxDetail({
        id: params.messageBoxId,
        fanId: fanId,
      }),
  )

  useEffect(() => {
    // 可変要素セット
    if (!data) return
    setReplyItems(data.result.reply)
    setIsFinishedMessage(
      data.result.messageType === MessageType.FINISH_INQUIRY_MESSAGE.value,
    )
  }, [data])

  // 遷移
  const goList = () => {
    navigate('/message-boxes/inquiry')
  }
  const replyFormRef = useRef<ReplyFormRef>(null)

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const { isConfirmed } = await swal.confirm({
        text: 'この内容で返信します',
      })
      if (!isConfirmed) return
      const formValues = replyFormRef.current!.getFormValues()
      const { result: replyRes } = await postMessageBoxReply({
        messageBoxId: params.messageBoxId,
        fanId: fanId,
        body: formValues.replyText,
      })

      // ファイル選択されてたらアップロード
      if (formValues.files && formValues.files.length > 0) {
        let fileSort = 1
        for (const file of formValues.files) {
          if (!file) continue
          const { result } = await postMessageBoxFileUploadUrl({
            messageBoxId: params.messageBoxId,
            fanId: fanId,
            sort: fileSort,
            replyCount: replyRes.replyCount,
            fileName: file.name,
          })

          const uploadRes = await postUploadMedia({
            url: result.fileUrl,
            file: file,
          })
          if (!uploadRes) {
            await swal.messages({
              messages: ['ファイルのアップロードに失敗しました。'],
            })
            break
          }
          fileSort++
        }
      }

      swal.toastSuccess()
      mutate() //データ取り直し
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  // メッセージ終了処理
  const finishInquiry = async () => {
    try {
      setIsLoading(true)
      const { isConfirmed } = await swal.confirm({
        text: 'メッセージを終了します。終了すると返信ができなくなりますが、よろしいですか？',
      })
      if (!isConfirmed) {
        setIsLoading(false)
        return
      }

      await postFinishInquiry({
        messageBoxId: params.messageBoxId,
        fanId: fanId,
      })
      await swal.alert({
        text: 'メッセージを終了しました。',
      })
      mutate() //データ取り直し
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const pageHeaderActions = [{ text: '一覧に戻る', onClick: goList }]
  if (
    data &&
    data.result.messageType !== MessageType.FINISH_INQUIRY_MESSAGE.value
  ) {
    pageHeaderActions.unshift({
      text: 'メッセージを終了する',
      onClick: finishInquiry,
    })
  }

  return (
    <Box className='page'>
      <PageTitle actions={pageHeaderActions}>{pageTitle}</PageTitle>
      {data && (
        <Grid container spacing={4}>
          <Grid item md={4}>
            {data.result.ttl && (
              <>
                <FormValue label={'◼️ メッセージ削除予定日時'}>
                  {data.result.ttl}
                </FormValue>
                <Typography color='error'>
                  削除予定日時が設定されている場合は、その日以降に削除されます
                </Typography>
              </>
            )}
            <FormValue label={'◼️ タイトル'}>{data.result.title}</FormValue>
            <FormValue label={'◼️ 受信者ファンID'}>
              {data.result.fanId}
            </FormValue>
            <FormValue label={'◼️ 受信者ファン名'}>
              {data.result.fanName}
            </FormValue>
            <FormValue label={'◼️ メッセージ種別'}>
              {MessageType[data.result.messageType].text}
            </FormValue>
            <FormValue label={'◼️ 重要フラグ'}>
              {data.result.importantFlag ? (
                <Typography color={'error'}>重要</Typography>
              ) : (
                <Typography>重要ではない</Typography>
              )}
            </FormValue>
            <FormValue label={'◼️ お気に入りフラグ'}>
              {data.result.favoriteFlag ? (
                <Typography color={'success'}>お気に入り</Typography>
              ) : (
                <Typography>お気に入りではない</Typography>
              )}
            </FormValue>
            <FormValue label={'◼️ 既読フラグ'}>
              {data.result.readFlag ? (
                <Typography>既読</Typography>
              ) : (
                <Typography>未読</Typography>
              )}
            </FormValue>
            {!isEmptyObj(data.result.cheer) && (
              <>
                <FormValue label={'◼️ CHEER数'}>
                  {data.result.cheer.cheer}
                </FormValue>
                <FormValue label={'◼️ CHEER種別'}>
                  {CheerType[data.result.cheer.type].text}
                </FormValue>
                <FormValue label={'◼️ CHEER受け取り済みフラグ'}>
                  {data.result.readFlag ? (
                    <Typography>受け取り済み</Typography>
                  ) : (
                    <Typography>受け取っていない</Typography>
                  )}
                </FormValue>
                <FormValue label={'◼️ CHEER有効期限'}>
                  {data.result.cheer.expiredAt}
                </FormValue>
                {!isEmptyObj(data.result.cheer.event) ? (
                  <>
                    <FormValue label={'◼️ イベントID'}>
                      {data.result.cheer.event.id}
                    </FormValue>
                    <FormValue label={'◼️ イベント名'}>
                      {data.result.cheer.event.name}
                    </FormValue>
                  </>
                ) : (
                  <Typography>イベント未設定</Typography>
                )}
              </>
            )}
          </Grid>
          <Grid item md={6}>
            <FormLabel>◼️ やり取り</FormLabel>
            {/* 大元のメッセージ */}
            <MessageReplyItem
              fromUserIconUrl={data.result.from.iconUrl}
              fromUserName={data.result.from.name}
              body={data.result.body}
              createdAt={data.result.createdAt}
              inquiryFiles={data.result.inquiryFile}
            ></MessageReplyItem>
            <Box pl={2}>
              {/* それに連なる返信 */}
              {replyItems?.map((item) => (
                <Box key={item.sort}>
                  <Typography>↓</Typography>
                  <MessageReplyItem
                    fromUserIconUrl={item.from.iconUrl}
                    fromUserName={item.from.name}
                    body={item.body}
                    createdAt={item.createdAt}
                    inquiryFiles={item.inquiryFile}
                  ></MessageReplyItem>
                </Box>
              ))}
              {/* 返信可能なら返信フォームを表示 */}
              {!isFinishedMessage && (
                <>
                  <Typography>↓</Typography>
                  <Box
                    borderColor={'primary.main'}
                    border={1}
                    borderRadius={1}
                    p={1}
                  >
                    <ReplyForm ref={replyFormRef}></ReplyForm>
                    <ActionButton disabled={isLoading} onClick={handleSubmit}>
                      返信
                    </ActionButton>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default Page
