import { useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Grid, Box, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'

import { getCsrf } from '@/features/auth/api/getCsrf'
import { postLogin } from '@/features/auth/api/postLogin'
import { useNavigate } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'サインイン'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const isSamlShow = import.meta.env.VITE_ENV_NAME !== 'local'

  const submit = async () => {
    try {
      setIsLoading(true)
      // CSRFトークンを取得
      await getCsrf()

      // ログイン
      await postLogin({
        email: getValues('email'),
        password: getValues('password'),
        remember_me: true,
      })

      // 遷移
      navigate('/')
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  const redirectToSaml = () => {
    window.location.href = `${import.meta.env.VITE_API_BASE_URL}/admin/v1/saml/login`
  }

  return (
    <Grid
      container
      component='main'
      sx={{
        height: '100vh',
      }}
    >
      <Grid item xs={12}>
        <Box
          sx={{
            height: '80%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            component='h1'
            variant='h4'
            fontWeight='bold'
            color='primary'
            sx={{
              mb: 3,
            }}
          >
            CHEERZ ADMIN
          </Typography>
          {isSamlShow ? (
            <Box sx={{ my: 2 }}>
              <ActionButton
                type='button'
                isLoading={isLoading}
                fullWidth
                onClick={redirectToSaml}
              >
                Googleアカウントでログイン
              </ActionButton>
            </Box>
          ) : (
            <Box component='form' onSubmit={handleSubmit(submit)}>
              <FormText
                control={control}
                required
                name='email'
                label='メールアドレス'
                type='email'
                autocomplete='email'
                size='medium'
              />
              <FormText
                control={control}
                required
                name='password'
                label='パスワード'
                type='password'
                autocomplete='current-password'
                size='medium'
              />
              <Box sx={{ my: 2 }}>
                <ActionButton type='submit' isLoading={isLoading} fullWidth>
                  サインイン
                </ActionButton>
              </Box>
            </Box>
          )}
          <Typography component='p' align='center'>
            <small>&copy; 2023 CHEERZ</small>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Page
