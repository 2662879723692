import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import DataModal from '@cz_frontend/ui/components/common/DataModal/DataModal'
import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormValue from '@cz_frontend/ui/components/forms/FormValue/FormValue'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { EmojiEventsOutlined } from '@mui/icons-material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Alert, Box, FormLabel, Grid, Link, OutlinedInput, Typography } from '@mui/material'
import useSWR from 'swr'

import {
  getEventDetail,
  getEventDetailPath,
} from '@/features/event/api/getEventDetail'
import {
  getEventRankingArtist,
  getEventRankingArtistPath,
} from '@/features/event/api/getEventRankingArtist'
import { getEventRankingArtistDownloadCsv } from '@/features/event/api/getEventRankingArtistDownloadCsv'
import { patchEventRankingArtistCorrectionValue } from '@/features/event/api/patchEventRankingArtistCorrectionValue'
import { newDate } from '@/lib/dateFns'
import { useNavigate, useParams } from '@/router'
import { downloadCsv, pickErrorMessages } from '@/utils/functions'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const params = useParams('/events/:eventId/ranking/artists')
  const [isEventStarted, setIsEventStarted] = useState(false)
  const { data: eventData } = useSWR(getEventDetailPath, () =>
    getEventDetail({
      eventId: Number(params.eventId),
    }),
  )
  const eventName = eventData?.result.name ?? ''
  const pageTitle = 'イベントアーティストランキング'
  usePageInfo({
    title: pageTitle,
  })

  const [isLoading, setIsLoading] = useState(false)

  type ModalDataType = {
    rank: number
    score: number
    correctionValue: number
    artistId: number
    artistName: string
  }
  const [isShowModal, setIsShowModal] = useState(false)
  const [modalData, setModalData] = useState<ModalDataType | null>(null)
  const [correctionValue, setCorrectionValue] = useState<number | null>(0)

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    eventDetailId: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const eventDetailId = searchParams.get('eventDetailId') || ''

  const { data, isValidating, mutate } = useSWR(getEventRankingArtistPath, () =>
    getEventRankingArtist({
      page: page,
      eventDetailId: Number(eventDetailId),
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [{ key: 'page', value: String(page) }])
      return prev
    })
    mutate(
      getEventRankingArtist({
        page: page,
        eventDetailId: Number(eventDetailId),
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      rank: item.rank,
      score: item.score,
      correctionValue: item.correctionValue,
      cheerCount: item.cheerCount,
      watchingDuration: item.watchingDuration,
      postCount: item.postCount,
      postCheerFanCount: item.postCheerFanCount,
      artistId: item.artist.id,
      artistFanId: item.artist.fanId,
      artistName: item.artist.name,
    }
  })

  const goFanDetail = (fanId: number) => {
    navigate('/fans/:fanId', {
      params: {
        fanId: String(fanId),
      },
    })
  }

  const goEventEntry = () => {
    navigate('/events/:eventId/entries', {
      params: {
        eventId: String(params.eventId),
      },
    })
  }

  const goEventFanRanking = (artistId = '') => {
    navigate(
      {
        pathname: '/events/:eventId/ranking/artists/:artistId/fans',
        search: `?eventDetailId=${eventDetailId}`,
      },
      {
        params: {
          eventId: params.eventId,
          artistId: artistId,
        },
      },
    )
  }

  // CSVダウンロード
  const handleDownloadCsv = async () => {
    try {
      const res = await getEventRankingArtistDownloadCsv({
        eventDetailId: Number(eventDetailId),
      })

      const resBlob = await res.blob()
      const contentDisposition = res.headers.get('content-disposition')
      const fileName = contentDisposition
        ? contentDisposition.split('filename=')[1]
        : ''
      downloadCsv(resBlob, fileName)
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (eventData?.result.eventStartAt) {
      setIsEventStarted(newDate(eventData.result.eventStartAt) <= newDate())
    }
  }, [eventData])

  const openModal = (data: ModalDataType) => {
    setIsShowModal(true)
    setModalData(data)
    setCorrectionValue(data.correctionValue)
  }

  const closeModal = () => {
    setIsShowModal(false)
    setModalData(null)
    setCorrectionValue(0)
  }

  // 更新
  const submit = async () => {
    try {
      await patchEventRankingArtistCorrectionValue({
        eventDetailId: Number(eventDetailId),
        artistId: modalData?.artistId ?? 0,
        correctionValue: correctionValue ?? 0,
      })

      swal.toastSuccess()
      closeModal()
      // 一覧更新
      search({ page: page })
    } catch (error) {
      closeModal()
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  return (
    <Box className='page'>
      <PageTitle
        actions={[
          { text: 'エントリー一覧へ戻る', onClick: goEventEntry },
          { text: 'CSVダウンロード', onClick: handleDownloadCsv },
        ]}
        isLoading={isLoading}
      >
        {pageTitle}
      </PageTitle>
      {eventName && (
        <Box my={3}>
          <Typography>対象のイベント：{eventName}</Typography>
        </Box>
      )}
      <Box>
        <DataTable
          columns={[
            { field: 'rank', headerName: '順位', width: 50 },
            {
              field: 'artistName',
              headerName: 'アーティスト',
              width: 250,
              renderCell: (params) => (
                <Link
                  onClick={() => goFanDetail(params.row.artistFanId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: '_eventFanRanking',
              headerName: 'ファンランキング',
              width: 140,
              align: 'center',
              renderCell: (params) =>
                isEventStarted ? (
                  <EmojiEventsOutlined
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() => goEventFanRanking(params.row.artistId)}
                  />
                ) : (
                  '-'
                ),
            },
            {
              field: 'score',
              headerName: 'スコア',
              width: 130,
              headerAlign: 'right',
            },
            {
              field: 'correctionValue',
              headerName: '補正値',
              width: 130,
              headerAlign: 'right',
              renderCell: (params) => (
                // フォームで値を表示
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  columnGap={1}
                >
                  <>{params.value}</>
                  <EditOutlinedIcon
                    onClick={() => openModal(params.row)}
                    sx={{
                      cursor: 'pointer',
                    }}
                  />
                </Box>
              ),
            },
            {
              field: 'cheerCount',
              headerName: '獲得CHEER数',
              width: 140,
              headerAlign: 'right',
            },
            {
              field: 'watchingDuration',
              headerName: '総視聴時間(分)',
              width: 140,
              headerAlign: 'right',
            },
            {
              field: 'postCount',
              headerName: '総投稿数',
              width: 140,
              headerAlign: 'right',
            },
            {
              field: 'postCheerFanCount',
              headerName: 'CHEER UU数',
              width: 140,
              headerAlign: 'right',
            },
          ]}
          idPropertyName={'rank'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          editReferProp={'rank'}
        />
      </Box>
      <DataModal isShow={isShowModal} onCallback={closeModal}>
        <Grid container spacing={4}>
          <Grid item md={12}>
            <Box minWidth={500}>
              <Box mb={2}>
                <Alert color='info'>
                  即時反映されるわけではありません。次回集計時にスコアに加算されます。
                </Alert>
              </Box>
              {modalData && (
                <>
                  <FormValue label={'◼️ 順位'}>
                    {modalData.rank.toLocaleString()}
                  </FormValue>
                  <FormValue label={'◼️ スコア'}>
                    {modalData.score.toLocaleString()}
                  </FormValue>
                  <FormValue label={'◼️ アーティスト'}>
                    {modalData.artistName}
                  </FormValue>
                  <Box>
                    <FormLabel>補正値</FormLabel>
                    <OutlinedInput
                      fullWidth
                      value={correctionValue}
                      type='number'
                      onChange={(e) =>
                        setCorrectionValue(
                          e.target.value ? Number(e.target.value) : null,
                        )
                      }
                    />
                  </Box>{' '}
                  <ActionButton
                    size='large'
                    isLoading={isLoading}
                    fullWidth
                    onClick={submit}
                    sx={{ marginTop: 10 }}
                  >
                    更新する
                  </ActionButton>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </DataModal>
    </Box>
  )
}

export default Page
