import type {
  LiveStatusType,
  LiveStopReasonType,
  LiveTargetAudienceType,
  PostStatusType,
  PostTypeType,
} from '../types'
import type {
  APIResponse,
  APIResponseResultPagination,
  EventTypeType,
} from '@/types'

import { http } from '@/lib/http'

export type GetPostPathParams = {
  page: number
  id?: string
  type?: string
  artistId?: string
  artistName?: string
  eventId?: string
  eventName?: string
  status?: string
}

export type GetPostPathResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    type: PostTypeType
    fanId: number
    artistId: number
    artistName: string
    eventId: number | null
    eventName: string
    eventType: EventTypeType | null
    body: string
    targetAudience: LiveTargetAudienceType
    publishedAt: string
    status: PostStatusType
    imageUrl: string
    movieUrl: string
    liveStatus: LiveStatusType
    createdAt: string
    updatedAt: string
    liveDetail: null | {
      liveStartAt: string | null
      liveEndAt: string | null
      liveDuration: number | null
      uniqueViewerCount: number
      isRadioMode: boolean
      stopReason: LiveStopReasonType | null
    }
  }>
>

export const getPostPath = '/admin/v1/post'

export const getPost = async (params: GetPostPathParams) => {
  return await http<GetPostPathResponse>(getPostPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      id: params.id,
      type: params.type,
      artist_id: params.artistId,
      artist_name: params.artistName,
      event_id: params.eventId,
      event_name: params.eventName,
      status: params.status,
    },
  })
}
