import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Grid, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import type { EventTypeType } from '@/types'

import { getSubEvent, getSubEventPath } from '@/features/event/api/getSubEvent'
import { EntryType } from '@/features/event/types'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { EventType } from '@/types'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'サブイベ一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    subEventId: '',
    name: '',
    mainEventType: '',
    startAt: '',
    endAt: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const subEventId = searchParams.get('subEventId') || ''
  const name = searchParams.get('name') || ''
  const mainEventType = searchParams.get('mainEventType') || 'ALL'
  const startAtParam = searchParams.get('startAt')
  const startAt = startAtParam ? newDate(startAtParam) : null
  const endAtParam = searchParams.get('endAt')
  const endAt = endAtParam ? newDate(endAtParam) : null

  const { control, getValues } = useForm({
    defaultValues: {
      subEventId: subEventId,
      name: name,
      mainEventType: mainEventType,
      startAt: startAt,
      endAt: endAt,
    },
  })

  const { data, isValidating, mutate } = useSWR(getSubEventPath, () =>
    getSubEvent({
      page: page,
      type: mainEventType !== 'ALL' ? mainEventType : undefined,
      subEventId: subEventId || undefined,
      name: name || undefined,
      startAt: startAt
        ? formatDateTime(startAt, Format.dateRequest)
        : undefined,
      endAt: endAt ? formatDateTime(endAt, Format.dateRequest) : undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'subEventId', value: getValues('subEventId') },
        { key: 'mainEventType', value: getValues('mainEventType') },
        { key: 'name', value: getValues('name') },
        {
          key: 'startAt',
          value: formatDateTime(getValues('startAt'), Format.dateRequest),
        },
        {
          key: 'endAt',
          value: formatDateTime(getValues('endAt'), Format.dateRequest),
        },
      ])
      return prev
    })
    mutate(
      getSubEvent({
        page: page,
        subEventId: getValues('subEventId') || undefined,
        type:
          getValues('mainEventType') !== 'ALL'
            ? getValues('mainEventType')
            : undefined,
        name: getValues('name') || undefined,
        startAt: getValues('startAt')
          ? formatDateTime(getValues('startAt'), Format.dateRequest)
          : undefined,
        endAt: getValues('endAt')
          ? formatDateTime(getValues('endAt'), Format.dateRequest)
          : undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const goDetail = (id = '') => {
    navigate('/events/sub/:eventId', {
      params: {
        eventId: id,
      },
    })
  }

  const goCreate = () => {
    navigate('/events/sub/create')
  }

  const goPreview = (url: string) => {
    window.open(url, '_blank')
  }

  const goCopy = (eventId: number) => {
    navigate('/events/sub/copy/:originEventId', {
      params: {
        originEventId: String(eventId),
      },
    })
  }

  const goEventDetail = (eventId: number, type: EventTypeType) => {
    navigate(`/events/${EventType[type].page}/:eventId`, {
      params: {
        eventId: String(eventId),
      },
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      subEventId: item.subEventId,
      name: item.name,
      mainEventId: item.mainEvent.eventId,
      mainEventName: item.mainEvent.name,
      mainEventType: {
        value: item.mainEvent.type,
        text: EventType[item.mainEvent.type].text,
      },
      mainEventEntryType: EntryType[item.mainEvent.entryType].text,
      eventStartAt: item.eventStartAt,
      eventEndAt: item.eventEndAt,
      eventPeriodColor:
        newDate(item.eventStartAt) <= newDate() &&
        newDate() <= newDate(item.eventEndAt)
          ? 'success.main'
          : 'default',
      entryCount: item.entryCount,
      previewUrl: item.previewUrl,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '新規作成', onClick: goCreate }]}>
        {pageTitle}
      </PageTitle>
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Grid container columnSpacing={3} mb={3}>
          <Grid item>
            <FormText
              control={control}
              name='subEventId'
              label='サブイベントID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='name'
              label='イベント名'
              fullWidth={false}
              hint={'部分一致'}
            />
          </Grid>
          <Grid item>
            <FormSelect
              control={control}
              name='mainEventType'
              label='親イベント種別'
              fullWidth={false}
              options={[
                {
                  value: 'ALL',
                  text: 'すべて',
                },
              ].concat(
                Object.entries(EventType).map(([, item]) => ({
                  value: item.value,
                  text: item.text,
                })),
              )}
            />
          </Grid>
          <Grid item>
            <FormDate
              control={control}
              name='startAt'
              label='イベント期間開始日'
              views={['year', 'month', 'day']}
              fullWidth={false}
            />
          </Grid>
          <Grid item>
            <FormDate
              control={control}
              name='endAt'
              label='イベント期間終了日'
              views={['year', 'month', 'day']}
              fullWidth={false}
            />
          </Grid>
          <Grid item>
            <ActionButton type='submit'>検索</ActionButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataTable
          columns={[
            {
              field: 'subEventId',
              headerName: 'サブイベントID',
              width: 120,
              align: 'right',
            },
            {
              field: 'name',
              headerName: 'イベント名',
              width: 300,
            },
            {
              field: 'eventStartAt',
              headerName: 'イベント開始日時',
              width: 200,
              renderCell: (params) => (
                <Box color={params.row.eventPeriodColor}>{params.value}</Box>
              ),
            },
            {
              field: 'eventEndAt',
              headerName: 'イベント終了日時',
              width: 200,
              renderCell: (params) => (
                <Box color={params.row.eventPeriodColor}>{params.value}</Box>
              ),
            },
            {
              field: 'entryCount',
              headerName: 'エントリー数',
              width: 110,
              align: 'right',
            },
            {
              field: 'mainEventId',
              headerName: '親イベントID',
              width: 110,
              align: 'right',
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'mainEventName',
              headerName: '親イベント名',
              width: 200,
              renderCell: (params) => (
                <Link
                  onClick={() =>
                    goEventDetail(
                      params.row.mainEventId,
                      params.row.mainEventType.value,
                    )
                  }
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'mainEventType',
              headerName: '親イベント種別',
              width: 120,
              renderCell: (params) => <>{params.value.text}</>,
            },
            {
              field: 'mainEventEntryType',
              headerName: '親募集タイプ',
              width: 150,
            },
            {
              field: 'createdAt',
              headerName: '作成日時',
              width: 200,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 200,
            },
            {
              field: 'action',
              headerName: '操作',
              width: 300,
              renderCell: (params) => (
                <Box display={'flex'} gap={1}>
                  <ActionButton
                    size='small'
                    onClick={() => {
                      goPreview(params.row.previewUrl)
                    }}
                  >
                    LP確認
                  </ActionButton>
                  <ActionButton
                    size='small'
                    onClick={() => {
                      goCopy(params.row.subEventId)
                    }}
                  >
                    コピー
                  </ActionButton>
                </Box>
              ),
            },
          ]}
          idPropertyName={'subEventId'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
          editReferProp={'subEventId'}
        />
      </Box>
    </Box>
  )
}

export default Page
