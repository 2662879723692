import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { getArtistIdGroup, getArtistIdGroupPath } from '@/features/artists/api/getArtistIdGroup'
import { Format, formatDateTime } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'アーティストIDグループ一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const page = Number(searchParams.get('page') || '1')
  const id = searchParams.get('id') || ''
  const artistId = searchParams.get('artistId') || ''
  const artistName = searchParams.get('artistName') || ''
  const name = searchParams.get('name') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      id: id,
      artistId: artistId,
      artistName: artistName,
      name: name,
    },
  })

  const { data, isValidating, mutate } = useSWR(getArtistIdGroupPath, () =>
    getArtistIdGroup({
      page: page,
      id: id ? Number(id) : undefined,
      artistId: artistId || undefined,
      artistName: artistName || undefined,
      name: name || undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'id', value: getValues('id') },
        { key: 'artistId', value: getValues('artistId') },
        { key: 'artistName', value: getValues('artistName') },
        { key: 'name', value: getValues('name') },
      ])
      return prev
    })
    mutate(
      getArtistIdGroup({
        page: page,
        id: getValues('id') ? Number(getValues('id')) : undefined,
        artistId: getValues('artistId') || undefined,
        artistName: getValues('artistName') || undefined,
        name: getValues('name') || undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const goDetail = (id = '') => {
    navigate('/artist-id-groups/:artistGroupId', {
      params: {
        artistGroupId: id,
      },
    })
  }

  const goCreate = () => {
    navigate('/artist-id-groups/create')
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      name: item.name,
      artistCount: item.artistCount,
      createdAt: formatDateTime(item.createdAt, Format.dateTimeDefault),
      updatedAt: formatDateTime(item.updatedAt, Format.dateTimeDefault),
    }
  })

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '新規作成', onClick: goCreate }]}>
        {pageTitle}
      </PageTitle>
      <Box
        display={'flex'}
        gap={4}
        alignItems={'center'}
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Box display={'flex'} gap={2} alignItems={'flex-start'}>
          <FormText
            control={control}
            name='id'
            label='グループID'
            fullWidth={false}
            type='number'
          />
          <FormText
            control={control}
            name='name'
            label='IDグループ名'
            fullWidth={false}
            hint='部分一致'
          />
          <FormText
            control={control}
            name='artistId'
            label='含まれているアーティストID'
            fullWidth={false}
            hint='カンマ区切りで複数指定可能'
          />
          <FormText
            control={control}
            name='artistName'
            label='含まれているアーティスト名'
            fullWidth={false}
            hint='部分一致'
          />
        </Box>

        <ActionButton type='submit'>検索</ActionButton>
      </Box>
      <Box>
        <DataTable
          columns={[
            { field: 'id', headerName: 'ID', width: 50 },
            {
              field: 'name',
              headerName: 'IDグループ名',
              width: 300,
            },
            {
              field: 'artistCount',
              headerName: 'アーティスト数',
              width: 130,
            },
            {
              field: 'createdAt',
              headerName: '作成日時',
              width: 180,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 180,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
          editReferProp={'id'}
        ></DataTable>
      </Box>
    </Box>
  )
}

export default Page
