import { useEffect, useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'

import type { BannerFormRef } from '@/features/banners/components/BannerForm'

import { deleteBanner } from '@/features/banners/api/deleteBanner'
import { getBannerDetail } from '@/features/banners/api/getBannerDetail'
import { patchBanner } from '@/features/banners/api/patchBanner'
import BannerForm from '@/features/banners/components/BannerForm'
import { transformFormValues } from '@/features/banners/utils'
import { newDate } from '@/lib/dateFns'
import { useNavigate, useParams } from '@/router'
import { pickErrorMessages, postUploadMedia } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/banners/:bannerId')
  const pageTitle = 'バナー編集'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({ title: pageTitle })

  const goList = () => {
    navigate('/banners')
  }

  const BannerFormRef = useRef<BannerFormRef>(null)

  useEffect(() => {
    const fetchData = async () => {
      const { result } = await getBannerDetail({
        id: Number(params.bannerId),
      })
      BannerFormRef.current?.formReset({
        bannerUrl: result.bannerUrl,
        bannerFile: null,
        displayStartAt:
          result.displayStartAt !== null
            ? newDate(result.displayStartAt)
            : null,
        displayEndAt:
          result.displayEndAt !== null ? newDate(result.displayEndAt) : null,
        priority: Number(result.priority),
        target: result.target,
        displayTarget: result.displayTarget,
        destination: result.destination,
        url: result.url || '',
        eventId: result.eventId ? Number(result.eventId) : '',
        artistFanId: result.artistFanId ? Number(result.artistFanId) : '',
        isDisplayed: result.isDisplayed,
      })
    }
    fetchData()
  }, [params])

  const handleUpdate = async () => {
    try {
      setIsLoading(true)

      const formValues = BannerFormRef.current!.getFormValues()
      const { result } = await patchBanner({
        ...transformFormValues(formValues),
        bannerId: Number(params.bannerId),
      })

      if (formValues.bannerFile) {
        const res = await postUploadMedia({
          url: result.bannerUploadUrl,
          file: formValues.bannerFile,
        })
        if (!res) {
          await swal.messages({
            messages: ['バナーファイルのアップロードに失敗しました'],
          })
          return
        }
      }
      swal.toastSuccess()
      goList()
    } catch (err) {
      await swal.messages({ messages: pickErrorMessages(err) })
    } finally {
      setIsLoading(false)
    }
  }

  const handleDelete = async () => {
    try {
      setIsLoading(true)
      const { isConfirmed } = await swal.confirm({
        title: '削除しますか？',
        text: '削除すると元に戻せません。',
      })

      if (!isConfirmed) return

      await deleteBanner({ id: Number(params.bannerId) })
      swal.toastSuccess()
      goList()
    } catch (err) {
      await swal.messages({ messages: pickErrorMessages(err) })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <BannerForm ref={BannerFormRef} />
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={handleUpdate}
      >
        更新する
      </ActionButton>
      <Box mt={6}>
        <ActionButton
          size='large'
          isLoading={isLoading}
          fullWidth
          color='error'
          variant='outlined'
          onClick={handleDelete}
        >
          削除する
        </ActionButton>
      </Box>
    </Box>
  )
}

export default Page
