export const BannerTarget = {
  ALL: { value: 'ALL', text: '全て' },
  APP: { value: 'APP', text: 'アプリ' },
  WEB: { value: 'WEB', text: 'WEB' },
} as const
export type BannerTargetType = keyof typeof BannerTarget

export const BannerDisplayTarget = {
  FAN_ALL: { value: 'FAN_ALL', text: 'ファン(全て)' },
  FAN_EXCLUDE_ARTIST: {
    value: 'FAN_EXCLUDE_ARTIST',
    text: 'ファン(アーティスト除く)',
  },
  ARTIST: { value: 'ARTIST', text: 'アーティスト' },
} as const
export type BannerDisplayTargetType = keyof typeof BannerDisplayTarget

export const BannerDestination = {
  WEB: { value: 'WEB', text: 'WEB' },
  EVENT_DETAIL: { value: 'EVENT_DETAIL', text: 'イベント詳細' },
  ARTIST_PROFILE: { value: 'ARTIST_PROFILE', text: 'アーティストプロフィール' },
} as const

export type BannerDestinationType = keyof typeof BannerDestination

export const DisplayStatus = {
  ON: { value: 'ON', text: '表示', color: 'success' },
  OFF: { value: 'OFF', text: '非表示', color: 'error' },
} as const
export type DisplayStatusType = keyof typeof DisplayStatus

export type BannerFormValues = {
  bannerUrl: string
  bannerFile: File | null
  displayStartAt: Date | null
  displayEndAt: Date | null
  priority: number | ''
  target: BannerTargetType
  displayTarget: BannerDisplayTargetType
  destination: BannerDestinationType
  url: string | ''
  eventId: number | ''
  artistFanId: number | ''
  isDisplayed: DisplayStatusType
}
