import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Link, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { GetBlockPath, getBlock } from '@/features/fan/api/getBlock'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'ブロック一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    blockerFanId: '',
    blockerName: '',
    blockedFanId: '',
    blockedName: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const blockerFanId = searchParams.get('blockerFanId') || ''
  const blockerName = searchParams.get('blockerName') || ''
  const blockedFanId = searchParams.get('blockedFanId') || ''
  const blockedName = searchParams.get('blockedName') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      blockerFanId: blockerFanId,
      blockerName: blockerName,
      blockedFanId: blockedFanId,
      blockedName: blockedName,
    },
  })

  const { data, isValidating, mutate } = useSWR(GetBlockPath, () =>
    getBlock({
      page: page,
      blockerFanId: blockerFanId || undefined,
      blockerName: blockerName || undefined,
      blockedFanId: blockedFanId || undefined,
      blockedName: blockedName || undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'blockerFanId', value: getValues('blockerFanId') },
        { key: 'blockerName', value: getValues('blockerName') },
        { key: 'blockedFanId', value: getValues('blockedFanId') },
        { key: 'blockedName', value: getValues('blockedName') },
      ])
      return prev
    })
    mutate(
      getBlock({
        page: page,
        blockerFanId: getValues('blockerFanId') || undefined,
        blockerName: getValues('blockerName') || undefined,
        blockedFanId: getValues('blockedFanId') || undefined,
        blockedName: getValues('blockedName') || undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      blocker: {
        id: item.blockerFanId,
        value: item.blockerName,
      },
      blocked: {
        id: item.blockedFanId,
        value: item.blockedName,
      },
      createdAt: item.createdAt,
    }
  })

  const goFanDetail = (fanId: number) => {
    navigate('/fans/:fanId', {
      params: {
        fanId: String(fanId),
      },
    })
  }

  return (
    <Box className='page'>
      <PageTitle>{pageTitle}</PageTitle>
      <Box
        display={'flex'}
        gap={4}
        alignItems={'center'}
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Box display={'flex'} gap={2} alignItems={'flex-start'}>
          <FormText
            control={control}
            name='blockerFanId'
            label='ブロックしたファンID'
            fullWidth={false}
            hint={'カンマ区切りで複数指定可能'}
          />
          <FormText
            control={control}
            name='blockerName'
            label='ブロックしたファン名'
            fullWidth={false}
            hint={'部分一致'}
          />
          <FormText
            control={control}
            name='blockedFanId'
            label='ブロックされたファンID'
            fullWidth={false}
            hint={'カンマ区切りで複数指定可能'}
          />
          <FormText
            control={control}
            name='blockedName'
            label='ブロックされたファン名'
            fullWidth={false}
            hint={'部分一致'}
          />
        </Box>

        <ActionButton type='submit'>検索</ActionButton>
      </Box>
      <Box>
        <DataTable
          columns={[
            { field: 'id', headerName: 'ID', width: 100 },
            {
              field: 'blocker',
              headerName: 'ブロックしたファン',
              width: 300,
              renderCell: (params) => (
                <Box>
                  <Typography component='span'>
                    #{params.value.id} &nbsp;
                  </Typography>
                  <Link
                    onClick={() => goFanDetail(params.value.id)}
                    color='inherit'
                    underline='hover'
                  >
                    {params.value.value}
                  </Link>
                </Box>
              ),
            },
            {
              field: 'blocked',
              headerName: 'ブロックされたファン',
              width: 300,
              renderCell: (params) => (
                <Box>
                  <Typography
                    component='span'
                    onClick={() => {
                      navigator.clipboard.writeText(String(params.value.id))
                    }}
                  >
                    #{params.value.id} &nbsp;
                  </Typography>
                  <Link
                    onClick={() => goFanDetail(params.value.id)}
                    color='inherit'
                    underline='hover'
                  >
                    {params.value.value}
                  </Link>
                </Box>
              ),
            },
            {
              field: 'createdAt',
              headerName: 'ブロック日時',
              width: 200,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          editReferProp={'id'}
        ></DataTable>
      </Box>
    </Box>
  )
}

export default Page
