import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import {
  getFaqCategoryPath,
  getFaqCategory,
} from '@/features/faq/api/getFaqCategory'
import { FaqCategoryStatus, FaqCategoryTargetType } from '@/features/faq/types'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'FAQカテゴリー一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    name: '',
    status: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const name = searchParams.get('name') || ''
  const status = searchParams.get('status') || ''
  const target = searchParams.get('target') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      name: name,
      status: status,
      target: target,
    },
  })

  const { data, isValidating, mutate } = useSWR(getFaqCategoryPath, () =>
    getFaqCategory({
      page: page,
      name: name || undefined,
      status: status || undefined,
      target: target || undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'name', value: getValues('name') },
        { key: 'status', value: getValues('status') },
        { key: 'target', value: getValues('target') },
      ])
      return prev
    })
    mutate(
      getFaqCategory({
        page: page,
        name: getValues('name') || undefined,
        status: getValues('status') || undefined,
        target: getValues('target') || undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const goDetail = (id = '') => {
    navigate('/faqs/categories/:faqCategoryId', {
      params: {
        faqCategoryId: id,
      },
    })
  }

  const goCreate = () => {
    navigate('/faqs/categories/create')
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      name: item.name,
      status: item.status,
      sort: item.sort,
      target: item.target,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  const statusOptions = () => {
    const empty = [{ value: '', text: '選択してください' }]
    const base = Object.entries(FaqCategoryStatus).map(([key, value]) => ({
      value: key,
      text: value.text,
    }))
    return empty.concat(base)
  }

  const targetOptions = () => {
    const empty = [{ value: '', text: '選択してください' }]
    const base = Object.entries(FaqCategoryTargetType).map(([key, value]) => ({
      value: key,
      text: value.text,
    }))
    return empty.concat(base)
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '新規作成', onClick: goCreate }]}>
        {pageTitle}
      </PageTitle>
      <Box
        display={'flex'}
        gap={4}
        alignItems={'center'}
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Box display={'flex'} gap={2} alignItems={'flex-start'}>
          <FormText
            control={control}
            name='name'
            label='カテゴリー名'
            fullWidth={false}
          />
          <FormSelect
            control={control}
            fullWidth={false}
            name='status'
            label='ステータス'
            options={statusOptions()}
          />
          <FormSelect
            control={control}
            fullWidth={false}
            name='target'
            label='表示場所'
            options={targetOptions()}
          />
        </Box>

        <ActionButton type='submit'>検索</ActionButton>
      </Box>
      <Box>
        <DataTable
          columns={[
            { field: 'id', headerName: 'ID', width: 50 },
            {
              field: 'name',
              headerName: 'カテゴリー名',
              width: 300,
            },
            {
              field: 'sort',
              headerName: '並び順',
              width: 100,
            },
            {
              field: 'status',
              headerName: 'ステータス',
              width: 120,
              renderCell: (params) => {
                return <>{params.value.value}</>
              },
            },
            {
              field: 'target',
              headerName: '表示場所',
              width: 200,
              renderCell: (params) => {
                return <>{params.value && params.value.value}</>
              },
            },
            {
              field: 'createdAt',
              headerName: '作成日時',
              width: 200,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 200,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
          editReferProp={'id'}
        ></DataTable>
      </Box>
    </Box>
  )
}

export default Page
