import { useRef, useState, type FormEvent } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormFile from '@cz_frontend/ui/components/forms/FormFile/FormFile'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'

import {
  postEventEntryUploadCsv,
  type PostEventEntryUploadCsvFormData,
} from '@/features/event/api/postEventEntryUploadCsv'
import { useNavigate, useParams } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const params = useParams('/events/:eventId/entries/csv-upload')
  const [isLoading, setIsLoading] = useState(false)

  //csvFileのdom要素を初期化
  const csvFileRef = useRef<HTMLInputElement | null>(null)
  const pageTitle = 'イベントエントリーCSVアップロード'

  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const eventId = params.eventId

  const { control, getValues } = useForm({
    defaultValues: {
      csvFile: null,
    },
  })  

  // アップロード
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    try {
      setIsLoading(true)
      if (!getValues('csvFile')) {
        swal.alert({ text: 'CSVファイルを選択してください' })
        return
      }
      const formData = new FormData() as PostEventEntryUploadCsvFormData
      formData.append('event_id', Number(eventId))
      formData.append('file', getValues('csvFile'))
      await postEventEntryUploadCsv(formData)
      swal.toastSuccess()
      setIsLoading(false)
      goEntryList()
    } catch (error) {
      csvFileRef!.current ?  (csvFileRef.current.value = '') : null
      await swal.messages({ messages: pickErrorMessages(error) })      
    } finally {
      setIsLoading(false)
    }
  }

  const goEntryList = () => {
    navigate('/events/:eventId/entries', {
      params: { eventId: eventId },
    })
  }

  return (
    <Box className='page'>
      <PageTitle
        actions={[
          { text: 'イベントエントリー一覧へ戻る', onClick: goEntryList },
        ]}
      >
        {pageTitle}
      </PageTitle>
      <Box
        onSubmit={(e) => {
          handleSubmit(e)
        }}
        component='form'
      >
        <FormFile
          label='CSVファイル'
          control={control}
          name='csvFile'
          required
          accept='.csv'
          inputCsvFile={csvFileRef}
        ></FormFile>
        <ActionButton type='submit' disabled={isLoading}>
          アップロード
        </ActionButton>
      </Box>
    </Box>
  )
}

export default Page
