import { useState, type FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import DataModal from '@cz_frontend/ui/components/common/DataModal/DataModal'
import DataTable from '@cz_frontend/ui/components/data/DataTable/DataTable'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import FormValue from '@cz_frontend/ui/components/forms/FormValue/FormValue'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { MoreHorizOutlined } from '@mui/icons-material'
import { Box, Grid, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import type { ReportTypeType } from '@/features/fan/types';

import { GetReportPath, getReport } from '@/features/fan/api/getReport'
import { ReportReason, ReportType } from '@/features/fan/types'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = '通報一覧'
  usePageInfo({
    title: pageTitle,
  })

  type ModalDataType = {
    type: ReportTypeType
    reasonDetail: string | null
    artistId: number | null
    postId: number | null
    liveId: number | null
    commentNo: number | null
    comment: string | null
  }

  const [isShowModal, setIsShowModal] = useState(false)
  const [modalData, setModalData] = useState<ModalDataType | null>(null)

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    fanId: '',
    fanName: '',
    targetFanId: '',
    targetFanName: '',
    type: '',
    reason: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const fanId = searchParams.get('fanId') || ''
  const fanName = searchParams.get('fanName') || ''
  const targetFanId = searchParams.get('targetFanId') || ''
  const targetFanName = searchParams.get('targetFanName') || ''
  const type = searchParams.get('type') || ''
  const reason = searchParams.get('reason') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      fanId: fanId,
      fanName: fanName,
      targetFanId: targetFanId,
      targetFanName: targetFanName,
      type: type,
      reason: reason,
    },
  })

  const { data, isValidating, mutate } = useSWR(GetReportPath, () =>
    getReport({
      page: page,
      fanId: fanId || undefined,
      fanName: fanName || undefined,
      targetFanId: targetFanId || undefined,
      targetFanName: targetFanName || undefined,
      type: type || undefined,
      reason: reason || undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'fanId', value: getValues('fanId') },
        { key: 'fanName', value: getValues('fanName') },
        { key: 'targetFanId', value: getValues('targetFanId') },
        { key: 'targetFanName', value: getValues('targetFanName') },
        { key: 'type', value: getValues('type') },
        { key: 'reason', value: getValues('reason') },
      ])
      return prev
    })
    mutate(
      getReport({
        page: page,
        fanId: getValues('fanId') || undefined,
        fanName: getValues('fanName') || undefined,
        targetFanId: getValues('targetFanId') || undefined,
        targetFanName: getValues('targetFanName') || undefined,
        type: getValues('type') || undefined,
        reason: getValues('reason') || undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      fanId: item.fanId,
      fanName: item.fanName,
      targetFanId: item.targetFanId,
      targetFanName: item.targetFanName,
      type: ReportType[item.type].text,
      reason: ReportReason[item.reason].text,
      detail: {
        type: item.type,
        reasonDetail: item.detail.reasonDetail,
        artistId: item.detail.artistId,
        postId: item.detail.postId,
        liveId: item.detail.liveId,
        commentNo: item.detail.commentNo,
        comment: item.detail.comment,
      },
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  const types = () => {
    const empty = [{ value: '', text: '選択してください' }]
    const base = Object.entries(ReportType).map(([key, value]) => ({
      value: key,
      text: value.text,
    }))
    return empty.concat(base)
  }

  const reasons = () => {
    const empty = [{ value: '', text: '選択してください' }]
    const base = Object.entries(ReportReason).map(([key, value]) => ({
      value: key,
      text: value.text,
    }))
    return empty.concat(base)
  }

  const goFanDetail = (fanId: number) => {
    navigate('/fans/:fanId', {
      params: {
        fanId: String(fanId),
      },
    })
  }

  const goPostDetail = (postId: number) => {
    navigate('/posts/:postId', {
      params: {
        postId: String(postId),
      },
    })
  }

  const goPostComments = (postId: number) => {
    navigate('/posts/:postId/comments', {
      params: {
        postId: String(postId),
      },
    })
  }

  const goLiveDetail = (liveId: number) => {
    navigate('/lives/:liveId', {
      params: {
        liveId: String(liveId),
      },
    })
  }

  const goLiveComments = (liveId: number, commentNo: number) => {
    navigate(
      {
        pathname: '/lives/:liveId/comments',
        // ライブ配信コメントはDynamoDBに保存されていて order by ができないため、ページネーションするために直前のコメントNoを基準に取得している
        // そのため commentNo + 1 で前のコメントで検索する
        search: `?commentNo=${commentNo + 1}`,
      },
      {
        params: {
          liveId: String(liveId),
        },
      },
    )
  }

  const goNoticeDetail = (modalData: ModalDataType) => {
    switch (modalData.type) {
      case ReportType.POST.value:
        goPostDetail(modalData.postId ?? 0)
        break
      case ReportType.POST_COMMENT.value:
        goPostComments(modalData.postId ?? 0)
        break
      case ReportType.LIVE.value:
        goLiveDetail(modalData.postId ?? 0) // idはpostIdを使う
        break
      case ReportType.LIVE_COMMENT.value:
        goLiveComments(modalData.postId ?? 0, modalData.commentNo ?? 0) // idはpostIdを使う
        break
      default:
        return
    }
  }

  const openModal = (data: ModalDataType) => {
    setIsShowModal(true)
    setModalData(data)
  }

  const closeModal = () => {
    setIsShowModal(false)
  }

  return (
    <Box className='page'>
      <PageTitle>{pageTitle}</PageTitle>
      <Box
        display={'flex'}
        gap={4}
        alignItems={'center'}
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Box mb={3}>
          <Box display={'flex'} gap={2} alignItems={'flex-start'}>
            <FormText
              control={control}
              name='fanId'
              label='通報したファンID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
            <FormText
              control={control}
              name='fanName'
              label='通報したファン名'
              fullWidth={false}
              hint={'部分一致'}
            />
            <FormText
              control={control}
              name='targetFanId'
              label='通報されたファンID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
            <FormText
              control={control}
              name='targetFanName'
              label='通報されたファン名'
              fullWidth={false}
              hint={'部分一致'}
            />
          </Box>
          <Box display={'flex'} gap={2} alignItems={'center'}>
            <FormSelect
              control={control}
              name='type'
              label='通報対象'
              fullWidth={false}
              options={types()}
            />
            <FormSelect
              control={control}
              name='reason'
              label='理由'
              fullWidth={false}
              options={reasons()}
            />
            <ActionButton type='submit'>検索</ActionButton>
          </Box>
        </Box>
      </Box>
      <Box>
        <DataTable
          columns={[
            { field: 'id', headerName: 'ID', width: 50 },
            {
              field: 'type',
              headerName: '通報対象',
              width: 150,
            },
            {
              field: 'reason',
              headerName: '理由',
              width: 170,
            },
            {
              field: 'createdAt',
              headerName: '通報日時',
              width: 150,
            },
            {
              field: 'fanId',
              headerName: '通報したファンID',
              width: 150,
              align: 'center',
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'fanName',
              headerName: '通報したファン名',
              width: 250,
              renderCell: (params) => (
                <Link
                  onClick={() => goFanDetail(params.row.fanId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'targetFanId',
              headerName: '通報されたファンID',
              width: 150,
              align: 'center',
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'targetFanName',
              headerName: '通報されたファン名',
              width: 250,
              renderCell: (params) => (
                <Link
                  onClick={() => goFanDetail(params.row.targetFanId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: '_more',
              headerName: 'もっと見る',
              width: 100,
              renderCell: (params) => {
                return (
                  <MoreHorizOutlined
                    onClick={() => openModal(params.row.detail)}
                    sx={{
                      cursor: 'pointer',
                    }}
                  />
                )
              },
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          editReferProp={'id'}
        ></DataTable>
      </Box>
      <DataModal isShow={isShowModal} onCallback={closeModal}>
        <Grid container spacing={4}>
          <Grid item md={12}>
            <Box minWidth={500}>
              <FormValue label={'◼️ 理由詳細'}>
                {modalData?.reasonDetail ? modalData?.reasonDetail : '記入なし'}
              </FormValue>
              {modalData?.artistId && (
                <FormValue label={'◼️ 通報対象のアーティストID'}>
                  {modalData?.artistId}
                </FormValue>
              )}
              {modalData?.comment && (
                <FormValue label={'◼️ 通報対象のコメント'}>
                  {modalData?.comment}
                </FormValue>
              )}
              {(modalData?.type === ReportType.POST.value ||
                modalData?.type === ReportType.POST_COMMENT.value ||
                modalData?.type === ReportType.LIVE.value ||
                modalData?.type === ReportType.LIVE_COMMENT.value) && (
                <ActionButton
                  onClick={() => goNoticeDetail(modalData)}
                  color='primary'
                  variant='contained'
                  size='small'
                >
                  対象の{ReportType[modalData?.type].text}へ
                </ActionButton>
              )}
            </Box>
          </Grid>
        </Grid>
      </DataModal>
    </Box>
  )
}

export default Page
